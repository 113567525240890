@font-face {
  font-family: gotham-rounded-book;
  src: url(../fonts/gotham-rounded-book.otf);
}
.bidding #promos {
  margin-top: 30px;
  margin-right: -0.35rem;
}
.bidding #promos .box {
  min-height: 300px;
}
.bidding #promos .jobs .scroll {
  height: 205px;
}
.bidding #promos .bidding .scroll {
  height: 250px;
}
.bidding .password-state .fa-times-circle[data-v-630f7e84] {
  color: #d9534f;
}
.bidding .password-state .fa-check-circle[data-v-630f7e84] {
  color: #5cb85c;
}
.bidding .downloadFrame,
.bidding .printFrame {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.bidding .listview .fa-print {
  color: darkgreen;
}
@media print {
  @page {
    .bidding {
      margin: 1cm;
      size: 8.5in 11in;
    }
  }
  .bidding .col-pr-3 {
    flex: 0 0 24%;
  }
  .bidding .col-pr-4 {
    flex: 0 0 33.333333%;
  }
  .bidding .col-pr-8 {
    flex: 0 0 66.666666%;
  }
  .bidding .col-pr-9 {
    flex: 0 0 75%;
  }
}
.bidding .sticky[data-v-597c81ab] {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.bidding .countdown[data-v-597c81ab] {
  background-color: #eee;
  z-index: 1;
}
.bidding .countdown > div[data-v-597c81ab] {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
}
.bidding .countdown i[data-v-597c81ab] {
  font-size: 2.2rem;
  opacity: 0.5;
  margin-right: 15px;
}
.bidding .countdown > div.later[data-v-597c81ab] {
  background-color: #cce5ff;
  border-color: #b8daff;
  color: #004085;
}
.bidding .countdown > div.today[data-v-597c81ab],
.bidding .countdown > div.soon[data-v-597c81ab] {
  border-color: #e3a008;
  background-color: #2d3848;
  color: #fff;
}
.bidding .countdown > div.verysoon[data-v-597c81ab] {
  color: #fff;
  background-color: #c05621;
  border-color: #9c4221;
}
.bidding .countdown > div.imminent[data-v-597c81ab] {
  color: #fff;
  background-color: #c53030;
  border-color: #9b2c2c;
}
.bidding .countdown > div.closed[data-v-597c81ab] {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.bidding .countdown > div.closed[data-v-597c81ab] {
  color: #fff;
  background-color: #444;
  border-color: #000;
}
.bidding .countdown .message[data-v-597c81ab] {
  flex-grow: 1;
}
.bidding .countdown a[data-v-597c81ab] {
  background-color: #666;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
.bidding .countdown a[data-v-597c81ab]:hover {
  text-decoration: none;
  background-color: #888;
}
.bidding img {
  width: 100%;
}
.bidding .top-navigation {
  float: right;
}
.bidding .top-navigation > ul {
  margin: 0 0 0 0;
}
.bidding .top-navigation ul {
  list-style-type: none;
  padding: 0;
}
.bidding .top-navigation > ul > li {
  float: left;
  margin-left: 22px;
  height: 76px;
  line-height: 76px;
}
.bidding .top-navigation ul > li > ul {
  display: none;
  position: absolute;
  top: 76px; /* the height of the main nav */
  padding: 20px 0;
  background-color: rgba(224, 7, 0, 0.87);
  border-top: 3px solid #cd5c5c;
  z-index: 1;
}
.bidding .top-navigation ul > li:hover > ul {
  display: inherit;
}
.bidding .top-navigation ul ul li {
  width: 210px;
  float: none;
  display: list-item;
  position: relative;
  padding: 0 20px;
}
.bidding .top-navigation ul ul li:hover {
  background-color: rgba(224, 7, 0, 0.37);
}
.bidding .top-navigation ul ul li a {
  padding: 6px 20px;
  line-height: 20px;
  height: 32px;
  display: block;
}
.bidding .top-navigation li:hover > a {
  opacity: 0.7;
}
.bidding .top-navigation a {
  color: white;
  font-family: "Play", Helvetica, Arial, Lucida, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;
  transition: all 1s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bidding .top-navigation .fa-chevron-down {
  font-size: 10px;
}
.bidding .large-icon {
  width: 3rem;
  height: auto;
}
.bidding .inline-icon {
  top: 0;
  bottom: 0;
  margin-top: 0.32em;
  margin-bottom: 0;
  margin-right: 7px;
}
.bidding .listview__body .details {
  color: #818a91;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.bidding .listview__body .details .company {
  display: inline-block;
  margin-right: 15px;
}
.bidding .details .location {
  display: inline-block;
  margin-right: 15px;
}
.bidding .listview__body .icon-space {
  margin-right: 5px;
  margin-bottom: 4px;
}
.bidding .listview__body .row .name {
  font-size: 1.1rem;
  font-weight: 700;
}
.bidding .listview__body .row .description {
  color: #434b51;
  max-height: 3rem;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.bidding .listview__body .row .notification {
  color: #434b51;
  max-height: 3rem;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.bidding .yellow {
  color: #ffa000;
}
.bidding .listview__body .row .bid-date {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.bidding .input {
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.2rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 1px 2px rgba(10, 10, 0, 0.1);
  line-height: 1.25;
}
.bidding .search {
  width: 240px !important;
  margin: 5px 5px 5px 0;
}
.bidding .filter {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 45px 0 0;
  padding: 0;
  position: relative;
  top: 3px;
}
.bidding .show {
  display: block !important;
}
.bidding .hide {
  display: none !important;
}
.bidding #feedback-body .input-wrap {
  margin: 0 0 10px 0;
}
.bidding #feedback-body input,
.bidding #feedback-body textarea {
  padding: 6px;
}
.bidding .card {
  display: inline-flex;
  align-items: center;
  padding-left: 15px;
}
.bidding .private-key-box {
  display: inline-flex;
}
.bidding .search-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 16px;
  padding-bottom: 32px;
}
.bidding .header-wrapper {
  background-color: #3d7ff0 !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3CradialGradient id='a' cx='800' cy='1050' r='1400' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%233d7ff0'/%3E%3Cstop offset='0.3' stop-color='%233d7ff0'/%3E%3Cstop offset='0.7' stop-color='%230947b9'/%3E%3Cstop offset='1' stop-color='%233d7ff0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.bidding .footer-wrapper {
  background: #edecec !important;
  border-bottom: 0 white !important;
}
.bidding a {
  color: #0947b9 !important;
}
.bidding .t-primary-bgcolor {
  background-color: #0947b9 !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='0' r='1830' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230947b9'/%3E%3Cstop offset='1' stop-color='%233d7ff0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='0' cy='900' r='1200' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230947b9' stop-opacity='.6'/%3E%3Cstop offset='1' stop-color='%230947b9' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='1600' cy='0' r='1200' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%233d7ff0' stop-opacity='.6'/%3E%3Cstop offset='1' stop-color='%233d7ff0' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Cg %3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Crect fill='url(%23b)' width='1600' height='900'/%3E%3Crect fill='url(%23c)' width='1600' height='900'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  color: white !important;
}
.bidding .listview__body .row .name {
  color: #0947b9 !important;
}
.bidding .gray-link {
  color: #282828 !important;
}
.bidding .sidebar__nav a {
  padding: 3px 0;
  font-weight: 600;
}
.bidding .tabs .tab a,
.bidding .tabs li a {
  color: #666 !important;
}
.bidding .centerline-branding-title {
  margin-top: 6px;
  font-size: 1rem;
  letter-spacing: 4px;
  text-align: center;
  color: white;
  line-height: 0;
  margin-top: 16px;
  font-weight: 600;
  font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
}
.bidding .centerline-branding {
  width: auto;
  margin-left: 48px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.bidding .centerline-logo {
  width: 346px;
  height: 64px;
}
.bidding .header-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: auto;
  flex-basis: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 83px;
}
.bidding .page-login .main__content .login {
  flex-grow: 1;
  max-width: 400px;
  margin: 30px 0;
}
.bidding .page-login .main__content .register {
  background-color: #eee;
  padding: 2rem;
  border-radius: 10px;
  margin: 30px 0;
}
.bidding .page-login .main__content .row {
  margin: 20px auto;
  justify-content: space-around;
  padding: 1.5rem;
  align-items: flex-start;
}
.bidding .page-login .heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}
.bidding .page-login .main__content h2 {
  margin-bottom: 15px;
}
.bidding .dropdown-item.selected.sort-asc:before {
  font-family: FontAwesome;
  content: "x";
  position: absolute;
  left: 10px;
  color: #b4b9bd;
}
.bidding .dropdown-item.selected:before {
  font-family: FontAwesome;
  content: "x";
  position: absolute;
  left: 10px;
  color: #b4b9bd;
}
.bidding .dropdown-toggle {
  cursor: pointer !important;
}
.bidding .main h1 {
  color: #2e384d !important;
  font-weight: 500 !important;
  font-family: Roboto, Arial !important;
}
.bidding .entry {
  height: 100px !important;
}
.bidding .link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  color: #0947b9 !important;
}
.bidding .link-button:hover,
.bidding .link-button:focus {
  text-decoration: underline;
}

@media print {
  .header {
    display: none !important;
  }
  .drawer {
    display: none !important;
  }
  .hide-on-print {
    display: none !important;
  }
  .full-width-on-print {
    width: 600px;
  }
  .start-new-page {
    page-break-before: always;
  }
  .nobreak, .MuiTableRow-root {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  div .MuiGrid-root {
    display: block;
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
  div .MuiCard-root {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
  button {
    display: none !important;
  }
}

