@font-face {
  font-family: gotham-rounded-book;
  src: url(../fonts/gotham-rounded-book.otf);
}
@font-face {
  font-family: gotham-rounded-bold;
  src: url(../fonts/gotham-rounded-bold.otf);
}
@font-face {
  font-family: play-regular;
  src: url(../fonts/play-regular.ttf);
}
@font-face {
  font-family: play-bold;
  src: url(../fonts/play-bold.ttf);
}

$primary-blue: #1b5faa;
$banner-height: 120px;
$banner-side-margin: 60px;
$animation-speed: 0.4s;

.admin {
  * {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  textarea {
    height: auto;
  }

  #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .spinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .spinner {
    margin: 0;
  }

  .spinner.spinner-lg {
    font-size: 150px;
  }

  .code-box {
    display: inline-block;
    padding: 15px;
    text-align: left;
  }

  .input-box {
    display: inline-block;
    padding: 15px;
    text-align: left;
  }

  .card-docs-description-url {
    display: block;
    padding: 5px 15px;
    max-width: 350px;
  }

  .fields {
    margin-bottom: 40px;
  }

  div.card-docs-description.field {
    display: block;
    margin-bottom: 15px;
    text-align: center;
  }

  .field__key {
    display: block;
    margin-bottom: 5px;
  }

  .field__value {
    display: inline-block;
    padding: 5px 15px;
    margin-bottom: 10px;
  }

  .avatar {
    height: 100%;
  }

  .form-control {
    max-width: 350px;
  }

  .red-text {
    color: #db3416;
  }

  @keyframes fade-out-left {
    from {
      opacity: 1;
      transform: translate(0%, 0%, 0%);
    }
    to {
      opacity: 0;
      transform: translateX(-500px);
    }
  }

  @keyframes fade-in-left {
    from {
      opacity: 0;
      transform: translateX(500px);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes grow {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }

  .view-transition-enter {
  }

  .view-transition-enter.view-transition-enter-active {
    animation-name: fade-in-left;
    animation-duration: $animation-speed;
  }

  .view-transition-leave {
    opacity: 0;
  }

  .view-transition-leave.view-transition-leave-active {
    animation-name: fade-out-left;
    animation-duration: $animation-speed / 2;
  }

  .user-info {
    font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    flex-grow: 1;
    font-weight: 800;
    color: black;
    cursor: pointer;
    display: cblock;
    clear: both;
    float: left;
    position: relative;
    top: 43px;
    height: $banner-height;
    margin-right: $banner-side-margin;
  }
  .user-info-top {
    font-size: 1.3rem;
    color: white;
  }
  .user-info-dropdown-break {
    padding: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 8px;
    margin-right: 8px;
    background: rgba(180, 180, 180);
    height: 1px;
  }

  .user-info-dropdown {
    transform: scaleY(0);
    transition: 0.2s;
    background: rgba(220, 220, 220, 1);
    transform-origin: top;
    box-shadow: 7px 4px 15px rgba(160, 160, 160, 1);
  }
  .user-info:hover .user-info-dropdown {
    transform: scaleY(1);
  }
  .user-info-dropdown-item {
    display: flex;
    justify-content: left;
    flex-basis: auto;
    flex-wrap: nowrap;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
  }
  .user-info-dropdown-item:hover {
    background: rgba(230, 230, 230);
  }

  .admin-main-container {
    background: green;
  }

  .current-view-container {
    display: flex;
    justify-content: center;
    flex-basis: auto;
    flex-wrap: nowrap;
    align-items: center;
    width: 40vw;
  }

  .header-whitespace {
    width: 30vw;
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
  }

  .header-bar {
    background-color: #1a5da6;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='1' y2='0'%3E%3Cstop offset='0' stop-color='%230f1547' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%230f1547'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23473499' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23473499'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Crect fill='url(%23b)' width='1600' height='900'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    display: flex;
    flex-flow: row;
    justify-content: center;
    flex-wrap: nowrap;
    color: white;
    box-shadow: 0px 5px 10px rgba(180, 180, 180, 1);
  }
  .centerline-logo {
    width: 64px;
    height: 64px;
  }
  .centerline-branding-title {
    margin-top: 6px;
    font-size: 1rem;
    letter-spacing: 4px;
    text-align: center;
    color: white;
    line-height: 0;
    margin-top: 16px;
    font-weight: 600;
    font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
  }
  .centerline-branding {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-basis: auto;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    margin-left: $banner-side-margin;
  }

  .admin-header {
    font-family: play-regular, Arial, Helvetica, sans-serif;
    font-weight: 1000;
    text-align: center;
    font-size: 3rem;
    line-height: 1;
    margin: 0;
    padding: 0;
  }
  .smaller-header {
    font-family: play-regular, Arial, Helvetica, sans-serif;
    font-weight: bolder;
    text-align: center;
    font-size: 1.8rem;
    position: relative;
    bottom: 20px;
  }
  .inline-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-basis: auto;
    align-items: center;
    max-height: 360px;
  }
  .inline-container-large {
    @extend .inline-container;
    max-height: 9999px;
  }
  .home-flex-box {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  .home-content {
    flex: 1;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    position: fixed;
    left: 0px;
    top: $banner-height;
    width: 100%;
    height: calc(100vh - #{$banner-height});
    transition: 0.2s;
  }

  .home-flex-box-container {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100vw;
    height: 100vh;
    flex-basis: auto;
    align-items: center;
  }
  .home-tile {
    display: flex;
    flex-direction: column;
    flex-basis: auto;

    width: 450px;
    height: 200px;
    //border:1px solid black;
    background: white;
    margin-left: 150px;
    margin-right: 150px;
    text-align: center;
    transition: transform 0.33s;
    box-shadow: 13px 10px 25px gray;
    cursor: pointer;
    background-image: -webkit-linear-gradient(120deg, #61a6f2 50%, transparent 50%);
    background-image: linear-gradient(330deg, #61a6f2 50%, transparent 50%);
    background-size: 1300px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  .tile {
    @extend .home-tile;
    box-shadow: 4px 4px 10px lightgray;
    width: 500px;
    height: 175px;
    text-align: left;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: flex-start;
    background-size: 1500px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .tile:hover .tile-footer {
    color: rgb(225, 225, 225);
  }
  .tile:hover .tile-forward {
    color: rgb(225, 225, 225);
  }

  .flex-grow {
    flex-grow: 1;
  }
  .flex-grow-container {
    flex-grow: 100;
  }

  .home-tile:hover {
    transform: scale(
      1.1
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    color: whitesmoke;
    background-position: 100%;
  }
  .home-tile:hover .home-tile-header {
    color: whitesmoke;
  }

  .paragraph-center {
    display: flex;
    height: 100%;
    flex-grow: 1;
    align-items: center;
  }
  .home-tile-header-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-basis: auto;
    justify-content: flex-start;
    align-items: center;
  }
  .home-tile-header-image {
    width: 32px;
    height: 32px;
    margin-left: 16px;
  }
  .home-tile-break {
    height: 1px;
    background: gray;
    margin-left: 20px;
    margin-right: 20px;
  }
  .tile-break {
    height: 1px;
    background: gray;
    margin-left: 12px;
    margin-right: 12px;
    padding: 0;
  }
  .home-tile-header {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 32px;
    font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
    flex-grow: 1;
    color: black;
    font-size: 24px;
    font-weight: 1000;
    transition: 0.33s;
  }

  .header {
    font-size: 24px;
    font-weight: 500;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .tile-header {
    @extend .home-tile-header;
    line-height: 0;
    font-size: 1.3rem;
    flex-grow: 0;
    padding-top: 8px;
    padding-bottom: 4px;
    transition: 0.33s;
  }
  .home-tile-paragraph {
    font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    height: auto;
    flex-grow: 0;
    text-align: center;
    justify-self: center;
    width: 100%;
    line-height: 1.5rem;
    margin-left: 32px;
    margin-right: 32px;
  }
  .tile-paragraph {
    @extend .home-tile-paragraph;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    justify-self: flex-start;
    text-align: left;
    margin: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 42%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .tile-footer-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    padding-bottom: 8px;
  }
  .tile-footer {
    font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: rgb(122, 122, 122);
    height: auto;
    line-height: 1px;
    flex-grow: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    transition: color 0.33s;
  }
  .tile-forward {
    font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
    color: rgb(122, 122, 122);
    font-size: 1.7rem;
    line-height: 0;
    transition: color 0.33s;
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .light-break {
    background-color: lightgray;
    width: 60%;
    height: 1px;
  }
  .text-instructions {
    color: black;
    font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    font-weight: 1000;
    flex-grow: 1;
    max-height: 100px;
    display: flex;
    align-items: flex-end;
  }
  .home-tile-break-margin {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .admin-home-back {
    overflow: hidden;
    background: white;
  }
  .sidebar-arrow {
    position: absolute;
    left: 0px;
    top: 50%;
    height: 96px;
    width: 32px;
    background-color: rgba(27, 95, 170, 0.5);
    cursor: pointer;
  }
  .sidebar-arrow-active {
    position: absolute;
    left: 280px;
    top: 50%;
    height: 96px;
    width: 32px;
    background-color: rgba(250, 250, 250, 0.5);
    cursor: pointer;
    transition: 0.2s;
  }
  .sidebar-active .sidebar-arrow-active {
    left: 264px;
  }
  .sidebar-arrow-icon {
    width: 48px;
    left: -8px;
    height: 64px;
    position: absolute;
    top: 20%;
  }
  .down-arrow-icon {
    width: 32px;
    height: 32px;
    position: relative;
    bottom: -1px;
    left: 5px;
  }
  .user-info-username {
    position: relative;
    bottom: -1px;
  }
  .small-user-icon {
    width: 32px;
    height: 32px;
    margin-right: 14px;
    position: relative;
    bottom: 2px;
  }
  .current-view-icon {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    position: relative;
  }
  .sidebar {
    width: 280px;
    left: -280px;
    top: 0px;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-basis: auto;
    justify-content: flex-start;
    align-items: center;
    transition: left 0.22s;
    background-color: rgba(7, 7, 20, 0.75);
    color: white;
  }
  .sidebar-active {
    left: 0px;
  }
  .sidebar-top-margin {
    margin-bottom: 32px;
  }
  .sidebar-item {
    display: flex;
    flex-direction: row;
    flex-basis: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    font-family: gotham-rounded-book;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
    color: white;
    cursor: pointer;
  }
  .sidebar-item-active {
    background-color: white !important;
    color: black !important;
  }
  .sidebar-item:hover {
    background-color: lightgray;
  }

  /*I'm not proud of making a container for a container, but I needed to have 2 absolute positioned
    elements without removing the main div from the DOM.*/
  .sidebar-item-image-container-container {
    margin-left: 16px;
    margin-right: 24px;
    width: 32px;
    height: 32px;
  }

  .sidebar-item-image-container {
    position: absolute;
  }

  .sidebar-item-image {
    position: absolute;
    transform-origin: center center;
    width: 32px;
    height: 32px;
    left: 0%;
    top: 0px;
    transition: transform 0.3s;
  }

  .sidebar-item:hover .sidebar-item-image {
    transform: scale(1.2);
  }
  ​
    
    //I have no idea why, but removing this affects the text animations on the sidebar
    .sidebar-item-name {
  }

  .sidebar-text {
    transform-origin: left center;
    transition: color 0.3s, transform 0.3s;
    margin-top: 0px;
    text-align: left;
  }
  .sidebar-item:hover .sidebar-text {
    transform: scale(1.2);
    color: black;
  }
  ​ hr {
    color: black;
    background: black;
    border-top: 1px solid gray;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  /* Styling for buttons on Application Administrators */
  .search-adminstrator-button {
    text-align: center;
    position: relative;
    width: 306px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    margin-right: 100px;
  }
  .adminstrator-button {
    text-align: center;
    margin-top: 5px;
    position: relative;
    width: 285px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 100px;
  }

  .admin-table {
    width: 1500vw;
    height: 900vh;
    overflow: 'scroll';
    padding-left: 450vw;
    padding-right: 450vw;
  }

  .announcement-tile-styling {
    margin-top: 10vh;
  }

  .custom-button {
    @extend .home-tile;

    width: auto;
    height: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 4px 2px 6px gray;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 8px;
    cursor: pointer;
    background-image: -webkit-linear-gradient(120deg, #61a6f2 50%, transparent 50%);
    background-image: linear-gradient(330deg, #61a6f2 50%, transparent 50%);
    background-size: 750px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-basis: auto;
    justify-content: flex-start;
    align-items: center;
  }
  .custom-button-image {
    width: 32px;
    height: 32px;
    margin-right: 11px;
  }
  .custom-button-text {
    font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
    font-weight: bolder;
    flex-grow: 1;
    color: black;
    line-height: 0;
    padding: 0;
  }

  @media screen and (max-width: 1500px) {
    .home-tile {
      margin-left: 80px;
      margin-right: 80px;
    }
  }

  .not-visible {
    animation-name: fade-out;
    animation-duration: 0.2s;
    opacity: 0;
  }

  .visible {
    animation-name: fade-in;
    animation-duration: 0.2s;
    opacity: 1;
  }
}
