.step {
  display: flex;
  margin: 20px 0;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #6b7280;
}

.number {
  color: #fff;
  background-color: #27a9ff;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
