@import url(https://fonts.googleapis.com/css?family=Play:400,700);
@import url(https://fonts.googleapis.com/css?family=Arimo:400,700);
/* @import url(nav.css); */
.bidding body {
  background-color: #fff;
}
.bidding .t-primary-bgcolor {
  background-color: #c90f02;
}
.bidding a {
  color: #cc0000;
}
.bidding .main {
  margin-top: 0px;
}
.bidding .main h1 {
  font-family: "Play";
  font-size: 36px;
  color: #c90f02;
}
.bidding .header-wrapper {
  background-color: rgba(224, 7, 0, 0.87);
}
.bidding .footer-wrapper {
  height: 40px;
  background-color: #d92611;
  border-bottom: 10px solid #ac1600;
}
.bidding .header__logo {
  float: left;
}
.bidding .header__logo img {
  max-height: 41px;
  margin-top: 36%;
}

