.list {
  width: 600px;
}

.qrcode {
  float: right;
  margin: 8px 8px 0 0;
}

.property {
  display: inline-block;
  width: 30%;
  white-space: nowrap;
}

.value {
  display: inline-block;
  width: 70%;
  text-align: left;
  white-space: nowrap;
}

.log-table-logo {
  width: 150px;
  margin-top: 8px;
}

.print-only {
  display: none;

  @media print {
    display: block;
  }
}
