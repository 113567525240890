.punch-list-print-view {
  max-width: 1000px;
  margin-left: 8px;

  @media print {
    margin-left: 0;
  }

  .logo {
    width: 150px;
  }

  .print-icon {
    width: 150px;
    margin: 8px 0;
  }

  .qrcode {
    float: right;
  }

  .divider {
    width: 100%;
    border-top: solid 4px #00308C;
    display: block;
  }

  .divider, .mid-section {
    margin: 12px 0 12px 0;
  }

  .page-break {
    page-break-after: always;
  }

  .image-section {
    page-break-inside: avoid;
  }

  .row-subtitle {
    color: #5a6268;
    font-style: italic;
  }

  .fixed-width-table {
    table-layout: fixed;
    vertical-align: top;

    tr {
      vertical-align: top;
    }
  }

  .print-footer {
    display: none;
  }

  td:first-child {
    padding-left: 0;
  }

  td:last-child {
    padding-right: 0;
  }

  .no-padding-table {
    td {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }

    td:first-child {
      p {
        font-weight: bold;
      }

      width: auto;
      white-space: nowrap;
      vertical-align: top;
    }
  }

  .table-with-lines {
    th, td {
      border: 1px solid lightgrey;
    }

    td {
      padding: 2px;
    }

    td:first-child {
      padding-left: 1px;
    }

    td:last-child {
      padding-right: 1px;
    }
  }

  .header-table {
    display: block;
    min-width: 600px;

    h2 {
      margin-bottom: 0;
    }
  }

  .image-row {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    page-break-inside: avoid;
  }

  .image-with-caption {
    display: flex;
    flex-direction: column;
    width: 48.9%;
    height: 48vh;
    border: 1px solid gray;
  }

  .image {
    object-position: left;
    height: 100%;
    object-fit: contain;
    border-bottom: 1px solid gray;
  }

  .caption {
    margin: 2px;
  }

  @media print {
    .white-background {
      background-color: #ffffff;

      tr {
        background-color: #ffffff;
      }
    }
  }
}
