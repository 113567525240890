@charset "UTF-8";
@font-face {
  font-family: gotham-rounded-book;
  src: url(../fonts/gotham-rounded-book.otf);
}

.bidding {
  #promos {
    margin-top: 30px;
    margin-right: -0.35rem;
  }

  #promos .box {
    min-height: 300px;
  }

  #promos .jobs .scroll {
    height: 205px;
  }

  #promos .bidding .scroll {
    height: 250px;
  }

  .password-state .fa-times-circle[data-v-630f7e84] {
    color: #d9534f;
  }

  .password-state .fa-check-circle[data-v-630f7e84] {
    color: #5cb85c;
  }

  .downloadFrame,
  .printFrame {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .listview .fa-print {
    color: darkgreen;
  }

  @media print {
    @page {
      margin: 1cm;
      size: 8.5in 11in;
    }

    .col-pr-3 {
      flex: 0 0 24%;
    }

    .col-pr-4 {
      flex: 0 0 33.333333%;
    }

    .col-pr-8 {
      flex: 0 0 66.666666%;
    }

    .col-pr-9 {
      flex: 0 0 75%;
    }
  }

  .sticky[data-v-597c81ab] {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .countdown[data-v-597c81ab] {
    background-color: #eee;
    z-index: 1;
  }

  .countdown > div[data-v-597c81ab] {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.25rem;
  }

  .countdown i[data-v-597c81ab] {
    font-size: 2.2rem;
    opacity: 0.5;
    margin-right: 15px;
  }

  .countdown > div.later[data-v-597c81ab] {
    background-color: #cce5ff;
    border-color: #b8daff;
    color: #004085;
  }

  .countdown > div.today[data-v-597c81ab],
  .countdown > div.soon[data-v-597c81ab] {
    border-color: #e3a008;
    background-color: #2d3848;
    color: #fff;
  }

  .countdown > div.verysoon[data-v-597c81ab] {
    color: #fff;
    background-color: #c05621;
    border-color: #9c4221;
  }

  .countdown > div.imminent[data-v-597c81ab] {
    color: #fff;
    background-color: #c53030;
    border-color: #9b2c2c;
  }

  .countdown > div.closed[data-v-597c81ab] {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }

  .countdown > div.closed[data-v-597c81ab] {
    color: #fff;
    background-color: #444;
    border-color: #000;
  }

  .countdown .message[data-v-597c81ab] {
    flex-grow: 1;
  }

  .countdown a[data-v-597c81ab] {
    background-color: #666;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
  }

  .countdown a[data-v-597c81ab]:hover {
    text-decoration: none;
    background-color: #888;
  }

  img {
    width: 100%;
  }

  .top-navigation {
    float: right;
  }

  .top-navigation > ul {
    margin: 0 0 0 0;
  }

  .top-navigation ul {
    list-style-type: none;
    padding: 0;
  }

  .top-navigation > ul > li {
    float: left;
    margin-left: 22px;
    height: 76px;
    line-height: 76px;
  }

  .top-navigation ul > li > ul {
    display: none;
    position: absolute;
    top: 76px; /* the height of the main nav */
    padding: 20px 0;
    background-color: rgba(224, 7, 0, 0.87);
    border-top: 3px solid #cd5c5c;
    z-index: 1;
  }

  .top-navigation ul > li:hover > ul {
    display: inherit;
  }

  .top-navigation ul ul li {
    width: 210px;
    float: none;
    display: list-item;
    position: relative;
    padding: 0 20px;
  }

  .top-navigation ul ul li:hover {
    background-color: rgba(224, 7, 0, 0.37);
  }

  .top-navigation ul ul li a {
    padding: 6px 20px;
    line-height: 20px;
    height: 32px;
    display: block;
  }

  .top-navigation li:hover > a {
    opacity: 0.7;
  }

  .top-navigation a {
    color: white;
    font-family: 'Play', Helvetica, Arial, Lucida, sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
    transition: all 1s ease;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .top-navigation .fa-chevron-down {
    font-size: 10px;
  }

  .large-icon {
    width: 3rem;
    height: auto;
  }

  .inline-icon {
    top: 0;
    bottom: 0;
    margin-top: 0.32em;
    margin-bottom: 0;

    margin-right: 7px;
  }

  .listview__body .details {
    color: #818a91;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .listview__body .details .company {
    display: inline-block;
    margin-right: 15px;
  }

  .details .location {
    display: inline-block;
    margin-right: 15px;
  }

  .listview__body .icon-space {
    margin-right: 5px;
    margin-bottom: 4px;
  }

  .listview__body .row .name {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .listview__body .row .description {
    color: #434b51;
    max-height: 3rem;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .listview__body .row .notification {
    color: #434b51;
    max-height: 3rem;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .yellow {
    color: #ffa000;
  }

  .listview__body .row .bid-date {
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  }

  .input {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.2rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 2px rgba(10, 10, 0, 0.1);
    line-height: 1.25;
  }

  .search {
    width: 240px !important;
    margin: 5px 5px 5px 0;
  }

  .filter {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 45px 0 0;
    padding: 0;
    position: relative;
    top: 3px;
  }

  .show {
    display: block !important;
  }

  .hide {
    display: none !important;
  }

  #feedback-body .input-wrap {
    margin: 0 0 10px 0;
  }

  #feedback-body input,
  #feedback-body textarea {
    padding: 6px;
  }

  .card {
    display: inline-flex;
    align-items: center;
    padding-left: 15px;
  }

  .private-key-box {
    display: inline-flex;
  }

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 16px;
    padding-bottom: 32px;
  }

  .header-wrapper {
    background-color: #3d7ff0 !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3CradialGradient id='a' cx='800' cy='1050' r='1400' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%233d7ff0'/%3E%3Cstop offset='0.3' stop-color='%233d7ff0'/%3E%3Cstop offset='0.7' stop-color='%230947b9'/%3E%3Cstop offset='1' stop-color='%233d7ff0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
  }

  .footer-wrapper {
    background: #edecec !important;
    border-bottom: 0 white !important;
  }

  a {
    color: #0947b9 !important;
  }

  .t-primary-bgcolor {
    background-color: #0947b9 !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='0' r='1830' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230947b9'/%3E%3Cstop offset='1' stop-color='%233d7ff0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='0' cy='900' r='1200' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230947b9' stop-opacity='.6'/%3E%3Cstop offset='1' stop-color='%230947b9' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='1600' cy='0' r='1200' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%233d7ff0' stop-opacity='.6'/%3E%3Cstop offset='1' stop-color='%233d7ff0' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Cg %3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Crect fill='url(%23b)' width='1600' height='900'/%3E%3Crect fill='url(%23c)' width='1600' height='900'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    color: white !important;
  }

  .listview__body .row .name {
    color: #0947b9 !important;
  }

  .gray-link {
    color: #282828 !important;
  }

  .sidebar__nav a {
    padding: 3px 0;
    font-weight: 600;
  }

  .tabs .tab a,
  .tabs li a {
    color: #666 !important;
  }

  .centerline-branding-title {
    margin-top: 6px;
    font-size: 1rem;
    letter-spacing: 4px;
    text-align: center;
    color: white;
    line-height: 0;
    margin-top: 16px;
    font-weight: 600;
    font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
  }

  .centerline-branding {
    width: auto;
    margin-left: 48px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  .centerline-logo {
    width: 346px;
    height: 64px;
  }

  .header-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    height: auto;
    flex-basis: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    max-height: 83px;
  }

  .page-login .main__content .login {
    flex-grow: 1;
    max-width: 400px;
    margin: 30px 0;
  }

  .page-login .main__content .register {
    background-color: #eee;
    padding: 2rem;
    border-radius: 10px;
    margin: 30px 0;
  }

  .page-login .main__content .row {
    margin: 20px auto;
    justify-content: space-around;
    padding: 1.5rem;
    align-items: flex-start;
  }

  .page-login .heading {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
  }

  .page-login .main__content h2 {
    margin-bottom: 15px;
  }

  .dropdown-item.selected.sort-asc:before {
    font-family: FontAwesome;
    content: 'x';
    position: absolute;
    left: 10px;
    color: #b4b9bd;
  }

  .dropdown-item.selected:before {
    font-family: FontAwesome;
    content: 'x';
    position: absolute;
    left: 10px;
    color: #b4b9bd;
  }

  .dropdown-toggle {
    cursor: pointer !important;
  }

  .main h1 {
    color: #2e384d !important;
    font-weight: 500 !important;
    font-family: Roboto, Arial !important;
  }

  .entry {
    height: 100px !important;
  }

  .link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
    color: #0947b9 !important;
  }

  .link-button:hover,
  .link-button:focus {
    text-decoration: underline;
  }
}

@media print {
  .header {
    display: none !important;
  }
  .drawer {
    display: none !important;
  }
  .hide-on-print {
    display: none !important;
  }
  .full-width-on-print {
    width: 600px;
  }
  .start-new-page {
    page-break-before: always;
  }
  //div {
  //  break-inside: avoid;
  //}
  .nobreak, .MuiTableRow-root {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  div .MuiGrid-root {
    display: block;
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  div .MuiCard-root {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  button {
    display: none !important;
  }
}
