// Accordion Generator https://accordionslider.com/

.accordion {
  box-sizing: border-box;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
  border-color: #edecec;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  height: 100%;
  margin-bottom: 10px;
  width: 665px;
}

.accordion-select {
  cursor: pointer;
  margin: 0;
  opacity: 0;
  z-index: 1;
  padding-top: 5px;
  margin-bottom: -65px;
  margin-right: -65px;
}

.accordion-title {
  position: relative;
  color: #616061;
  width: 65px;
  height: 100%;
  font-size: 15px;
  background-color: #d7f3e0;

  .inactive {
    background-color: #f9f9f9;
  }

  &:not(:nth-last-child(2))::after {
    border: 1px solid #edecec;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    border-bottom-color: transparent;
    border-right-color: rgb(234, 234, 234);
  }
  span {
    bottom: 0px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    white-space: nowrap;
    width: 100%;
    transform: rotate(-90deg);
    -ms-writing-mode: lr-bt;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    padding-bottom: 33px;
    padding-top: 33px;
    line-height: 65px;
  }
}

.accordion-content {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  transition: margin 0.3s ease 0.1s;
  background-color: #f7f7f7;
  color: #7f8787;
  height: 100%;
  margin-bottom: 0;
  margin-right: calc(-1 * calc(100% - 325px));
  padding: 10px;
  width: calc(100% - 325px);
}

.accordion-select:checked + .accordion-title + .accordion-content {
  margin-bottom: 0;
  margin-right: 0;
}

/* Generated styles starts here */

// .accordion-title,
.accordion-select {
  background-color: white;
  color: #616061;
  width: 65px;
  height: 100%;
  font-size: 15px;
}

.accordion-select:hover + .accordion-title,
.accordion-select:checked + .accordion-title {
  background-color: #ffffff;
}
