body .page-account .profile {
  margin: 10px;
  border: 2px solid rgba(0, 0, 0, 0.06);
  padding: 1rem;
  text-align: center;
  flex: 1;
  display: flex;
}

body.is-guest .download-wrapper {
  display: none;
}

body.page-account .profiles {
  margin: 0 -10px 30px;
  display: flex;
  flex-wrap: wrap;
}

body.page-account .profile {
  margin: 10px;
  border: 2px solid rgba(0, 0, 0, 0.06);
  padding: 1rem;
  text-align: center;
  flex: 1;
  display: flex;
}

body.page-account .profile .avatar {
  width: 70px;
  padding: 1rem;
  display: flex;
}

body.page-account .profile .avatar i {
  color: #d8d8d8;
  font-size: 3rem;
}

body.page-account .profile h4 {
  margin-bottom: 10px;
}

body.page-account .profile .title {
  margin: -10px 0 10px;
  opacity: 0.7;
}

body.page-account .profile .address {
  margin-bottom: 10px;
}

body.page-account .profile .details {
  text-align: left;
  padding-top: 15px;
  display: inline-block;
}

body.page-account .profile .details > div {
  display: flex;
}

body.page-account .profile .details i {
  opacity: 0.3;
  width: 16px;
  text-align: center;
  margin-top: 4px;
  margin-right: 5px;
}

body.page-account .profile .actions {
  margin: 15px 0;
}

body.page-account .recent {
  margin-bottom: 30px;
}

body.page-account .recent .more {
  margin-top: 10px;
  display: inline-block;
}

body.page-account .recent .badge-secondary {
  background-color: #aaa;
}

body.page-account .download {
  float: right;
  line-height: 1;
}

body.page-account .download i.fa-download {
  color: #f78803;
  font-size: 1.5rem;
}

body.page-account .download i.fa-file-archive-o {
  color: #000;
  margin-right: 5px;
}

body.page-account #downloadDetails .modal-dialog,
body.page-account #orderDetails .modal-dialog {
  max-width: 600px;
}

body.page-account .modal-dialog h2 {
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

body.page-account .modal-dialog h3 {
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 700;
}

body.page-account .modal-dialog hr {
  height: 4px;
  border: 0;
  color: #d7dfe3;
  background-color: #d7dfe3;
  margin: 0.5rem 0;
}

body.page-account .modal-dialog th {
  font-weight: 700;
  color: #9ba2ab;
  border-bottom: 1px solid #edeff2;
}

body.page-account .modal-dialog th p {
  margin: 3px 0;
}

body.page-account .modal-dialog td {
  padding: 5px 0;
}

body.page-account .modal-dialog .align-right {
  text-align: right;
}

body.page-account-downloads h1,
body.page-account-orders h1 {
  margin-bottom: 15px;
}

body.page-account-downloads .pagination-wrapper,
body.page-account-orders .pagination-wrapper {
  float: right;
  margin: 0 0 10px;
}

body.page-account-downloads .listview__body,
body.page-account-orders .listview__body {
  clear: both;
}

body.page-bidding h2 {
  font-weight: 400;
}

body.page-bidding .step {
  display: flex;
  margin: 20px 0;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #6b7280;
}

body.page-bidding .step .number {
  color: #fff;
  background-color: #27a9ff;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

body.page-bidding .step h2 {
  color: #000;
}

body.page-bidding .sidebar .download-wrapper,
body.page-bidding .sidebar .order-wrapper {
  display: none;
}

body.page-bidding .profiles {
  margin: 0 -10px 30px;
  display: flex;
  flex-wrap: wrap;
}

body.page-bidding .profile {
  margin: 10px;
  border: 2px solid rgba(0, 0, 0, 0.06);
  padding: 1rem;
  text-align: center;
  flex: 1;
  display: flex;
}

body.page-bidding .profile .avatar {
  width: 70px;
  padding: 1rem;
  display: flex;
}

body.page-bidding .profile .avatar i {
  color: #d8d8d8;
  font-size: 3rem;
}

body.page-bidding .profile h4 {
  margin-bottom: 10px;
}

body.page-bidding .profile .company {
  margin: -10px 0 10px;
  opacity: 0.7;
}

body.page-bidding .profile .address {
  margin-bottom: 10px;
}

body.page-bidding .profile .details {
  text-align: left;
  display: inline-block;
}

body.page-bidding .profile .details > div {
  display: flex;
}

body.page-bidding .profile .details i {
  opacity: 0.3;
  width: 16px;
  text-align: center;
  margin-top: 4px;
  margin-right: 5px;
}

body.page-bidding table.table.hover tbody td {
  cursor: pointer;
}

body.page-bidding table.table td i.fa-file {
  opacity: 0.4;
}

body.page-bidding .notice {
  font-weight: 700;
}

body.page-bidding .notice i {
  font-size: 2rem;
  color: #27a9ff;
  vertical-align: middle;
  margin-right: 3px;
}

body.aec .main {
  display: block;
}

body.page-login .heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}

body.page-login .main__content h2 {
  margin-bottom: 15px;
}

body.page-login .main__content .row {
  margin: 20px auto;
  justify-content: space-around;
  padding: 1.5rem;
  align-items: flex-start;
}

body.page-login .main__content .login {
  flex-grow: 1;
  max-width: 400px;
  margin: 30px 0;
}

body.page-login .main__content .register {
  background-color: #eee;
  padding: 2rem;
  border-radius: 10px;
  margin: 30px 0;
}

body.page-login .main__content h2 .fa {
  opacity: 0.3;
  font-size: 2rem;
  float: left;
  margin-left: -35px;
  width: 35px;
}

body.page-login .main__content .alert {
  margin-top: 1.5rem;
}

body.page-login .main__content hr {
  margin: 1.5rem 0;
}

.bidding {
  .main-wrapper {
    background: inherit !important;
  }
  .breadcrumbs {
    font-size: 11px;
    color: #666;
  }

  #vpName {
    padding-top: 30px;
  }

  #vpContactInfo {
    float: right;
    text-align: right;
    font-size: 11px;
    margin: 20px 20px 0 0;
  }

  .sidebarBox {
    margin-bottom: 15px;
  }

  #sidebar .sidebarBox .fa {
    font-size: 15px;
    opacity: 0.5;
  }

  #loginContainer .input {
    margin-top: 3px;
    width: 100px;
    margin-left: 7px;
  }

  #loginContainer input[type='checkbox'] {
    margin-left: 7px;
    margin-right: 2px;
  }

  #loginContainer a {
    font-size: 11px;
  }

  .tblHeader2 {
    color: #fff;
    border-bottom: 1px solid #333;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    background-repeat: repeat-x;
    background-position: 0 0;
  }

  .tbl,
  .tbl2 {
    font-size: 13px;
    text-align: left;
  }

  .tbl2 {
    border-bottom: 1px solid #cecece;
  }

  .col1 {
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.06);
    border-left: 1px solid rgba(0, 0, 0, 0.02);
    padding: 4px 8px;
  }

  .col2,
  .col3 {
    padding: 3px 8px;
  }

  .col2,
  .col2Corner,
  .col3 {
    background-color: rgba(0, 0, 0, 0.06);
    border-right: 1px solid #cecece;
  }

  .col2Corner {
    padding-left: 5px;
    border-bottom: 1px solid #cecece;
    height: 5px;
  }

  .col3 {
    padding: 0;
  }

  .col1Corner {
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.02);
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    border-left: 1px solid rgba(0, 0, 0, 0.02);
    border-bottom: 1px solid #cecece;
  }

  .menuItem a,
  .menuItem a:link,
  .menuItem a:visited {
    font-size: 1rem;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    display: block;
    padding: 4px 4px 5px;
    margin: 0 7px;
  }

  .textDefault {
    font-size: 13px;
  }

  .textSmall {
    font-size: 12px;
  }

  .instr {
    font-size: 16px;
  }

  .tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    padding-left: 5px;
    margin: 1.5rem 0;
    display: flex;
  }

  .tabs .tab,
  .tabs li {
    list-style-type: none;
  }

  .tabs .tab a,
  .tabs li a {
    text-decoration: none;
    padding: 0 12px;
    color: #666;
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.08));
    line-height: 1.2;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 2px -1px;
    border-top: 4px solid #fff;
  }

  .tabs .tab a:hover,
  .tabs li a:hover {
    color: #333;
    background-image: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.13));
  }

  .tabs .active a,
  .tabs .selected a {
    border-top: 1px solid rgba(0, 0, 0, 0.13);
    border-color: #666 rgba(0, 0, 0, 0.13) #fff;
    border-style: solid;
    border-width: 4px 1px 1px;
  }

  .tabs .active a,
  .tabs .active a:hover,
  .tabs .selected a,
  .tabs .selected a:hover {
    color: #000;
    background-image: linear-gradient(rgba(0, 0, 0, 0.06), transparent);
  }

  .tabs .private-tabs a {
    background-image: linear-gradient(rgba(150, 0, 0, 0.06), rgba(150, 0, 0, 0.14));
  }

  .tabs .private-tabs a:hover {
    background-image: linear-gradient(rgba(150, 0, 0, 0.08), rgba(150, 0, 0, 0.2));
  }

  .tabs .private-tabs .selected a {
    border-top: 4px solid #d83d3d;
    background-image: linear-gradient(rgba(150, 0, 0, 0.06), transparent);
  }

  .tabs a.highlight-blue {
    background-image: linear-gradient(rgba(0, 67, 150, 0.06), rgba(0, 51, 115, 0.2));
  }

  .tabs a.highlight-blue:hover {
    background-image: linear-gradient(rgba(0, 67, 150, 0.08), rgba(0, 51, 115, 0.28));
  }

  .tabs .active a.highlight-blue,
  .tabs .selected a.highlight-blue {
    border-top: 4px solid #004396;
    background-image: linear-gradient(rgba(0, 67, 150, 0.06), transparent);
  }

  .tabs .number {
    display: block;
    font-weight: 700;
    font-size: 22px;
    color: #666;
    opacity: 0.4;
    line-height: 1.2;
  }

  .job-description,
  .project-description {
    font-size: 16px;
    color: #666;
    margin-top: -5px;
  }

  .tblHeader,
  .tblHeaderRow td,
  .tblHeaderRow th {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    background-repeat: repeat-x;
  }

  .tblHeader a,
  .tblHeader a:link,
  .tblHeader a:visited {
    color: #fff;
    text-decoration: none;
  }

  .sectionHeader .clickExpand {
    display: none;
  }

  .noFiles {
    font-weight: 700;
    color: red;
    margin-top: 10px;
  }

  .tblHeaderOver {
    background-color: #000 !important;
    cursor: pointer;
  }

  .row1 {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    min-height: 22px;
  }

  .row1,
  table.striped tbody tr td {
    background-color: rgba(0, 0, 0, 0.02);
  }

  #jobs .row1,
  #jobs .row2 {
    border-bottom: 1px solid #d5d5d5;
    border-top: 1px solid #fff;
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .row1Right {
    background-color: rgba(0, 0, 0, 0.02);
    padding-left: 3px;
    border-right: 1px solid #cecece;
  }

  .row1Right,
  .row2 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .row2 {
    padding-left: 5px;
    min-height: 22px;
  }

  .row2,
  table.striped tbody tr:nth-child(2n) td,
  table tbody tr.striped td {
    background-color: rgba(0, 0, 0, 0.06);
  }

  table.hover tbody tr:hover td {
    background-color: #e0e0e0;
    color: #000;
  }

  table.table {
    width: 100%;
  }

  table.table tbody td {
    vertical-align: top;
    padding: 5px 0 5px 5px;
  }

  table.small {
    font-size: 13px;
  }

  table.files tbody tr td {
    padding: 2px 0 2px 5px;
    height: 32px;
  }

  table.files i.fa-search {
    color: #000;
  }

  table.planholders tbody td {
    padding: 8px 0 8px 5px;
  }

  table.planholders i {
    font-size: 125%;
  }

  table tr.no-border td {
    border: none;
  }

  table.activity tbody tr td {
    cursor: pointer;
    height: 32px;
    vertical-align: middle;
  }

  table.activity td i {
    opacity: 0.4;
  }

  table.activity tbody td:nth-child(4) {
    white-space: nowrap;
  }

  table.activity tbody td:nth-child(4) span {
    display: inline-block;
    min-width: 32px;
    margin-right: 5px;
  }

  table.sortable thead td {
    cursor: pointer;
  }

  table.sortable thead td.sorter-false {
    cursor: default;
  }

  .tablesorter-headerAsc .tablesorter-header-inner:after {
    content: '\F106';
    font-family: FontAwesome;
    margin-left: 5px;
  }

  .tablesorter-headerDesc .tablesorter-header-inner:after {
    content: '\F107';
    font-family: FontAwesome;
    margin-left: 5px;
  }

  .row2Right {
    background-color: rgba(0, 0, 0, 0.06);
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
    border-right: 1px solid #cecece;
  }

  .row1Bottom {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .row1Bottom,
  .row2Bottom {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border-bottom: 1px solid #cecece;
  }

  .row2Bottom {
    background-color: rgba(0, 0, 0, 0.06);
  }

  .row1BottomRight {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .row1BottomRight,
  .row2BottomRight {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border-bottom: 1px solid #cecece;
    border-right: 1px solid #cecece;
  }

  .row2BottomRight {
    background-color: rgba(0, 0, 0, 0.06);
  }

  .rowOver,
  .rowOver td {
    background-color: #e0e0e0;
    color: #000;
    cursor: pointer;
  }

  .noBold {
    font-weight: 400;
  }

  .tblSubHeader,
  table.activity-detail thead td,
  table.files thead td {
    font-size: 12px;
    color: #fff;
    background-color: #999;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
  }

  .tblSubHeaderRight {
    font-size: 12px;
    font-weight: 700;
    color: #000;
    background-color: #b7b7cc;
    border-top: 1px solid #e9e9ef;
    border-right: 1px solid #babacf;
    border-bottom: 1px solid #babacf;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
  }

  .frmTextField {
    border: 1px solid #999;
    font-size: 12px;
  }

  .tblHeaderNoPad {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    border-top: 1px solid #9c9cbd;
    border-bottom: 1px solid #6b6b8c;
  }

  .row1NoPad,
  .row1RightNoPad {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .row1RightNoPad {
    border-right: 1px solid #cecece;
  }

  .tbl2 {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .png {
    behavior: url(/src/images/empty.png);
  }

  .monthCal {
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .monthCal thead {
    color: #fff;
  }

  .monthCal thead td {
    width: 14%;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
    border-bottom: 1px solid #6b6b8c;
    font-weight: 700;
    background-repeat: repeat-x;
  }

  .monthCal tbody tr {
    height: 80px;
  }

  .monthCal tbody td {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: rgba(0, 0, 0, 0.02);
  }

  .monthCal tbody td p {
    margin-top: 3px;
    margin-bottom: 0;
  }

  .monthCal .nums {
    font-weight: 700;
  }

  .monthCal .firstCol {
    border-left: 1px solid #ccc;
  }

  .legend .bidName,
  .legend .prebidName,
  .monthCal .bidName,
  .monthCal .prebidName {
    font-size: 11px;
    display: block;
    padding: 2px;
    overflow: hidden;
    border-radius: 5px;
  }

  .monthCal .bidName a,
  .monthCal .prebidName a {
    color: #000;
    text-decoration: none;
  }

  .legend .bidName,
  .monthCal .bidName {
    background-color: #cacaca;
  }

  .legend .prebidName,
  .monthCal .prebidName {
    background-color: #e7e7e7;
  }

  .monthCal .bid,
  .monthCal .preBid {
    font-size: 11px;
    color: #333;
    display: none;
  }

  .legend .bidName,
  .legend .prebidName {
    font-weight: 700;
    float: left;
    padding: 5px;
    margin-right: 10px;
  }
  .downloadGroup > span {
    margin-left: 3px;
  }

  .download-group,
  .order-group {
    display: flex;
    align-items: center;
  }

  #showPrint,
  .print-only {
    display: none;
  }

  .msg {
    text-align: center;
    color: #00f;
    font-weight: 700;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #00f;
  }

  .sectionLine {
    margin: 5px 0;
  }

  .downloadSectionIfLogged {
    border: 1px solid #cecece;
    padding: 5px;
    background-color: #efefef;
  }

  .statusnote {
    padding: 10px;
    margin: 10px 0;
    min-height: 60px;
  }

  .banner,
  .statusnote {
    border: 1px solid #fc3;
    background-color: #ffedb7;
  }

  .banner {
    padding: 5px;
    margin: 10px 0 0;
  }

  .banner #response {
    display: none;
    margin-top: 10px;
    width: 100%;
    font-size: 0.9rem;
  }

  .banner #bidNotes {
    width: 350px;
    height: 58px;
    margin-top: 10px;
    padding: 5px;
    font-size: 13px;
    color: #999;
  }

  .banner #response .mark {
    background-color: inherit;
  }

  .banner #responseBtn {
    float: right;
  }

  .banner label {
    cursor: pointer;
  }

  .jquery-cb,
  .jquery-cbMaybe,
  .jquery-checkbox {
    display: inline;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
  }

  .jquery-cb .mark,
  .jquery-cbMaybe .mark,
  .jquery-checkbox .mark {
    display: inline;
  }

  .jquery-cb img,
  .jquery-cbMaybe img,
  .jquery-checkbox img {
    vertical-align: middle;
    width: 24px;
    height: 29px;
  }

  .jquery-checkbox img {
    background: transparent url(/src/images/empty.png) no-repeat;
  }

  .jquery-cb img {
    background: transparent url(/src/images/empty.png) no-repeat;
  }

  .jquery-cbMaybe img {
    background: transparent url(/src/images/empty.png) no-repeat;
  }

  .jquery-cb img,
  .jquery-cbMaybe img,
  .jquery-checkbox img {
    background-position: 0 0;
  }

  .jquery-cb-checked img,
  .jquery-cbMaybe-checked img,
  .jquery-checkbox-checked img {
    background-position: 0 -29px;
  }

  .message-box {
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 6px #999;
  }

  .error-box {
    background-color: #f9e8e8;
    border: 2px solid #ea1515;
  }

  #progress {
    text-align: center;
    margin: 0 0 15px;
    background-image: url(/src/images/empty.png);
  }

  #progress ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-image: url(/src/images/empty.png);
    background-position: 100% 0;
  }

  #progress li,
  #progress ul {
    height: 26px;
    background-repeat: no-repeat;
  }

  #progress li {
    float: left;
    background-image: url(/src/images/empty.png);
    padding-top: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #666;
  }

  #progress .step {
    width: 30%;
  }

  #progress .start {
    background-image: url(/src/images/empty.png);
    padding: 0 10px;
  }

  #progress .active {
    background-image: url(/src/images/empty.png);
    color: #000;
  }

  #progress .after {
    background-image: url(/src/images/empty.png);
  }

  #progress .end {
    width: 12px;
  }

  #promos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    padding-top: 30px;
  }

  #promos:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 86%;
    top: -10px;
    height: 10px;
    left: 7%;
    border-radius: 100px/5px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
  }

  #promos .box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    background-image: linear-gradient(hsla(0, 0%, 100%, 0.5), transparent 60%, rgba(0, 0, 0, 0.04));
    background-position: 100% 100%;
    background-repeat: no-repeat;
    flex: 1;
    font-size: 11px;
    padding: 10px 9px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    min-width: 230px;
    margin: 0 5px 15px;
  }

  #promos .inner {
    flex-grow: 1;
  }

  #promos .aec form,
  #promos .jobs form {
    display: flex;
    justify-content: space-between;
  }

  #promos .jobs .project-key {
    flex-grow: 1;
  }

  #promos .aec {
    background-image: url(/src/images/empty.png);
  }

  #promos .sub {
    background-image: url(/src/images/empty.png);
  }

  #promos .upload {
    background-image: url(/src/images/empty.png);
  }

  #promos .globe {
    background-image: url(/src/images/empty.png);
  }

  #promos h3 {
    font-size: 15px;
  }

  #promos h4 {
    margin: 0 0 10px;
    font-size: 12px;
    font-weight: 400;
    color: #666;
    line-height: inherit;
  }

  #promos p {
    margin: 0 0 5px;
  }

  #promos ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  #promos ul li {
    background: transparent url(/src/images/empty.png) no-repeat 2px 7px;
    padding-left: 15px;
  }

  #promos .empty {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
    line-height: 20px;
  }

  #promos .icons li {
    background-position: 0 2px;
    padding-left: 23px;
    margin-bottom: 5px;
    color: #333;
  }

  #promos .jobs .inner {
    display: flex;
    flex-direction: column;
  }

  #promos .jobs .scroll {
    font-size: 12px;
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 5px;
    margin-top: 10px;
    max-height: 190px;
  }

  #promos .jobs .job {
    line-height: 16px;
    margin-bottom: 7px;
  }

  #promos .private {
    margin-top: 5px;
  }

  #promos .jobs form {
    margin: 0;
  }

  #bannerlinks {
    height: 50px;
    background-image: url(/src/images/empty.png);
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 0 10px;
  }

  #bannerlinks div {
    margin-right: 72px;
    float: left;
    padding: 10px 0;
  }

  #bannerlinks a {
    text-decoration: none;
    font-weight: 700;
    font-size: 13px;
  }

  #bannerlinks .right {
    margin-right: 0;
  }

  #bannerlinks img {
    float: left;
    margin-right: 5px;
  }

  #ie-notice {
    display: none;
    border: 1px solid #ccbd92;
    margin-bottom: 15px;
    line-height: 15px;
    padding: 10px 10px 10px 65px;
    background: #ffedb7 url(/src/images/empty.png) no-repeat 15px 10px;
    color: #000;
    text-decoration: none;
  }

  #ie-notice p {
    margin: 0;
  }

  #ie-notice a {
    font-weight: 700;
    color: #000;
  }

  #ie-notice h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  #content .fa-times,
  #queueSummary .fa-times {
    color: #ea4100;
    font-size: 130%;
  }

  #btnDashboard {
    margin-bottom: 10px;
  }

  .spinner {
    margin: auto;
    height: 40px;
    width: 40px;
    -webkit-animation: rotate 0.8s linear infinite;
    animation: rotate 0.8s linear infinite;
    border: 8px solid #ccc;
    border-right-color: transparent;
    border-radius: 50%;
  }

  .spinner-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.1;
    margin: 10px 0;
    color: #333;
  }

  .noscroll {
    overflow: hidden;
  }

  .featherlight {
    background: rgba(0, 0, 0, 0.8);
    cursor: default;
    z-index: 1000;
  }

  .featherlight .featherlight-content {
    width: 800px;
    background-color: #eee !important;
    color: #333;
    border: 5px solid #999 !important;
    border-radius: 5px;
    padding: 0 !important;
  }

  .featherlight.viewer .featherlight-content {
    width: 90%;
    height: 90%;
    padding: 0;
    background-color: #fff !important;
    border-radius: 0;
    border: none;
  }

  .featherlight.viewer .viewer-container {
    position: absolute;
    top: 26px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .featherlight-close-icon {
    color: #fff !important;
    background: none !important;
    font-size: 14px;
  }

  .featherlight-content .title {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.75em;
    border: 1px solid #fff;
    padding: 0;
  }

  .dqDialog {
    padding: 10px;
  }

  .lead {
    font-size: 125%;
  }

  .card {
    border-radius: 5px;
  }

  .card.icon {
    flex-direction: row;
  }

  .card.icon > i {
    font-size: 48px;
    opacity: 0.2;
    margin: 15px 0 0 15px;
    width: 48px;
    text-align: center;
  }

  .card > .content {
    padding: 15px;
  }

  .card > .content > h2 {
    margin-top: 0;
  }

  .sectionHeader td {
    padding: 4px 0 5px 7px;
  }

  .alert {
    border-radius: 1px;
  }

  form .alert {
    padding: 7px 11px;
  }

  .alert-danger {
    background-color: #ffe6e6;
    border-color: #c00;
    color: #363636;
  }

  .alert-danger i {
    color: #c00;
  }

  div.print {
    float: right;
    margin-bottom: 10px;
  }

  .modal-inner {
    padding: 10px;
  }

  .modal-inner h2,
  .modal-inner h3 {
    margin: 5px 0;
    font-weight: 400;
  }

  .modal-inner .table-wrapper {
    border: 1px solid #666;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 10px;
    background-color: #fff;
    clear: both;
  }

  .hidden {
    display: none;
  }

  .viewer-loading {
    text-align: center;
    margin: 120px auto auto;
  }

  .thumbContainer {
    background-image: url(/src/images/empty.png);
    background-repeat: no-repeat;
    background-position: 10px 10px;
    min-width: 100px;
    min-height: 100px;
    border: 1px solid grey;
    font-family: arial;
    font-size: 12px;
    background-color: #fff;
    position: absolute;
    display: none;
  }

  .control-group {
    clear: left;
  }

  .badge-secondary {
    color: #fff;
    background-color: #6c757d;
  }

  @media print {
    @page {
      size: 8.5in 11in;
      margin: 0.5in;
    }

    .main,
    body {
      display: block !important;
      min-width: auto !important;
      border: none;
      color: #000;
    }

    #container,
    body,
    html {
      background-color: #fff !important;
      background-image: none !important;
      border: none !important;
    }

    #container,
    #content,
    #mainTable,
    .monthCal {
      width: 100% !important;
      padding: 0;
    }

    .legend .bidName,
    .legend .prebidName,
    .monthCal .bidName,
    .monthCal .prebidName {
      background-color: #fff;
      color: #000;
    }

    .legend .bidName,
    .monthCal .bid,
    .monthCal .bidName {
      font-weight: 700;
    }

    .legend .prebidName,
    .monthCal .preBid,
    .monthCal .prebidName {
      font-weight: 400;
      font-style: italic;
    }

    .monthCal .bid,
    .monthCal .preBid {
      color: #333;
      display: inline;
    }

    .monthCal .bid a,
    .monthCal .bidName a,
    .monthCal .prebidName a {
      color: #000;
    }

    .monthCal .bidName a:visited,
    .monthCal .prebidName a:visited {
      color: #000;
      text-decoration: none;
    }

    .print-only {
      display: block;
    }

    h2.print-only {
      margin: 25px 0;
    }

    .tblHeader,
    .tblHeaderRow td,
    .tblHeaderRow th {
      color: #000 !important;
      background-color: #fff !important;
    }

    .tablesorter-header-inner:after {
      content: '' !important;
    }

    .modal-inner .table-wrapper {
      max-height: inherit;
      border: 0;
      overflow-y: auto;
    }
  }

  @-webkit-keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }

  #sidebar .tblHeader2 {
    background-color: transparent;
    color: #818a91;
    padding: 5px 0;
    font-size: 1rem;
    font-weight: 400;
  }

  .sidebarBox table,
  .sidebarBox td,
  .sidebarBox th,
  .sidebarBox tr {
    border: none;
  }

  .sidebarBox .col2 {
    padding-bottom: 10px;
  }

  .sidebarBox img {
    display: none;
  }

  #menuContainer table,
  #menuContainer td,
  #menuContainer tr {
    background-color: transparent;
  }

  #loginContainer .tblHeader2,
  .sidebarBox th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  #checkoutForm table input[type='text'],
  #checkoutForm table select {
    font-size: 1rem !important;
    min-width: 200px;
  }

  .bidCatsTbl div {
    padding: 5px;
  }

  .page-public-jobs #filter input,
  .page-public-jobs #filter select {
    font-size: 1rem !important;
    width: auto !important;
  }

  .page-private-jobs #jobs .row1,
  .page-public-jobs #jobs .row1 {
    padding: 10px 0 10px 5px;
    background-color: transparent;
  }

  .page-private-jobs .rowOver td,
  .page-public-jobs .rowOver td {
    background-color: #eee;
  }

  .page-account .jqmWindow {
    width: 650px !important;
  }

  #feedback-header i {
    margin-top: 2px;
  }

  #feedback-body input,
  #feedback-body textarea {
    padding: 6px;
  }

  #feedback-body .input-wrap {
    margin: 0 0 11px;
  }

  @media (max-width: 768px) {
    #feedback-container {
      display: none;
    }
  }

  .download-wrapper.is-empty,
  .order-wrapper.is-empty {
    display: block;
  }

  .page-project .download-wrapper.is-empty,
  .page-project .order-wrapper.is-empty,
  .page-supplies .order-wrapper.is-empty {
    display: block;
  }

  #promos .jobs .project-key {
    width: 80%;
    font-size: 0.9rem;
  }
  .badge {
    font-size: 80%;
  }

  html.old-ie #browser-notice {
    display: block !important;
  }

  html.old-ie {
    margin-top: 45px;
  }

  #browser-notice a {
    color: red;
  }

  @media (max-width: 768px) {
    body.mobile-clean {
      background: #fff;
    }

    body.mobile-clean .header-main-wrapper {
      box-shadow: none;
      display: block;
    }

    body.mobile-clean .main {
      border: none;
    }

    body.mobile-clean .container {
      width: 100% !important;
    }

    body.mobile-clean .main__content {
      min-height: auto;
    }

    body.mobile-clean .row {
      padding: 0 30px;
    }

    body.mobile-clean .copyright,
    body.mobile-clean .fa-sign-in,
    body.mobile-clean .footer-wrapper,
    body.mobile-clean .header-wrapper,
    body.mobile-clean .register,
    body.mobile-clean .sidebar,
    body.mobile-clean .subheading {
      display: none;
    }

    body.mobile-clean .row > div:first-child {
      border: none;
      padding: 0;
    }
  }

  body,
  html {
    font-size: 14px;
  }

  body {
    padding: 0;
    margin: 0;
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    color: #373a3c;
    background-color: #eee;
  }

  .link,
  a {
    color: #039;
    color: var(--link-color, #039);
    text-decoration: none;
  }

  .link:hover,
  a:hover {
    color: #000;
    cursor: pointer;
    text-decoration: underline;
  }

  h1 {
    margin-top: 0;
    font-size: 1.7rem;
    color: #000;
    font-weight: 400;
  }

  h1,
  h2 {
    line-height: 1.1;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  table {
    font-size: 0.9rem;
  }

  th {
    text-align: left;
    font-weight: 400;
  }

  [v-cloak] {
    display: none;
  }

  .t-primary-bgcolor {
    background-color: #333;
    background-color: var(--primary-color, #333);
  }

  .container {
    max-width: 100%;
    width: 840px;
    margin: auto;
    position: relative;
  }

  .wrapper {
    flex: 1 0 auto;
    text-align: center;
  }

  .header-main-wrapper {
    text-align: left;
    background: inherit !important;
  }

  .header {
    padding: 0;
  }

  .header__contact {
    position: absolute;
    top: 20px;
    right: 20px;
    text-align: right;
    font-size: 15px;
    line-height: 22px;
  }

  .main {
    padding: 1.5rem;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
  }

  .main__content {
    order: 1;
    flex: 1;
    min-height: 500px;
    min-width: 300px;
  }

  .sidebar {
    width: 185px;
    flex-shrink: 0;
    margin-right: 1.5rem;
    font-size: 15px;
    line-height: 1.5;
  }

  .sidebar .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
  }

  .footer-wrapper {
    margin-top: 40px;
  }

  .copyright {
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    font-size: 0.85rem;
    order: 3;
    margin-top: 30px;
  }

  .copyright,
  .copyright a {
    color: #999;
  }

  .empty-message {
    font-size: 1.2rem;
    font-weight: 400;
    opacity: 0.6;
    padding: 20px 0;
    text-align: center;
    border: 1px dotted #d3d3d3;
    position: relative;
    margin: 20px 0;
  }
  .price-description {
    font-weight: 700;
    font-size: 0.9em;
    opacity: 0.7;
  }

  .alert-icon {
    display: flex;
  }

  .alert-icon > .fa {
    font-size: 1.5rem;
    margin: 0.25rem 1rem 0 0;
  }

  .featherlight .title {
    border: none;
  }

  .listview {
    margin: 0 0.75rem;
  }

  .listview .row {
    margin: 0 -0.75rem;
  }

  .listview .col {
    padding: 0;
  }

  .listview .listview__footer,
  .listview .listview__header {
    font-size: 0.85rem;
    margin: 0.9rem -0.25rem;
  }

  .listview .listview__footer .row,
  .listview .listview__header .row {
    padding: 0.5rem;
  }

  .listview .listview__footer .links > *,
  .listview .listview__header .links > * {
    margin-right: 10px;
  }

  .listview .listview__footer .links > div,
  .listview .listview__header .links > div {
    display: inline-block;
  }

  .listview .listview__body .row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    padding: 1rem 0.5rem;
  }

  .listview .listview__body .row:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.09);
  }

  .listview--selectable .listview__body .row:hover {
    background-color: rgba(0, 0, 0, 0.06);
    cursor: pointer;
    text-decoration: none;
  }

  .modal-dialog .modal-content {
    background-color: transparent;
  }

  .modal-dialog .close {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #fff;
    text-shadow: none;
  }

  .modal-dialog .modal-header {
    background-color: #565b63;
    color: #c2c6c9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 0;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-bottom: none;
    min-height: 44px;
  }

  .modal-dialog .modal-body {
    background-color: #fff;
  }

  .modal-dialog .modal-footer {
    background-color: #f7f8fb;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    justify-content: space-between;
    flex-shrink: 0;
    color: #798496;
  }

  .dropdown-item {
    font-size: 0.95rem;
    padding: 3px 1.2rem 3px 2rem;
    width: auto;
    cursor: pointer;
  }

  .dropdown-item.selected:before {
    font-family: FontAwesome;
    content: '\F00C';
    position: absolute;
    left: 10px;
    color: #b4b9bd;
  }

  .dropdown-item.selected.sort-asc:before {
    font-family: FontAwesome;
    content: '\F0D8';
    position: absolute;
    left: 10px;
    color: #b4b9bd;
  }

  .dropdown-item.selected.sort-desc:before {
    font-family: FontAwesome;
    content: '\F0D7';
    position: absolute;
    left: 10px;
    color: #b4b9bd;
  }

  .dropdown-toggle {
    cursor: pointer;
  }

  input[type='checkbox'] {
    margin: 3px 4px;
  }

  .input {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.2rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 2px rgba(10, 10, 0, 0.1);
    line-height: 1.25;
  }

  .input:hover {
    border-color: #7dc3e8;
  }

  select.input {
    height: calc(2.6rem - 3px);
  }

  .input--small {
    font-size: 0.9rem;
    padding: 0.3rem 0.5rem;
  }

  .has-error .input,
  .has-error input,
  .has-error input:hover,
  .has-error select,
  .has-error select:hover,
  .input:invalid,
  .is-error {
    border: 1px solid red;
  }

  .form-group.required label {
    font-weight: 700;
  }

  .itb-response .response-missing {
    display: none;
  }

  .itb-response h3 {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .itb-response .form-check-inline,
  .itb-response .form-check-label {
    padding-left: 0;
  }

  .itb-response .form-check-input {
    margin-right: 0.25rem;
  }

  .itb-response input[type='radio'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .itb-response input[type='radio'] + label:before {
    font-family: FontAwesome;
    display: inline-block;
    content: '\F1DB';
    letter-spacing: 10px;
    font-size: 1.2em;
    color: #535353;
    width: 1em;
  }

  .itb-response input[type='radio']:checked + label:before {
    font-size: 1.2em;
    letter-spacing: 5px;
  }

  .itb-response input#responseYes:checked + label:before {
    color: green;
    content: '\F058';
  }

  .itb-response input#responseNo:checked + label:before {
    color: red;
    content: '\F057';
  }

  .itb-response input#responseMaybe:checked + label:before {
    color: #00f;
    content: '\F059';
  }

  .itb-response .responded {
    display: flex;
  }

  .itb-response .responded span {
    padding: 3px 0;
    flex-grow: 1;
  }

  .itb-response .response_1 {
    color: green;
    font-weight: 700;
  }

  .itb-response .response_0 {
    color: red;
    font-weight: 700;
  }

  .itb-response .response_2 {
    color: #00f;
    font-weight: 700;
  }

  .sidebar__nav {
    display: flex;
    flex-direction: column;
  }

  .sidebar__nav a {
    padding: 3px 0;
    color: #000;
  }

  .sidebar__nav a:hover {
    color: #666;
    text-decoration: none;
  }

  .pagination--simple {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  .pagination--simple .disabled {
    color: #818a91;
    opacity: 0.7;
  }

  .pagination--simple .label {
    margin: 0 10px;
    color: #818a91;
  }

  .pagination--simple .fa {
    font-weight: 700;
  }

  .project-details .items {
    display: flex;
    flex-wrap: wrap;
  }

  .project-details .items .item {
    flex: 1;
    display: flex;
    margin: 15px 0;
    min-width: 300px;
  }

  .project-details .items .item .icon {
    width: 35px;
    text-align: center;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .project-details .items .item .icon i {
    font-size: 200%;
    opacity: 0.3;
  }

  .project-details .items .item h3 {
    margin: 0 0 7px;
    font-weight: 400;
  }

  .project-details .items .item h4 {
    margin: 0 0 5px;
  }

  .project-details .items .item p {
    margin: 0;
  }

  .project-details .items .item.status strong {
    display: inline-block;
    width: 90px;
    color: #666;
  }

  .project-details .notes {
    clear: both;
  }

  .project-details .notes section {
    display: flex;
    border-top: 5px solid rgba(0, 0, 0, 0.05);
    padding-top: 25px;
    margin-top: 10px;
  }

  .project-details .notes section .icon {
    width: 35px;
    text-align: center;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .project-details .notes section .icon i {
    font-size: 200%;
    opacity: 0.3;
  }

  .project-details .notes section.downloading i:before {
    content: '\F019';
  }

  .project-details .notes section.ordering i:before {
    content: '\F02F';
  }

  .section {
    margin: 1.2rem 0;
  }

  .section__heading {
    padding: 4px 8px;
    color: #fff;
  }

  .section__heading:hover {
    cursor: pointer;
    background-color: #000;
  }

  .section__heading .fa {
    width: 15px;
  }

  .section--open .section__heading .fa-folder-o,
  .section__heading .fa-folder-open-o {
    display: none;
  }

  .section--open .section__heading .fa-folder-open-o {
    display: inline-block;
  }

  .section__content {
    display: none;
    margin: 1rem 0;
  }

  .section--open .section__content {
    display: block;
  }

  .section__notes {
    padding: 1rem;
    margin: 1.2rem 0;
    background-color: #fcf8e3;
    border: 1px solid #faf2cc;
  }

  .slideshow {
    clear: both;
    font-size: 1rem;
  }

  body.mobile-nav-open .slideshow {
    display: none;
  }

  .slideshow h1 {
    font-size: 1.7em;
    color: #fff;
    border: none;
    font-weight: 400;
    line-height: 35px;
  }

  .slideshow h2 {
    font-size: 1.3em;
  }

  .slideshow p {
    font-size: 1.2em;
    font-weight: 300;
  }

  .slideshow--nivo img {
    display: none;
  }

  .slideshow--static img {
    width: 100%;
  }

  .slideshow__links {
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
  }

  .slideshow__links a {
    text-decoration: none;
    font-weight: 700;
    font-size: 13px;
  }

  .slideshow__links img {
    vertical-align: sub;
    margin-right: 5px;
  }

  .slideshow a:hover {
    text-decoration: none;
  }

  .swal-modal {
    border-radius: 0.3rem;
  }

  .swal-text {
    line-height: 1.5;
    text-align: center;
  }

  .swal-button--confirm {
    font-weight: 400;
    color: #fff;
    text-shadow: 0 1px 0 #555;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), inset 0 14px 16px hsla(0, 0%, 100%, 0.2),
      inset 0 0 4px hsla(0, 0%, 100%, 0.2);
    background-color: #2e78ba;
    border-color: #2e78ba;
  }

  .swal-button--confirm:enabled:hover {
    color: #fff;
    box-shadow: 0 0 4px #4d90fe, inset 0 14px 16px hsla(0, 0%, 100%, 0.2),
      inset 0 0 4px hsla(0, 0%, 100%, 0.2);
    background-color: #266198;
  }

  .swal-button--confirm:enabled:active {
    background-color: #122d49;
  }

  .swal-button--cancel {
    background: none;
    padding: 0;
    font-weight: 400;
    color: #4a4a4a;
    margin-right: 10px;
  }

  .swal-button--cancel:not([disabled]):hover {
    background-color: transparent;
    text-decoration: underline;
  }

  .table td,
  .table th {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  .modal.wizard {
    overflow-y: hidden;
  }

  .modal.wizard .modal-dialog {
    max-width: 600px;
    font-size: 1rem;
  }

  .modal.wizard .modal-content-wrapper {
    display: flex;
    flex-direction: row;
  }

  .modal.wizard .modal-content {
    max-height: 90vh;
    background-color: transparent;
    width: 100%;
  }

  .modal.wizard .close {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #fff;
    text-shadow: none;
  }

  .modal.wizard .modal-header {
    background-color: #565b63;
    color: #c2c6c9;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    padding: 0;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-bottom: none;
    min-height: 44px;
  }

  .modal.wizard .modal-header h4 {
    margin-top: 0.5rem;
  }

  .modal.wizard .modal-header div {
    text-align: center;
    padding: 1.5rem 1rem;
    z-index: 1;
    position: relative;
  }

  .modal.wizard .modal-header div:before {
    content: '';
    width: 100%;
    position: absolute;
    border-bottom: 1px solid #31343b;
    left: 0;
    top: 28px;
    z-index: -1;
  }

  .modal.wizard .modal-header div:first-child:before {
    width: 50%;
    left: 50%;
  }

  .modal.wizard .modal-header div:last-child:before {
    width: 50%;
    right: 50%;
  }

  .modal.wizard .modal-header div i.fa {
    display: block;
    margin-bottom: 5px;
  }

  .modal.wizard .modal-header div i.fa:before {
    content: '\F111';
    font-size: 0.9rem;
    color: #31343b;
  }

  .modal.wizard .modal-header .active {
    color: #fff;
    font-weight: 700;
  }

  .modal.wizard .modal-header .active i.fa:before {
    color: #fff;
  }

  .modal.wizard .modal-header .complete i.fa:before {
    content: '\F058';
    color: #c2c6c9;
  }

  .modal.wizard .modal-body {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    min-height: 250px;
    text-align: left;
    background-color: #fff;
    padding: 15px;
  }

  .modal.wizard .modal-footer {
    flex-shrink: 0;
    background-color: #f7f8fb;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    justify-content: space-between;
    color: #798496;
  }

  .modal.wizard .modal-footer .cancel {
    color: #4a4a4a;
    margin-right: 10px;
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    .modal.wizard .modal-dialog {
      max-width: 100%;
    }
  }

  .page-projects .filter {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .page-projects .filter .btn-group {
    display: flex;
    margin: 5px 5px 5px 0;
  }

  .page-projects .filter > * {
    margin-right: 5px;
  }

  .page-projects .distance {
    display: flex;
  }

  .page-projects .distance > * {
    margin-right: 5px;
  }

  .page-projects .distance .fa-map-marker {
    font-size: 1.5rem;
    opacity: 0.6;
    padding-top: 5px;
    margin-right: 10px;
  }

  .page-projects .filter__distance {
    display: flex;
  }

  .page-projects .search {
    width: 300px;
    margin: 5px 5px 5px 0;
  }

  .page-projects .private-key {
    margin: 2rem 0;
  }

  .page-projects .private-key form {
    display: flex;
  }

  .page-projects .private-key form > * {
    margin-right: 5px;
  }

  .page-projects .empty-message {
    padding-left: 50px;
    opacity: 1;
  }

  .page-projects .empty-message:before {
    content: '\F002';
    font-family: FontAwesome;
    position: absolute;
    left: 20px;
  }

  .page-projects .empty-message .reset {
    font-size: 1rem;
    margin-top: 1rem;
  }

  .page-projects .pagination-wrapper {
    min-height: 21px;
  }

  .page-projects .save-view.active {
    font-weight: 700;
  }

  .page-projects .listview-wrapper {
    margin-top: 2rem;
  }

  .page-projects .listview__footer i,
  .page-projects .listview__header i {
    margin-top: 4px;
  }

  .page-projects .listview__footer .links i,
  .page-projects .listview__header .links i {
    margin-right: 3px;
    width: 12px;
  }

  .page-projects .listview__body .row {
    min-height: 80px;
  }

  .page-projects .listview__body .row .name {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .page-projects .listview__body .row .name i {
    font-size: 1rem;
  }

  .page-projects .listview__body .row i.favorite {
    color: #ffa000;
  }

  .page-projects .listview__body .row i.unlocked {
    color: #89db33;
  }

  .page-projects .listview__body .row i.locked {
    color: #ffa000;
  }

  .page-projects .listview__body .row .description {
    color: #434b51;
    max-height: 3rem;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .page-projects .listview__body .row .details {
    color: #818a91;
    margin: 0.5rem 0;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  .page-projects .listview__body .row .details i {
    color: #818a91;
    margin-right: 3px;
    line-height: 1.8;
  }

  .page-projects .listview__body .row .details > div {
    display: inline-block;
    margin-right: 10px;
  }

  .page-projects .listview__body .row .bid-date {
    font-weight: 700;
  }

  .page-projects .listview__body .row .notification {
    display: inline-block;
    color: #373a3c;
    font-size: 0.9rem;
    margin: 0 0 1rem 1rem;
  }

  .page-projects .listview__body .row .notification.bidding-closed {
    color: #818a91;
  }

  .page-projects .listview__body .row i.bidding {
    color: #89db33;
  }

  .page-projects .listview__body .row i.bidding-soon {
    color: #ffa000;
  }

  .page-projects .listview__body .row i.bidding-today {
    color: red;
  }

  .page-projects .listview__body .row i.bidding-closed {
    color: #ccc;
  }

  .page-projects .listview__body .row i.planholder {
    color: #00b6ff;
  }

  .page-project h1.project-name {
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .page-project h1.project-name img {
    margin-right: 10px;
    cursor: pointer;
  }

  .page-project h1.project-name .private {
    font-size: 12px;
    color: #999;
    font-weight: 400;
    margin-left: 12px;
    white-space: nowrap;
  }

  @media (min-width: 992px) {
    .page-project h1.project-name img {
      margin: 0 6px 0 -30px;
    }
  }

  body.page-register .terms {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #bbb;
    background-color: #f5f5f5;
    padding: 1rem;
  }

  body.page-register .trades {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  body.page-register .trades label {
    display: block;
    font-weight: 400;
    white-space: nowrap;
  }

  @media (min-width: 1024px) {
    body.page-register .inset-centered {
      margin: 3rem 6rem;
    }
  }

  .page-calendar .main__content .event {
    font-size: 11px;
    border-radius: 2px;
    padding: 3px;
    border: 1px solid transparent;
  }

  .page-calendar .main__content .bidding {
    background-color: #1da1e6;
    background-color: var(--accent-color-dark, #1da1e6);
    color: #fff;
  }

  .page-calendar .main__content .bidding.disabled {
    background-color: #fff;
    color: #ccc;
    border-color: #ccc;
  }

  .page-calendar .main__content .prebidding {
    color: #444;
    background-color: #eee;
    background-color: var(--accent-color-light, #eee);
  }

  .page-calendar .main__content .prebidding.disabled {
    background-color: #fff;
    color: #ccc;
    border-color: #ccc;
  }

  .page-calendar .main__content table {
    display: none;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
  }

  .page-calendar .main__content table thead th {
    width: 15%;
    color: #fff;
    padding: 5px 3px;
  }

  .page-calendar .main__content table tbody td {
    vertical-align: top;
    height: 100px;
    border: 1px solid #ddd;
  }

  .page-calendar .main__content table tbody td .day {
    font-size: 0.9rem;
    padding: 3px;
    margin-bottom: 2px;
  }

  .page-calendar .main__content table tbody td a.event {
    display: block;
    margin: 1px;
  }

  .page-calendar .main__content table tbody td.adjacent-month {
    background-color: #f5f5f5;
  }

  .page-calendar .main__content table tbody td.adjacent-month .day,
  .page-calendar .main__content table tbody td.weekend .day {
    opacity: 0.5;
  }

  .page-calendar .main__content table tbody td.today {
    border: 2px solid #1da1e6;
    border-color: var(--accent-color-dark, #1da1e6);
  }

  .page-calendar .main__content table tbody td.today .day {
    font-weight: 700;
  }

  .page-calendar .main__content .results {
    margin-top: 2rem;
  }

  .page-calendar .main__content .listview {
    margin: 0;
  }

  .page-calendar .main__content .listview__header {
    margin: 0.9rem 0;
    display: flex;
    align-items: center;
  }

  .page-calendar .main__content .listview__header .links i {
    margin-right: 3px;
    width: 12px;
  }

  .page-calendar .main__content .pagination {
    margin-right: 15px;
    font-size: 1.1rem;
    display: flex;
    align-items: baseline;
  }

  .page-calendar .main__content .pagination .label {
    color: #000;
  }

  .page-calendar .main__content .pagination i {
    margin-top: 4px;
  }

  @media print {
    .page-calendar .main__content table thead th {
      color: #000;
    }

    .page-calendar .main__content .event {
      background-color: #fff;
      color: #000;
    }

    .page-calendar .main__content .prebidding {
      font-style: italic;
      color: #999;
    }
  }

  .download-checkout .modal-body {
    font-size: 0.95rem;
  }

  .download-checkout .modal-body h2 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .download-checkout .modal-body .card.icon {
    padding: 2rem 1.2rem;
    margin: 20px 0;
  }

  .download-checkout .modal-body .card.icon > .fa {
    font-size: 2.5rem;
    opacity: 0.3;
    margin: 5px;
  }

  .download-checkout .modal-body .card.icon .fa-warning {
    color: #b8860b;
  }

  .download-checkout .modal-body .card.icon .message {
    font-size: 1.1rem;
    padding: 0 0.5rem;
    flex-grow: 1;
  }

  .download-checkout .modal-body .meta {
    color: #818a91;
    font-size: 0.9rem;
  }

  .download-checkout .modal-body.review .icon .fa {
    margin: 2px 4px;
    font-size: 1.25rem;
    color: #a4a4a4;
  }

  .download-checkout .modal-body.review .delete .fa {
    opacity: 0.4;
  }

  .download-checkout .modal-body.review .delete .fa:hover {
    opacity: 1;
    color: red;
    cursor: pointer;
  }

  .download-checkout .modal-body.credit_balance,
  .download-checkout .modal-body.download,
  .download-checkout .modal-body.empty,
  .download-checkout .modal-body.payment {
    padding: 20px 50px;
  }

  .download-checkout .modal-body.credit_balance,
  .download-checkout .modal-body.download,
  .download-checkout .modal-body.empty {
    padding-top: 50px;
  }

  .download-checkout .modal-body.payment .cardtypes {
    font-size: 2rem;
    color: #c7c7c7;
  }

  .download-checkout .modal-body.payment .cardtypes .fa {
    margin-right: 2px;
  }

  .download-checkout .modal-body.payment .expiration {
    display: flex;
  }

  .download-checkout .modal-body.payment .expiration select[name='expiration_month'] {
    flex-basis: 70%;
    margin-right: 5px;
  }

  .download-checkout .modal-body.payment .expiration select[name='expiration_year'] {
    flex-basis: 30%;
  }

  .download-checkout .modal-body.payment input[name='cvv'] {
    width: 100px;
  }

  .download-checkout .modal-body.download .card,
  .download-checkout .modal-body.empty .card {
    align-items: center;
  }

  .download-checkout .modal-footer .due {
    font-size: 1.1rem;
  }

  .download-checkout .modal-footer .tax {
    font-size: 0.9rem;
    opacity: 0.7;
  }

  .download-checkout .modal-footer .secure .fa {
    font-size: 1.9rem;
    vertical-align: middle;
    margin-right: 5px;
    color: #daa520;
  }

  .u-clearfix:after {
    content: '';
    display: table;
    clear: both;
  }

  .u-hide-empty:empty {
    display: none;
  }

  .op-1 {
    opacity: 0.1;
  }

  .op-2 {
    opacity: 0.2;
  }

  .op-3 {
    opacity: 0.3;
  }

  .op-4 {
    opacity: 0.4;
  }

  .op-5 {
    opacity: 0.5;
  }

  .op-6 {
    opacity: 0.6;
  }

  .op-7 {
    opacity: 0.7;
  }

  .op-8 {
    opacity: 0.8;
  }

  .op-9 {
    opacity: 0.9;
  }

  .text-xs {
    font-size: 0.8em;
  }

  .text-sm {
    font-size: 0.9em;
  }

  .text-normal {
    font-size: 1em;
  }

  .text-lg {
    font-size: 1.1em;
  }

  .mobile-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 15px !important;
    padding-left: 15px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 101;
    position: relative;
  }

  .mobile-header i.fa-bars {
    font-size: 1.5rem;
    opacity: 0.5;
  }

  .mobile-header__logo {
    max-height: 100px;
    max-width: 250px;
  }

  .mobile-header__nav {
    display: none;
    position: fixed;
    padding: 50px 30px 30px;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #28303f;
    z-index: 10000;
  }

  .mobile-header__nav i.fa-times {
    font-size: 1.5rem;
    color: #fff;
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 10px;
  }

  .mobile-header__nav a {
    padding: 10px 0;
    font-size: 18px;
    color: #fff;
    opacity: 0.8;
  }

  body.mobile-nav-open .mobile-header__nav {
    display: flex;
    overflow: hidden;
  }

  .mobile-subheader {
    background-color: #f0f0f0;
    justify-content: space-between;
    margin-bottom: 5px;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 100;
    font-size: 1rem;
  }

  .mobile-subheader,
  .mobile-subheader a {
    color: #333;
    display: flex;
    align-items: center;
  }

  .mobile-subheader a {
    padding: 0.5rem 0;
  }

  .mobile-subheader a.is-empty {
    display: none;
  }

  .mobile-subheader a:active,
  .mobile-subheader a:hover {
    background-color: hsla(0, 0%, 100%, 0.2);
    text-decoration: none;
  }

  .mobile-subheader i.fa {
    font-size: 1.3rem;
    opacity: 0.7;
  }

  .mobile-subheader .bubble {
    color: #fff;
    font-size: 10px;
    background-color: red;
    min-width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -8px;
    margin-top: 17px;
    margin-right: 6px;
    z-index: 1;
  }

  @media (max-width: 1023px) {
    .header-main-wrapper,
    .main,
    .main-wrapper,
    .main__content,
    body,
    html {
      padding: 0 !important;
      margin: 0 !important;
      box-shadow: none !important;
      border: none !important;
      background: #fff !important;
      background-image: none !important;
    }

    .header-main-wrapper {
      display: block !important;
      width: 100% !important;
    }

    .sidebar {
      width: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      overflow: hidden;
    }

    .main {
    }

    .container {
      width: 100% !important;
    }

    .main__content {
      min-height: auto;
    }

    .tabs {
      padding-left: 0;
    }

    .tabs li a {
      font-size: 13px;
      padding: 0 8px;
    }
  }

  .fg-menu-container,
  .fg-menu-container > .flymenu {
    box-sizing: content-box;
  }

  .fg-menu-container.fg-menu-flyout {
    overflow: visible !important;
  }

  .fg-menu-container {
    overflow-y: auto !important;
  }
}
