$orange-100: #fff3da;
$orange-200: #ffde9f;
$orange-300: #ffcb67;
$orange-400: #ffba34;
$orange-500: #ffab08;
$orange-600: #ff9c17;
$orange-700: #f28b00;
$orange-800: #db7e00;

.main {
  h2 {
    font-size: 26px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 16px;
    margin-top: 10px;
    font-family: Roboto, Arial;
    font-weight: 500;
    color: #464546;
    text-transform: none;
  }
  .grow-1 {
    flex-grow: 1;
  }

  .inline-box {
    display: inline-flex;
    flex-direction: row;
    flex-basis: auto;
    flex-wrap: wrap;
    align-items: center;
    width: auto;
  }
  .orange-vertical-borders {
    border-style: solid none solid none;
    border-width: 2px;
    border-color: $orange-700;
  }
  .min-width-med {
    min-width: 470px;
  }
  .right-border {
    border-right: 1px solid #edecec;
  }

  .triangle {
    //position: relative;
    //left: 4.83%;
    //right: 86%;
    //top: -6.12%;
    //bottom: 26.53%;
    /* Orange / Orange 700 Brand */
    width: 55px;
    height: 55px;
    background: $orange-700;
    transform: rotate(45deg);
    position: relative;
    right: 39px;
  }

  .triangle-container {
    overflow: hidden;
    width: 55px;
    height: 55px;
  }

  .triangle-container {
    height: 55px;
    width: 40px;
  }

  .body1 {
    /* Text / Body */

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    /* Gray / Gray 700 */

    color: #464546;
  }

  .body2 {
    /* Caps Header */

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */

    text-transform: uppercase;
  }

  .bold {
    font-weight: bold;
  }

  .hr {
    border: 1px solid #edecec;
  }

  .options {
    font-family: Roboto;
  }
}

.apexcharts-menu {
  min-width: 118 !important;
}
