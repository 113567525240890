.punch-list-print-view {
  max-width: 1000px;
  margin-left: 8px;
}
@media print {
  .punch-list-print-view {
    margin-left: 0;
  }
}
.punch-list-print-view .logo {
  width: 150px;
}
.punch-list-print-view .print-icon {
  width: 150px;
  margin: 8px 0;
}
.punch-list-print-view .qrcode {
  float: right;
}
.punch-list-print-view .divider {
  width: 100%;
  border-top: solid 4px #00308C;
  display: block;
}
.punch-list-print-view .divider, .punch-list-print-view .mid-section {
  margin: 12px 0 12px 0;
}
.punch-list-print-view .page-break {
  page-break-after: always;
}
.punch-list-print-view .image-section {
  page-break-inside: avoid;
}
.punch-list-print-view .row-subtitle {
  color: #5a6268;
  font-style: italic;
}
.punch-list-print-view .fixed-width-table {
  table-layout: fixed;
  vertical-align: top;
}
.punch-list-print-view .fixed-width-table tr {
  vertical-align: top;
}
.punch-list-print-view .print-footer {
  display: none;
}
.punch-list-print-view td:first-child {
  padding-left: 0;
}
.punch-list-print-view td:last-child {
  padding-right: 0;
}
.punch-list-print-view .no-padding-table td {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
.punch-list-print-view .no-padding-table td:first-child {
  width: auto;
  white-space: nowrap;
  vertical-align: top;
}
.punch-list-print-view .no-padding-table td:first-child p {
  font-weight: bold;
}
.punch-list-print-view .table-with-lines th, .punch-list-print-view .table-with-lines td {
  border: 1px solid lightgrey;
}
.punch-list-print-view .table-with-lines td {
  padding: 2px;
}
.punch-list-print-view .table-with-lines td:first-child {
  padding-left: 1px;
}
.punch-list-print-view .table-with-lines td:last-child {
  padding-right: 1px;
}
.punch-list-print-view .header-table {
  display: block;
  min-width: 600px;
}
.punch-list-print-view .header-table h2 {
  margin-bottom: 0;
}
.punch-list-print-view .image-row {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  page-break-inside: avoid;
}
.punch-list-print-view .image-with-caption {
  display: flex;
  flex-direction: column;
  width: 48.9%;
  height: 48vh;
  border: 1px solid gray;
}
.punch-list-print-view .image {
  object-position: left;
  height: 100%;
  object-fit: contain;
  border-bottom: 1px solid gray;
}
.punch-list-print-view .caption {
  margin: 2px;
}
@media print {
  .punch-list-print-view .white-background {
    background-color: #ffffff;
  }
  .punch-list-print-view .white-background tr {
    background-color: #ffffff;
  }
}

