body {
    margin: 0;
}

.bidding {
    margin: 0;
    text-transform: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bidding code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinback {
    0% {
        -webkit-transform: rotate(181deg);
        transform: rotate(181deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'Uniform Rounded Medium';
    src: local('Uniform Rounded Medium'), url("../fonts/uniformrd-md.ttf") format('opentype');
}

