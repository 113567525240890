@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.bidding,
.admin {
  }
.bidding :root,
.admin :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
.bidding *,
.bidding ::after,
.bidding ::before,
.admin *,
.admin ::after,
.admin ::before {
  box-sizing: border-box;
}
.bidding html,
.admin html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
.bidding article,
.bidding aside,
.bidding figcaption,
.bidding figure,
.bidding footer,
.bidding header,
.bidding hgroup,
.bidding main,
.bidding nav,
.bidding section,
.admin article,
.admin aside,
.admin figcaption,
.admin figure,
.admin footer,
.admin header,
.admin hgroup,
.admin main,
.admin nav,
.admin section {
  display: block;
}
.bidding body,
.admin body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #edecec;
}
.bidding [tabindex="-1"]:focus,
.admin [tabindex="-1"]:focus {
  outline: 0 !important;
}
.bidding hr,
.admin hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.bidding h1,
.bidding h2,
.bidding h3,
.bidding h4,
.bidding h5,
.bidding h6,
.admin h1,
.admin h2,
.admin h3,
.admin h4,
.admin h5,
.admin h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.bidding p,
.admin p {
  margin-top: 0;
}
.bidding abbr[data-original-title],
.bidding abbr[title],
.admin abbr[data-original-title],
.admin abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
.bidding address,
.admin address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.bidding dl,
.bidding ol,
.bidding ul,
.admin dl,
.admin ol,
.admin ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.bidding ol ol,
.bidding ol ul,
.bidding ul ol,
.bidding ul ul,
.admin ol ol,
.admin ol ul,
.admin ul ol,
.admin ul ul {
  margin-bottom: 0;
}
.bidding dt,
.admin dt {
  font-weight: 700;
}
.bidding dd,
.admin dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.bidding blockquote,
.admin blockquote {
  margin: 0 0 1rem;
}
.bidding b,
.bidding strong,
.admin b,
.admin strong {
  font-weight: bolder;
}
.bidding small,
.admin small {
  font-size: 80%;
}
.bidding sub,
.bidding sup,
.admin sub,
.admin sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
.bidding sub,
.admin sub {
  bottom: -0.25em;
}
.bidding sup,
.admin sup {
  top: -0.5em;
}
.bidding a,
.admin a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.bidding a:hover,
.admin a:hover {
  color: #0056b3;
  text-decoration: underline;
}
.bidding a:not([href]):not([tabindex]),
.admin a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
.bidding a:not([href]):not([tabindex]):focus,
.bidding a:not([href]):not([tabindex]):hover,
.admin a:not([href]):not([tabindex]):focus,
.admin a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
.bidding a:not([href]):not([tabindex]):focus,
.admin a:not([href]):not([tabindex]):focus {
  outline: 0;
}
.bidding code,
.bidding kbd,
.bidding pre,
.bidding samp,
.admin code,
.admin kbd,
.admin pre,
.admin samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
.bidding pre,
.admin pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
.bidding figure,
.admin figure {
  margin: 0 0 1rem;
}
.bidding img,
.admin img {
  vertical-align: middle;
  border-style: none;
}
.bidding svg,
.admin svg {
  overflow: hidden;
  vertical-align: middle;
}
.bidding table,
.admin table {
  border-collapse: collapse;
}
.bidding caption,
.admin caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
.bidding th,
.admin th {
  text-align: inherit;
}
.bidding label,
.admin label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.bidding select,
.admin select {
  word-wrap: normal;
}
.bidding [type=button],
.bidding [type=reset],
.bidding [type=submit],
.bidding button,
.admin [type=button],
.admin [type=reset],
.admin [type=submit],
.admin button {
  -webkit-appearance: button;
}
.bidding [type=button]:not(:disabled),
.bidding [type=reset]:not(:disabled),
.bidding [type=submit]:not(:disabled),
.bidding button:not(:disabled),
.admin [type=button]:not(:disabled),
.admin [type=reset]:not(:disabled),
.admin [type=submit]:not(:disabled),
.admin button:not(:disabled) {
  cursor: pointer;
}
.bidding [type=button]::-moz-focus-inner,
.bidding [type=reset]::-moz-focus-inner,
.bidding [type=submit]::-moz-focus-inner,
.bidding button::-moz-focus-inner,
.admin [type=button]::-moz-focus-inner,
.admin [type=reset]::-moz-focus-inner,
.admin [type=submit]::-moz-focus-inner,
.admin button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.bidding input[type=checkbox],
.bidding input[type=radio],
.admin input[type=checkbox],
.admin input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
.bidding input[type=date],
.bidding input[type=datetime-local],
.bidding input[type=month],
.bidding input[type=time],
.admin input[type=date],
.admin input[type=datetime-local],
.admin input[type=month],
.admin input[type=time] {
  -webkit-appearance: listbox;
}
.bidding textarea,
.admin textarea {
  overflow: auto;
  resize: vertical;
}
.bidding fieldset,
.admin fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.bidding legend,
.admin legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
.bidding progress,
.admin progress {
  vertical-align: baseline;
}
.bidding [type=number]::-webkit-inner-spin-button,
.bidding [type=number]::-webkit-outer-spin-button,
.admin [type=number]::-webkit-inner-spin-button,
.admin [type=number]::-webkit-outer-spin-button {
  height: auto;
}
.bidding [type=search],
.admin [type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
.bidding [type=search]::-webkit-search-decoration,
.admin [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.bidding ::-webkit-file-upload-button,
.admin ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.bidding output,
.admin output {
  display: inline-block;
}
.bidding summary,
.admin summary {
  display: list-item;
  cursor: pointer;
}
.bidding template,
.admin template {
  display: none;
}
.bidding [hidden],
.admin [hidden] {
  display: none !important;
}
.bidding .h1,
.bidding .h2,
.bidding .h3,
.bidding .h4,
.bidding .h5,
.bidding .h6,
.bidding h1,
.bidding h2,
.bidding h3,
.bidding h4,
.bidding h5,
.bidding h6,
.admin .h1,
.admin .h2,
.admin .h3,
.admin .h4,
.admin .h5,
.admin .h6,
.admin h1,
.admin h2,
.admin h3,
.admin h4,
.admin h5,
.admin h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.bidding .h1,
.bidding h1,
.admin .h1,
.admin h1 {
  font-size: 2.5rem;
}
.bidding .h2,
.bidding h2,
.admin .h2,
.admin h2 {
  font-size: 2rem;
}
.bidding .h3,
.bidding h3,
.admin .h3,
.admin h3 {
  font-size: 1.75rem;
}
.bidding .h4,
.bidding h4,
.admin .h4,
.admin h4 {
  font-size: 1.5rem;
}
.bidding .h5,
.bidding h5,
.admin .h5,
.admin h5 {
  font-size: 1.25rem;
}
.bidding .h6,
.bidding h6,
.admin .h6,
.admin h6 {
  font-size: 1rem;
}
.bidding .lead,
.admin .lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.bidding .display-1,
.admin .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.bidding .display-2,
.admin .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.bidding .display-3,
.admin .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.bidding .display-4,
.admin .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.bidding hr,
.admin hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.bidding .small,
.bidding small,
.admin .small,
.admin small {
  font-size: 80%;
  font-weight: 400;
}
.bidding .mark,
.bidding mark,
.admin .mark,
.admin mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.bidding .list-unstyled,
.admin .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.bidding .list-inline,
.admin .list-inline {
  padding-left: 0;
  list-style: none;
}
.bidding .list-inline-item,
.admin .list-inline-item {
  display: inline-block;
}
.bidding .list-inline-item:not(:last-child),
.admin .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.bidding .blockquote,
.admin .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.bidding .blockquote-footer,
.admin .blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.bidding .blockquote-footer::before,
.admin .blockquote-footer::before {
  content: "— ";
}
.bidding .img-fluid,
.admin .img-fluid {
  max-width: 100%;
  height: auto;
}
.bidding .img-thumbnail,
.admin .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.bidding .figure,
.admin .figure {
  display: inline-block;
}
.bidding .figure-img,
.admin .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.bidding .figure-caption,
.admin .figure-caption {
  font-size: 90%;
  color: #6c757d;
}
.bidding code,
.admin code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
.bidding a > code,
.admin a > code {
  color: inherit;
}
.bidding kbd,
.admin kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
.bidding kbd kbd,
.admin kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
.bidding pre,
.admin pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
.bidding pre code,
.admin pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.bidding .pre-scrollable,
.admin .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.bidding .container,
.admin .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .bidding .container,
.admin .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .bidding .container,
.admin .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .bidding .container,
.admin .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .bidding .container,
.admin .container {
    max-width: 1140px;
  }
}
.bidding .container-fluid,
.admin .container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.bidding .row,
.admin .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.bidding .no-gutters,
.admin .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.bidding .no-gutters > .col,
.bidding .no-gutters > [class*=col-],
.admin .no-gutters > .col,
.admin .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.bidding .col,
.bidding .col-1,
.bidding .col-10,
.bidding .col-11,
.bidding .col-12,
.bidding .col-2,
.bidding .col-3,
.bidding .col-4,
.bidding .col-5,
.bidding .col-6,
.bidding .col-7,
.bidding .col-8,
.bidding .col-9,
.bidding .col-auto,
.bidding .col-lg,
.bidding .col-lg-1,
.bidding .col-lg-10,
.bidding .col-lg-11,
.bidding .col-lg-12,
.bidding .col-lg-2,
.bidding .col-lg-3,
.bidding .col-lg-4,
.bidding .col-lg-5,
.bidding .col-lg-6,
.bidding .col-lg-7,
.bidding .col-lg-8,
.bidding .col-lg-9,
.bidding .col-lg-auto,
.bidding .col-md,
.bidding .col-md-1,
.bidding .col-md-10,
.bidding .col-md-11,
.bidding .col-md-12,
.bidding .col-md-2,
.bidding .col-md-3,
.bidding .col-md-4,
.bidding .col-md-5,
.bidding .col-md-6,
.bidding .col-md-7,
.bidding .col-md-8,
.bidding .col-md-9,
.bidding .col-md-auto,
.bidding .col-sm,
.bidding .col-sm-1,
.bidding .col-sm-10,
.bidding .col-sm-11,
.bidding .col-sm-12,
.bidding .col-sm-2,
.bidding .col-sm-3,
.bidding .col-sm-4,
.bidding .col-sm-5,
.bidding .col-sm-6,
.bidding .col-sm-7,
.bidding .col-sm-8,
.bidding .col-sm-9,
.bidding .col-sm-auto,
.bidding .col-xl,
.bidding .col-xl-1,
.bidding .col-xl-10,
.bidding .col-xl-11,
.bidding .col-xl-12,
.bidding .col-xl-2,
.bidding .col-xl-3,
.bidding .col-xl-4,
.bidding .col-xl-5,
.bidding .col-xl-6,
.bidding .col-xl-7,
.bidding .col-xl-8,
.bidding .col-xl-9,
.bidding .col-xl-auto,
.admin .col,
.admin .col-1,
.admin .col-10,
.admin .col-11,
.admin .col-12,
.admin .col-2,
.admin .col-3,
.admin .col-4,
.admin .col-5,
.admin .col-6,
.admin .col-7,
.admin .col-8,
.admin .col-9,
.admin .col-auto,
.admin .col-lg,
.admin .col-lg-1,
.admin .col-lg-10,
.admin .col-lg-11,
.admin .col-lg-12,
.admin .col-lg-2,
.admin .col-lg-3,
.admin .col-lg-4,
.admin .col-lg-5,
.admin .col-lg-6,
.admin .col-lg-7,
.admin .col-lg-8,
.admin .col-lg-9,
.admin .col-lg-auto,
.admin .col-md,
.admin .col-md-1,
.admin .col-md-10,
.admin .col-md-11,
.admin .col-md-12,
.admin .col-md-2,
.admin .col-md-3,
.admin .col-md-4,
.admin .col-md-5,
.admin .col-md-6,
.admin .col-md-7,
.admin .col-md-8,
.admin .col-md-9,
.admin .col-md-auto,
.admin .col-sm,
.admin .col-sm-1,
.admin .col-sm-10,
.admin .col-sm-11,
.admin .col-sm-12,
.admin .col-sm-2,
.admin .col-sm-3,
.admin .col-sm-4,
.admin .col-sm-5,
.admin .col-sm-6,
.admin .col-sm-7,
.admin .col-sm-8,
.admin .col-sm-9,
.admin .col-sm-auto,
.admin .col-xl,
.admin .col-xl-1,
.admin .col-xl-10,
.admin .col-xl-11,
.admin .col-xl-12,
.admin .col-xl-2,
.admin .col-xl-3,
.admin .col-xl-4,
.admin .col-xl-5,
.admin .col-xl-6,
.admin .col-xl-7,
.admin .col-xl-8,
.admin .col-xl-9,
.admin .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.bidding .col,
.admin .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.bidding .col-auto,
.admin .col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.bidding .col-1,
.admin .col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.bidding .col-2,
.admin .col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.bidding .col-3,
.admin .col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.bidding .col-4,
.admin .col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.bidding .col-5,
.admin .col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.bidding .col-6,
.admin .col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.bidding .col-7,
.admin .col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.bidding .col-8,
.admin .col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.bidding .col-9,
.admin .col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.bidding .col-10,
.admin .col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.bidding .col-11,
.admin .col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.bidding .col-12,
.admin .col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.bidding .order-first,
.admin .order-first {
  -ms-flex-order: -1;
  order: -1;
}
.bidding .order-last,
.admin .order-last {
  -ms-flex-order: 13;
  order: 13;
}
.bidding .order-0,
.admin .order-0 {
  -ms-flex-order: 0;
  order: 0;
}
.bidding .order-1,
.admin .order-1 {
  -ms-flex-order: 1;
  order: 1;
}
.bidding .order-2,
.admin .order-2 {
  -ms-flex-order: 2;
  order: 2;
}
.bidding .order-3,
.admin .order-3 {
  -ms-flex-order: 3;
  order: 3;
}
.bidding .order-4,
.admin .order-4 {
  -ms-flex-order: 4;
  order: 4;
}
.bidding .order-5,
.admin .order-5 {
  -ms-flex-order: 5;
  order: 5;
}
.bidding .order-6,
.admin .order-6 {
  -ms-flex-order: 6;
  order: 6;
}
.bidding .order-7,
.admin .order-7 {
  -ms-flex-order: 7;
  order: 7;
}
.bidding .order-8,
.admin .order-8 {
  -ms-flex-order: 8;
  order: 8;
}
.bidding .order-9,
.admin .order-9 {
  -ms-flex-order: 9;
  order: 9;
}
.bidding .order-10,
.admin .order-10 {
  -ms-flex-order: 10;
  order: 10;
}
.bidding .order-11,
.admin .order-11 {
  -ms-flex-order: 11;
  order: 11;
}
.bidding .order-12,
.admin .order-12 {
  -ms-flex-order: 12;
  order: 12;
}
.bidding .offset-1,
.admin .offset-1 {
  margin-left: 8.333333%;
}
.bidding .offset-2,
.admin .offset-2 {
  margin-left: 16.666667%;
}
.bidding .offset-3,
.admin .offset-3 {
  margin-left: 25%;
}
.bidding .offset-4,
.admin .offset-4 {
  margin-left: 33.333333%;
}
.bidding .offset-5,
.admin .offset-5 {
  margin-left: 41.666667%;
}
.bidding .offset-6,
.admin .offset-6 {
  margin-left: 50%;
}
.bidding .offset-7,
.admin .offset-7 {
  margin-left: 58.333333%;
}
.bidding .offset-8,
.admin .offset-8 {
  margin-left: 66.666667%;
}
.bidding .offset-9,
.admin .offset-9 {
  margin-left: 75%;
}
.bidding .offset-10,
.admin .offset-10 {
  margin-left: 83.333333%;
}
.bidding .offset-11,
.admin .offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .bidding .col-sm,
.admin .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .bidding .col-sm-auto,
.admin .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bidding .col-sm-1,
.admin .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .bidding .col-sm-2,
.admin .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bidding .col-sm-3,
.admin .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bidding .col-sm-4,
.admin .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bidding .col-sm-5,
.admin .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .bidding .col-sm-6,
.admin .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bidding .col-sm-7,
.admin .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .bidding .col-sm-8,
.admin .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .bidding .col-sm-9,
.admin .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bidding .col-sm-10,
.admin .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .bidding .col-sm-11,
.admin .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .bidding .col-sm-12,
.admin .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bidding .order-sm-first,
.admin .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .bidding .order-sm-last,
.admin .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .bidding .order-sm-0,
.admin .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .bidding .order-sm-1,
.admin .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .bidding .order-sm-2,
.admin .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .bidding .order-sm-3,
.admin .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .bidding .order-sm-4,
.admin .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .bidding .order-sm-5,
.admin .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .bidding .order-sm-6,
.admin .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .bidding .order-sm-7,
.admin .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .bidding .order-sm-8,
.admin .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .bidding .order-sm-9,
.admin .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .bidding .order-sm-10,
.admin .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .bidding .order-sm-11,
.admin .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .bidding .order-sm-12,
.admin .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .bidding .offset-sm-0,
.admin .offset-sm-0 {
    margin-left: 0;
  }
  .bidding .offset-sm-1,
.admin .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .bidding .offset-sm-2,
.admin .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .bidding .offset-sm-3,
.admin .offset-sm-3 {
    margin-left: 25%;
  }
  .bidding .offset-sm-4,
.admin .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .bidding .offset-sm-5,
.admin .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .bidding .offset-sm-6,
.admin .offset-sm-6 {
    margin-left: 50%;
  }
  .bidding .offset-sm-7,
.admin .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .bidding .offset-sm-8,
.admin .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .bidding .offset-sm-9,
.admin .offset-sm-9 {
    margin-left: 75%;
  }
  .bidding .offset-sm-10,
.admin .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .bidding .offset-sm-11,
.admin .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .bidding .col-md,
.admin .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .bidding .col-md-auto,
.admin .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bidding .col-md-1,
.admin .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .bidding .col-md-2,
.admin .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bidding .col-md-3,
.admin .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bidding .col-md-4,
.admin .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bidding .col-md-5,
.admin .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .bidding .col-md-6,
.admin .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bidding .col-md-7,
.admin .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .bidding .col-md-8,
.admin .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .bidding .col-md-9,
.admin .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bidding .col-md-10,
.admin .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .bidding .col-md-11,
.admin .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .bidding .col-md-12,
.admin .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bidding .order-md-first,
.admin .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .bidding .order-md-last,
.admin .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .bidding .order-md-0,
.admin .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .bidding .order-md-1,
.admin .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .bidding .order-md-2,
.admin .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .bidding .order-md-3,
.admin .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .bidding .order-md-4,
.admin .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .bidding .order-md-5,
.admin .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .bidding .order-md-6,
.admin .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .bidding .order-md-7,
.admin .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .bidding .order-md-8,
.admin .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .bidding .order-md-9,
.admin .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .bidding .order-md-10,
.admin .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .bidding .order-md-11,
.admin .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .bidding .order-md-12,
.admin .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .bidding .offset-md-0,
.admin .offset-md-0 {
    margin-left: 0;
  }
  .bidding .offset-md-1,
.admin .offset-md-1 {
    margin-left: 8.333333%;
  }
  .bidding .offset-md-2,
.admin .offset-md-2 {
    margin-left: 16.666667%;
  }
  .bidding .offset-md-3,
.admin .offset-md-3 {
    margin-left: 25%;
  }
  .bidding .offset-md-4,
.admin .offset-md-4 {
    margin-left: 33.333333%;
  }
  .bidding .offset-md-5,
.admin .offset-md-5 {
    margin-left: 41.666667%;
  }
  .bidding .offset-md-6,
.admin .offset-md-6 {
    margin-left: 50%;
  }
  .bidding .offset-md-7,
.admin .offset-md-7 {
    margin-left: 58.333333%;
  }
  .bidding .offset-md-8,
.admin .offset-md-8 {
    margin-left: 66.666667%;
  }
  .bidding .offset-md-9,
.admin .offset-md-9 {
    margin-left: 75%;
  }
  .bidding .offset-md-10,
.admin .offset-md-10 {
    margin-left: 83.333333%;
  }
  .bidding .offset-md-11,
.admin .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .bidding .col-lg,
.admin .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .bidding .col-lg-auto,
.admin .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bidding .col-lg-1,
.admin .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .bidding .col-lg-2,
.admin .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bidding .col-lg-3,
.admin .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bidding .col-lg-4,
.admin .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bidding .col-lg-5,
.admin .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .bidding .col-lg-6,
.admin .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bidding .col-lg-7,
.admin .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .bidding .col-lg-8,
.admin .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .bidding .col-lg-9,
.admin .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bidding .col-lg-10,
.admin .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .bidding .col-lg-11,
.admin .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .bidding .col-lg-12,
.admin .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bidding .order-lg-first,
.admin .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .bidding .order-lg-last,
.admin .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .bidding .order-lg-0,
.admin .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .bidding .order-lg-1,
.admin .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .bidding .order-lg-2,
.admin .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .bidding .order-lg-3,
.admin .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .bidding .order-lg-4,
.admin .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .bidding .order-lg-5,
.admin .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .bidding .order-lg-6,
.admin .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .bidding .order-lg-7,
.admin .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .bidding .order-lg-8,
.admin .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .bidding .order-lg-9,
.admin .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .bidding .order-lg-10,
.admin .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .bidding .order-lg-11,
.admin .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .bidding .order-lg-12,
.admin .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .bidding .offset-lg-0,
.admin .offset-lg-0 {
    margin-left: 0;
  }
  .bidding .offset-lg-1,
.admin .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .bidding .offset-lg-2,
.admin .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .bidding .offset-lg-3,
.admin .offset-lg-3 {
    margin-left: 25%;
  }
  .bidding .offset-lg-4,
.admin .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .bidding .offset-lg-5,
.admin .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .bidding .offset-lg-6,
.admin .offset-lg-6 {
    margin-left: 50%;
  }
  .bidding .offset-lg-7,
.admin .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .bidding .offset-lg-8,
.admin .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .bidding .offset-lg-9,
.admin .offset-lg-9 {
    margin-left: 75%;
  }
  .bidding .offset-lg-10,
.admin .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .bidding .offset-lg-11,
.admin .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .bidding .col-xl,
.admin .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .bidding .col-xl-auto,
.admin .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bidding .col-xl-1,
.admin .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .bidding .col-xl-2,
.admin .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bidding .col-xl-3,
.admin .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bidding .col-xl-4,
.admin .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bidding .col-xl-5,
.admin .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .bidding .col-xl-6,
.admin .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bidding .col-xl-7,
.admin .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .bidding .col-xl-8,
.admin .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .bidding .col-xl-9,
.admin .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bidding .col-xl-10,
.admin .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .bidding .col-xl-11,
.admin .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .bidding .col-xl-12,
.admin .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bidding .order-xl-first,
.admin .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .bidding .order-xl-last,
.admin .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .bidding .order-xl-0,
.admin .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .bidding .order-xl-1,
.admin .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .bidding .order-xl-2,
.admin .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .bidding .order-xl-3,
.admin .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .bidding .order-xl-4,
.admin .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .bidding .order-xl-5,
.admin .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .bidding .order-xl-6,
.admin .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .bidding .order-xl-7,
.admin .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .bidding .order-xl-8,
.admin .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .bidding .order-xl-9,
.admin .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .bidding .order-xl-10,
.admin .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .bidding .order-xl-11,
.admin .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .bidding .order-xl-12,
.admin .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .bidding .offset-xl-0,
.admin .offset-xl-0 {
    margin-left: 0;
  }
  .bidding .offset-xl-1,
.admin .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .bidding .offset-xl-2,
.admin .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .bidding .offset-xl-3,
.admin .offset-xl-3 {
    margin-left: 25%;
  }
  .bidding .offset-xl-4,
.admin .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .bidding .offset-xl-5,
.admin .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .bidding .offset-xl-6,
.admin .offset-xl-6 {
    margin-left: 50%;
  }
  .bidding .offset-xl-7,
.admin .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .bidding .offset-xl-8,
.admin .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .bidding .offset-xl-9,
.admin .offset-xl-9 {
    margin-left: 75%;
  }
  .bidding .offset-xl-10,
.admin .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .bidding .offset-xl-11,
.admin .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.bidding .table,
.admin .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.bidding .table td,
.bidding .table th,
.admin .table td,
.admin .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.bidding .table thead th,
.admin .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.bidding .table tbody + tbody,
.admin .table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.bidding .table-sm td,
.bidding .table-sm th,
.admin .table-sm td,
.admin .table-sm th {
  padding: 0.3rem;
}
.bidding .table-bordered,
.admin .table-bordered {
  border: 1px solid #dee2e6;
}
.bidding .table-bordered td,
.bidding .table-bordered th,
.admin .table-bordered td,
.admin .table-bordered th {
  border: 1px solid #dee2e6;
}
.bidding .table-bordered thead td,
.bidding .table-bordered thead th,
.admin .table-bordered thead td,
.admin .table-bordered thead th {
  border-bottom-width: 2px;
}
.bidding .table-borderless tbody + tbody,
.bidding .table-borderless td,
.bidding .table-borderless th,
.bidding .table-borderless thead th,
.admin .table-borderless tbody + tbody,
.admin .table-borderless td,
.admin .table-borderless th,
.admin .table-borderless thead th {
  border: 0;
}
.bidding .table-striped tbody tr:nth-of-type(odd),
.admin .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.bidding .table-hover tbody tr:hover,
.admin .table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.bidding .table-primary,
.bidding .table-primary > td,
.bidding .table-primary > th,
.admin .table-primary,
.admin .table-primary > td,
.admin .table-primary > th {
  background-color: #b8daff;
}
.bidding .table-primary tbody + tbody,
.bidding .table-primary td,
.bidding .table-primary th,
.bidding .table-primary thead th,
.admin .table-primary tbody + tbody,
.admin .table-primary td,
.admin .table-primary th,
.admin .table-primary thead th {
  border-color: #7abaff;
}
.bidding .table-hover .table-primary:hover,
.admin .table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.bidding .table-hover .table-primary:hover > td,
.bidding .table-hover .table-primary:hover > th,
.admin .table-hover .table-primary:hover > td,
.admin .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.bidding .table-secondary,
.bidding .table-secondary > td,
.bidding .table-secondary > th,
.admin .table-secondary,
.admin .table-secondary > td,
.admin .table-secondary > th {
  background-color: #d6d8db;
}
.bidding .table-secondary tbody + tbody,
.bidding .table-secondary td,
.bidding .table-secondary th,
.bidding .table-secondary thead th,
.admin .table-secondary tbody + tbody,
.admin .table-secondary td,
.admin .table-secondary th,
.admin .table-secondary thead th {
  border-color: #b3b7bb;
}
.bidding .table-hover .table-secondary:hover,
.admin .table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.bidding .table-hover .table-secondary:hover > td,
.bidding .table-hover .table-secondary:hover > th,
.admin .table-hover .table-secondary:hover > td,
.admin .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.bidding .table-success,
.bidding .table-success > td,
.bidding .table-success > th,
.admin .table-success,
.admin .table-success > td,
.admin .table-success > th {
  background-color: #c3e6cb;
}
.bidding .table-success tbody + tbody,
.bidding .table-success td,
.bidding .table-success th,
.bidding .table-success thead th,
.admin .table-success tbody + tbody,
.admin .table-success td,
.admin .table-success th,
.admin .table-success thead th {
  border-color: #8fd19e;
}
.bidding .table-hover .table-success:hover,
.admin .table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.bidding .table-hover .table-success:hover > td,
.bidding .table-hover .table-success:hover > th,
.admin .table-hover .table-success:hover > td,
.admin .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.bidding .table-info,
.bidding .table-info > td,
.bidding .table-info > th,
.admin .table-info,
.admin .table-info > td,
.admin .table-info > th {
  background-color: #bee5eb;
}
.bidding .table-info tbody + tbody,
.bidding .table-info td,
.bidding .table-info th,
.bidding .table-info thead th,
.admin .table-info tbody + tbody,
.admin .table-info td,
.admin .table-info th,
.admin .table-info thead th {
  border-color: #86cfda;
}
.bidding .table-hover .table-info:hover,
.admin .table-hover .table-info:hover {
  background-color: #abdde5;
}
.bidding .table-hover .table-info:hover > td,
.bidding .table-hover .table-info:hover > th,
.admin .table-hover .table-info:hover > td,
.admin .table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.bidding .table-warning,
.bidding .table-warning > td,
.bidding .table-warning > th,
.admin .table-warning,
.admin .table-warning > td,
.admin .table-warning > th {
  background-color: #ffeeba;
}
.bidding .table-warning tbody + tbody,
.bidding .table-warning td,
.bidding .table-warning th,
.bidding .table-warning thead th,
.admin .table-warning tbody + tbody,
.admin .table-warning td,
.admin .table-warning th,
.admin .table-warning thead th {
  border-color: #ffdf7e;
}
.bidding .table-hover .table-warning:hover,
.admin .table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.bidding .table-hover .table-warning:hover > td,
.bidding .table-hover .table-warning:hover > th,
.admin .table-hover .table-warning:hover > td,
.admin .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.bidding .table-danger,
.bidding .table-danger > td,
.bidding .table-danger > th,
.admin .table-danger,
.admin .table-danger > td,
.admin .table-danger > th {
  background-color: #f5c6cb;
}
.bidding .table-danger tbody + tbody,
.bidding .table-danger td,
.bidding .table-danger th,
.bidding .table-danger thead th,
.admin .table-danger tbody + tbody,
.admin .table-danger td,
.admin .table-danger th,
.admin .table-danger thead th {
  border-color: #ed969e;
}
.bidding .table-hover .table-danger:hover,
.admin .table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.bidding .table-hover .table-danger:hover > td,
.bidding .table-hover .table-danger:hover > th,
.admin .table-hover .table-danger:hover > td,
.admin .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.bidding .table-light,
.bidding .table-light > td,
.bidding .table-light > th,
.admin .table-light,
.admin .table-light > td,
.admin .table-light > th {
  background-color: #fdfdfe;
}
.bidding .table-light tbody + tbody,
.bidding .table-light td,
.bidding .table-light th,
.bidding .table-light thead th,
.admin .table-light tbody + tbody,
.admin .table-light td,
.admin .table-light th,
.admin .table-light thead th {
  border-color: #fbfcfc;
}
.bidding .table-hover .table-light:hover,
.admin .table-hover .table-light:hover {
  background-color: #ececf6;
}
.bidding .table-hover .table-light:hover > td,
.bidding .table-hover .table-light:hover > th,
.admin .table-hover .table-light:hover > td,
.admin .table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.bidding .table-dark,
.bidding .table-dark > td,
.bidding .table-dark > th,
.admin .table-dark,
.admin .table-dark > td,
.admin .table-dark > th {
  background-color: #c6c8ca;
}
.bidding .table-dark tbody + tbody,
.bidding .table-dark td,
.bidding .table-dark th,
.bidding .table-dark thead th,
.admin .table-dark tbody + tbody,
.admin .table-dark td,
.admin .table-dark th,
.admin .table-dark thead th {
  border-color: #95999c;
}
.bidding .table-hover .table-dark:hover,
.admin .table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.bidding .table-hover .table-dark:hover > td,
.bidding .table-hover .table-dark:hover > th,
.admin .table-hover .table-dark:hover > td,
.admin .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.bidding .table-active,
.bidding .table-active > td,
.bidding .table-active > th,
.admin .table-active,
.admin .table-active > td,
.admin .table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.bidding .table-hover .table-active:hover,
.admin .table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.bidding .table-hover .table-active:hover > td,
.bidding .table-hover .table-active:hover > th,
.admin .table-hover .table-active:hover > td,
.admin .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.bidding .table .thead-dark th,
.admin .table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.bidding .table .thead-light th,
.admin .table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.bidding .table-dark,
.admin .table-dark {
  color: #fff;
  background-color: #343a40;
}
.bidding .table-dark td,
.bidding .table-dark th,
.bidding .table-dark thead th,
.admin .table-dark td,
.admin .table-dark th,
.admin .table-dark thead th {
  border-color: #454d55;
}
.bidding .table-dark.table-bordered,
.admin .table-dark.table-bordered {
  border: 0;
}
.bidding .table-dark.table-striped tbody tr:nth-of-type(odd),
.admin .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.bidding .table-dark.table-hover tbody tr:hover,
.admin .table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .bidding .table-responsive-sm,
.admin .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bidding .table-responsive-sm > .table-bordered,
.admin .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .bidding .table-responsive-md,
.admin .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bidding .table-responsive-md > .table-bordered,
.admin .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .bidding .table-responsive-lg,
.admin .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bidding .table-responsive-lg > .table-bordered,
.admin .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .bidding .table-responsive-xl,
.admin .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bidding .table-responsive-xl > .table-bordered,
.admin .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.bidding .table-responsive,
.admin .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.bidding .table-responsive > .table-bordered,
.admin .table-responsive > .table-bordered {
  border: 0;
}
.bidding .form-control,
.admin .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .form-control,
.admin .form-control {
    transition: none;
  }
}
.bidding .form-control::-ms-expand,
.admin .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.bidding .form-control:focus,
.admin .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bidding .form-control::-webkit-input-placeholder,
.admin .form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.bidding .form-control::-moz-placeholder,
.admin .form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.bidding .form-control:-ms-input-placeholder,
.admin .form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.bidding .form-control::-ms-input-placeholder,
.admin .form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.bidding .form-control::placeholder,
.admin .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.bidding .form-control:disabled,
.bidding .form-control[readonly],
.admin .form-control:disabled,
.admin .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.bidding select.form-control:focus::-ms-value,
.admin select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.bidding .form-control-file,
.bidding .form-control-range,
.admin .form-control-file,
.admin .form-control-range {
  display: block;
  width: 100%;
}
.bidding .col-form-label,
.admin .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.bidding .col-form-label-lg,
.admin .col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.bidding .col-form-label-sm,
.admin .col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.bidding .form-control-plaintext,
.admin .form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.bidding .form-control-plaintext.form-control-lg,
.bidding .form-control-plaintext.form-control-sm,
.admin .form-control-plaintext.form-control-lg,
.admin .form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.bidding .form-control-sm,
.admin .form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.bidding .form-control-lg,
.admin .form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.bidding select.form-control[multiple],
.bidding select.form-control[size],
.admin select.form-control[multiple],
.admin select.form-control[size] {
  height: auto;
}
.bidding textarea.form-control,
.admin textarea.form-control {
  height: auto;
}
.bidding .form-group,
.admin .form-group {
  margin-bottom: 1rem;
}
.bidding .form-text,
.admin .form-text {
  display: block;
  margin-top: 0.25rem;
}
.bidding .form-row,
.admin .form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.bidding .form-row > .col,
.bidding .form-row > [class*=col-],
.admin .form-row > .col,
.admin .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}
.bidding .form-check,
.admin .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.bidding .form-check-input,
.admin .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.bidding .form-check-input:disabled ~ .form-check-label,
.admin .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.bidding .form-check-label,
.admin .form-check-label {
  margin-bottom: 0;
}
.bidding .form-check-inline,
.admin .form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.bidding .form-check-inline .form-check-input,
.admin .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.bidding .valid-feedback,
.admin .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.bidding .valid-tooltip,
.admin .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.bidding .form-control.is-valid,
.bidding .was-validated .form-control:valid,
.admin .form-control.is-valid,
.admin .was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.bidding .form-control.is-valid:focus,
.bidding .was-validated .form-control:valid:focus,
.admin .form-control.is-valid:focus,
.admin .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.bidding .form-control.is-valid ~ .valid-feedback,
.bidding .form-control.is-valid ~ .valid-tooltip,
.bidding .was-validated .form-control:valid ~ .valid-feedback,
.bidding .was-validated .form-control:valid ~ .valid-tooltip,
.admin .form-control.is-valid ~ .valid-feedback,
.admin .form-control.is-valid ~ .valid-tooltip,
.admin .was-validated .form-control:valid ~ .valid-feedback,
.admin .was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}
.bidding .was-validated textarea.form-control:valid,
.bidding textarea.form-control.is-valid,
.admin .was-validated textarea.form-control:valid,
.admin textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.bidding .custom-select.is-valid,
.bidding .was-validated .custom-select:valid,
.admin .custom-select.is-valid,
.admin .was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.bidding .custom-select.is-valid:focus,
.bidding .was-validated .custom-select:valid:focus,
.admin .custom-select.is-valid:focus,
.admin .was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.bidding .custom-select.is-valid ~ .valid-feedback,
.bidding .custom-select.is-valid ~ .valid-tooltip,
.bidding .was-validated .custom-select:valid ~ .valid-feedback,
.bidding .was-validated .custom-select:valid ~ .valid-tooltip,
.admin .custom-select.is-valid ~ .valid-feedback,
.admin .custom-select.is-valid ~ .valid-tooltip,
.admin .was-validated .custom-select:valid ~ .valid-feedback,
.admin .was-validated .custom-select:valid ~ .valid-tooltip {
  display: block;
}
.bidding .form-control-file.is-valid ~ .valid-feedback,
.bidding .form-control-file.is-valid ~ .valid-tooltip,
.bidding .was-validated .form-control-file:valid ~ .valid-feedback,
.bidding .was-validated .form-control-file:valid ~ .valid-tooltip,
.admin .form-control-file.is-valid ~ .valid-feedback,
.admin .form-control-file.is-valid ~ .valid-tooltip,
.admin .was-validated .form-control-file:valid ~ .valid-feedback,
.admin .was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}
.bidding .form-check-input.is-valid ~ .form-check-label,
.bidding .was-validated .form-check-input:valid ~ .form-check-label,
.admin .form-check-input.is-valid ~ .form-check-label,
.admin .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}
.bidding .form-check-input.is-valid ~ .valid-feedback,
.bidding .form-check-input.is-valid ~ .valid-tooltip,
.bidding .was-validated .form-check-input:valid ~ .valid-feedback,
.bidding .was-validated .form-check-input:valid ~ .valid-tooltip,
.admin .form-check-input.is-valid ~ .valid-feedback,
.admin .form-check-input.is-valid ~ .valid-tooltip,
.admin .was-validated .form-check-input:valid ~ .valid-feedback,
.admin .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.bidding .custom-control-input.is-valid ~ .custom-control-label,
.bidding .was-validated .custom-control-input:valid ~ .custom-control-label,
.admin .custom-control-input.is-valid ~ .custom-control-label,
.admin .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}
.bidding .custom-control-input.is-valid ~ .custom-control-label::before,
.bidding .was-validated .custom-control-input:valid ~ .custom-control-label::before,
.admin .custom-control-input.is-valid ~ .custom-control-label::before,
.admin .was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.bidding .custom-control-input.is-valid ~ .valid-feedback,
.bidding .custom-control-input.is-valid ~ .valid-tooltip,
.bidding .was-validated .custom-control-input:valid ~ .valid-feedback,
.bidding .was-validated .custom-control-input:valid ~ .valid-tooltip,
.admin .custom-control-input.is-valid ~ .valid-feedback,
.admin .custom-control-input.is-valid ~ .valid-tooltip,
.admin .was-validated .custom-control-input:valid ~ .valid-feedback,
.admin .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}
.bidding .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.bidding .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.admin .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.admin .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.bidding .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.bidding .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.admin .custom-control-input.is-valid:focus ~ .custom-control-label::before,
.admin .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.bidding .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before,
.bidding .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.admin .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before,
.admin .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}
.bidding .custom-file-input.is-valid ~ .custom-file-label,
.bidding .was-validated .custom-file-input:valid ~ .custom-file-label,
.admin .custom-file-input.is-valid ~ .custom-file-label,
.admin .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}
.bidding .custom-file-input.is-valid ~ .valid-feedback,
.bidding .custom-file-input.is-valid ~ .valid-tooltip,
.bidding .was-validated .custom-file-input:valid ~ .valid-feedback,
.bidding .was-validated .custom-file-input:valid ~ .valid-tooltip,
.admin .custom-file-input.is-valid ~ .valid-feedback,
.admin .custom-file-input.is-valid ~ .valid-tooltip,
.admin .was-validated .custom-file-input:valid ~ .valid-feedback,
.admin .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}
.bidding .custom-file-input.is-valid:focus ~ .custom-file-label,
.bidding .was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.admin .custom-file-input.is-valid:focus ~ .custom-file-label,
.admin .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.bidding .invalid-feedback,
.admin .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.bidding .invalid-tooltip,
.admin .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.bidding .form-control.is-invalid,
.bidding .was-validated .form-control:invalid,
.admin .form-control.is-invalid,
.admin .was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.bidding .form-control.is-invalid:focus,
.bidding .was-validated .form-control:invalid:focus,
.admin .form-control.is-invalid:focus,
.admin .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.bidding .form-control.is-invalid ~ .invalid-feedback,
.bidding .form-control.is-invalid ~ .invalid-tooltip,
.bidding .was-validated .form-control:invalid ~ .invalid-feedback,
.bidding .was-validated .form-control:invalid ~ .invalid-tooltip,
.admin .form-control.is-invalid ~ .invalid-feedback,
.admin .form-control.is-invalid ~ .invalid-tooltip,
.admin .was-validated .form-control:invalid ~ .invalid-feedback,
.admin .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}
.bidding .was-validated textarea.form-control:invalid,
.bidding textarea.form-control.is-invalid,
.admin .was-validated textarea.form-control:invalid,
.admin textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.bidding .custom-select.is-invalid,
.bidding .was-validated .custom-select:invalid,
.admin .custom-select.is-invalid,
.admin .was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.bidding .custom-select.is-invalid:focus,
.bidding .was-validated .custom-select:invalid:focus,
.admin .custom-select.is-invalid:focus,
.admin .was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.bidding .custom-select.is-invalid ~ .invalid-feedback,
.bidding .custom-select.is-invalid ~ .invalid-tooltip,
.bidding .was-validated .custom-select:invalid ~ .invalid-feedback,
.bidding .was-validated .custom-select:invalid ~ .invalid-tooltip,
.admin .custom-select.is-invalid ~ .invalid-feedback,
.admin .custom-select.is-invalid ~ .invalid-tooltip,
.admin .was-validated .custom-select:invalid ~ .invalid-feedback,
.admin .was-validated .custom-select:invalid ~ .invalid-tooltip {
  display: block;
}
.bidding .form-control-file.is-invalid ~ .invalid-feedback,
.bidding .form-control-file.is-invalid ~ .invalid-tooltip,
.bidding .was-validated .form-control-file:invalid ~ .invalid-feedback,
.bidding .was-validated .form-control-file:invalid ~ .invalid-tooltip,
.admin .form-control-file.is-invalid ~ .invalid-feedback,
.admin .form-control-file.is-invalid ~ .invalid-tooltip,
.admin .was-validated .form-control-file:invalid ~ .invalid-feedback,
.admin .was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}
.bidding .form-check-input.is-invalid ~ .form-check-label,
.bidding .was-validated .form-check-input:invalid ~ .form-check-label,
.admin .form-check-input.is-invalid ~ .form-check-label,
.admin .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.bidding .form-check-input.is-invalid ~ .invalid-feedback,
.bidding .form-check-input.is-invalid ~ .invalid-tooltip,
.bidding .was-validated .form-check-input:invalid ~ .invalid-feedback,
.bidding .was-validated .form-check-input:invalid ~ .invalid-tooltip,
.admin .form-check-input.is-invalid ~ .invalid-feedback,
.admin .form-check-input.is-invalid ~ .invalid-tooltip,
.admin .was-validated .form-check-input:invalid ~ .invalid-feedback,
.admin .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.bidding .custom-control-input.is-invalid ~ .custom-control-label,
.bidding .was-validated .custom-control-input:invalid ~ .custom-control-label,
.admin .custom-control-input.is-invalid ~ .custom-control-label,
.admin .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}
.bidding .custom-control-input.is-invalid ~ .custom-control-label::before,
.bidding .was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.admin .custom-control-input.is-invalid ~ .custom-control-label::before,
.admin .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.bidding .custom-control-input.is-invalid ~ .invalid-feedback,
.bidding .custom-control-input.is-invalid ~ .invalid-tooltip,
.bidding .was-validated .custom-control-input:invalid ~ .invalid-feedback,
.bidding .was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.admin .custom-control-input.is-invalid ~ .invalid-feedback,
.admin .custom-control-input.is-invalid ~ .invalid-tooltip,
.admin .was-validated .custom-control-input:invalid ~ .invalid-feedback,
.admin .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}
.bidding .custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.bidding .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.admin .custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.admin .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.bidding .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.bidding .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.admin .custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.admin .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.bidding .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before,
.bidding .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.admin .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before,
.admin .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}
.bidding .custom-file-input.is-invalid ~ .custom-file-label,
.bidding .was-validated .custom-file-input:invalid ~ .custom-file-label,
.admin .custom-file-input.is-invalid ~ .custom-file-label,
.admin .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.bidding .custom-file-input.is-invalid ~ .invalid-feedback,
.bidding .custom-file-input.is-invalid ~ .invalid-tooltip,
.bidding .was-validated .custom-file-input:invalid ~ .invalid-feedback,
.bidding .was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.admin .custom-file-input.is-invalid ~ .invalid-feedback,
.admin .custom-file-input.is-invalid ~ .invalid-tooltip,
.admin .was-validated .custom-file-input:invalid ~ .invalid-feedback,
.admin .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}
.bidding .custom-file-input.is-invalid:focus ~ .custom-file-label,
.bidding .was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.admin .custom-file-input.is-invalid:focus ~ .custom-file-label,
.admin .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.bidding .form-inline,
.admin .form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}
.bidding .form-inline .form-check,
.admin .form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .bidding .form-inline label,
.admin .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .bidding .form-inline .form-group,
.admin .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .bidding .form-inline .form-control,
.admin .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .bidding .form-inline .form-control-plaintext,
.admin .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .bidding .form-inline .custom-select,
.bidding .form-inline .input-group,
.admin .form-inline .custom-select,
.admin .form-inline .input-group {
    width: auto;
  }
  .bidding .form-inline .form-check,
.admin .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .bidding .form-inline .form-check-input,
.admin .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .bidding .form-inline .custom-control,
.admin .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .bidding .form-inline .custom-control-label,
.admin .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.bidding .btn,
.admin .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .btn,
.admin .btn {
    transition: none;
  }
}
.bidding .btn:hover,
.admin .btn:hover {
  color: #212529;
  text-decoration: none;
}
.bidding .btn.focus,
.bidding .btn:focus,
.admin .btn.focus,
.admin .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bidding .btn.disabled,
.bidding .btn:disabled,
.admin .btn.disabled,
.admin .btn:disabled {
  opacity: 0.65;
}
.bidding a.btn.disabled,
.bidding fieldset:disabled a.btn,
.admin a.btn.disabled,
.admin fieldset:disabled a.btn {
  pointer-events: none;
}
.bidding .btn-primary,
.admin .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bidding .btn-primary:hover,
.admin .btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.bidding .btn-primary.focus,
.bidding .btn-primary:focus,
.admin .btn-primary.focus,
.admin .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.bidding .btn-primary.disabled,
.bidding .btn-primary:disabled,
.admin .btn-primary.disabled,
.admin .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bidding .btn-primary:not(:disabled):not(.disabled).active,
.bidding .btn-primary:not(:disabled):not(.disabled):active,
.bidding .show > .btn-primary.dropdown-toggle,
.admin .btn-primary:not(:disabled):not(.disabled).active,
.admin .btn-primary:not(:disabled):not(.disabled):active,
.admin .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.bidding .btn-primary:not(:disabled):not(.disabled).active:focus,
.bidding .btn-primary:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-primary.dropdown-toggle:focus,
.admin .btn-primary:not(:disabled):not(.disabled).active:focus,
.admin .btn-primary:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.bidding .btn-secondary,
.admin .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bidding .btn-secondary:hover,
.admin .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.bidding .btn-secondary.focus,
.bidding .btn-secondary:focus,
.admin .btn-secondary.focus,
.admin .btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.bidding .btn-secondary.disabled,
.bidding .btn-secondary:disabled,
.admin .btn-secondary.disabled,
.admin .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bidding .btn-secondary:not(:disabled):not(.disabled).active,
.bidding .btn-secondary:not(:disabled):not(.disabled):active,
.bidding .show > .btn-secondary.dropdown-toggle,
.admin .btn-secondary:not(:disabled):not(.disabled).active,
.admin .btn-secondary:not(:disabled):not(.disabled):active,
.admin .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.bidding .btn-secondary:not(:disabled):not(.disabled).active:focus,
.bidding .btn-secondary:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-secondary.dropdown-toggle:focus,
.admin .btn-secondary:not(:disabled):not(.disabled).active:focus,
.admin .btn-secondary:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.bidding .btn-success,
.admin .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bidding .btn-success:hover,
.admin .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.bidding .btn-success.focus,
.bidding .btn-success:focus,
.admin .btn-success.focus,
.admin .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.bidding .btn-success.disabled,
.bidding .btn-success:disabled,
.admin .btn-success.disabled,
.admin .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bidding .btn-success:not(:disabled):not(.disabled).active,
.bidding .btn-success:not(:disabled):not(.disabled):active,
.bidding .show > .btn-success.dropdown-toggle,
.admin .btn-success:not(:disabled):not(.disabled).active,
.admin .btn-success:not(:disabled):not(.disabled):active,
.admin .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.bidding .btn-success:not(:disabled):not(.disabled).active:focus,
.bidding .btn-success:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-success.dropdown-toggle:focus,
.admin .btn-success:not(:disabled):not(.disabled).active:focus,
.admin .btn-success:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.bidding .btn-info,
.admin .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bidding .btn-info:hover,
.admin .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.bidding .btn-info.focus,
.bidding .btn-info:focus,
.admin .btn-info.focus,
.admin .btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.bidding .btn-info.disabled,
.bidding .btn-info:disabled,
.admin .btn-info.disabled,
.admin .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bidding .btn-info:not(:disabled):not(.disabled).active,
.bidding .btn-info:not(:disabled):not(.disabled):active,
.bidding .show > .btn-info.dropdown-toggle,
.admin .btn-info:not(:disabled):not(.disabled).active,
.admin .btn-info:not(:disabled):not(.disabled):active,
.admin .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.bidding .btn-info:not(:disabled):not(.disabled).active:focus,
.bidding .btn-info:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-info.dropdown-toggle:focus,
.admin .btn-info:not(:disabled):not(.disabled).active:focus,
.admin .btn-info:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.bidding .btn-warning,
.admin .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bidding .btn-warning:hover,
.admin .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.bidding .btn-warning.focus,
.bidding .btn-warning:focus,
.admin .btn-warning.focus,
.admin .btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.bidding .btn-warning.disabled,
.bidding .btn-warning:disabled,
.admin .btn-warning.disabled,
.admin .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bidding .btn-warning:not(:disabled):not(.disabled).active,
.bidding .btn-warning:not(:disabled):not(.disabled):active,
.bidding .show > .btn-warning.dropdown-toggle,
.admin .btn-warning:not(:disabled):not(.disabled).active,
.admin .btn-warning:not(:disabled):not(.disabled):active,
.admin .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.bidding .btn-warning:not(:disabled):not(.disabled).active:focus,
.bidding .btn-warning:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-warning.dropdown-toggle:focus,
.admin .btn-warning:not(:disabled):not(.disabled).active:focus,
.admin .btn-warning:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.bidding .btn-danger,
.admin .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bidding .btn-danger:hover,
.admin .btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.bidding .btn-danger.focus,
.bidding .btn-danger:focus,
.admin .btn-danger.focus,
.admin .btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.bidding .btn-danger.disabled,
.bidding .btn-danger:disabled,
.admin .btn-danger.disabled,
.admin .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bidding .btn-danger:not(:disabled):not(.disabled).active,
.bidding .btn-danger:not(:disabled):not(.disabled):active,
.bidding .show > .btn-danger.dropdown-toggle,
.admin .btn-danger:not(:disabled):not(.disabled).active,
.admin .btn-danger:not(:disabled):not(.disabled):active,
.admin .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.bidding .btn-danger:not(:disabled):not(.disabled).active:focus,
.bidding .btn-danger:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-danger.dropdown-toggle:focus,
.admin .btn-danger:not(:disabled):not(.disabled).active:focus,
.admin .btn-danger:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.bidding .btn-light,
.admin .btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bidding .btn-light:hover,
.admin .btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.bidding .btn-light.focus,
.bidding .btn-light:focus,
.admin .btn-light.focus,
.admin .btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.bidding .btn-light.disabled,
.bidding .btn-light:disabled,
.admin .btn-light.disabled,
.admin .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bidding .btn-light:not(:disabled):not(.disabled).active,
.bidding .btn-light:not(:disabled):not(.disabled):active,
.bidding .show > .btn-light.dropdown-toggle,
.admin .btn-light:not(:disabled):not(.disabled).active,
.admin .btn-light:not(:disabled):not(.disabled):active,
.admin .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.bidding .btn-light:not(:disabled):not(.disabled).active:focus,
.bidding .btn-light:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-light.dropdown-toggle:focus,
.admin .btn-light:not(:disabled):not(.disabled).active:focus,
.admin .btn-light:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.bidding .btn-dark,
.admin .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bidding .btn-dark:hover,
.admin .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.bidding .btn-dark.focus,
.bidding .btn-dark:focus,
.admin .btn-dark.focus,
.admin .btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.bidding .btn-dark.disabled,
.bidding .btn-dark:disabled,
.admin .btn-dark.disabled,
.admin .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bidding .btn-dark:not(:disabled):not(.disabled).active,
.bidding .btn-dark:not(:disabled):not(.disabled):active,
.bidding .show > .btn-dark.dropdown-toggle,
.admin .btn-dark:not(:disabled):not(.disabled).active,
.admin .btn-dark:not(:disabled):not(.disabled):active,
.admin .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.bidding .btn-dark:not(:disabled):not(.disabled).active:focus,
.bidding .btn-dark:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-dark.dropdown-toggle:focus,
.admin .btn-dark:not(:disabled):not(.disabled).active:focus,
.admin .btn-dark:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.bidding .btn-outline-primary,
.admin .btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.bidding .btn-outline-primary:hover,
.admin .btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bidding .btn-outline-primary.focus,
.bidding .btn-outline-primary:focus,
.admin .btn-outline-primary.focus,
.admin .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.bidding .btn-outline-primary.disabled,
.bidding .btn-outline-primary:disabled,
.admin .btn-outline-primary.disabled,
.admin .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.bidding .btn-outline-primary:not(:disabled):not(.disabled).active,
.bidding .btn-outline-primary:not(:disabled):not(.disabled):active,
.bidding .show > .btn-outline-primary.dropdown-toggle,
.admin .btn-outline-primary:not(:disabled):not(.disabled).active,
.admin .btn-outline-primary:not(:disabled):not(.disabled):active,
.admin .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bidding .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.bidding .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-outline-primary.dropdown-toggle:focus,
.admin .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.admin .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.bidding .btn-outline-secondary,
.admin .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.bidding .btn-outline-secondary:hover,
.admin .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bidding .btn-outline-secondary.focus,
.bidding .btn-outline-secondary:focus,
.admin .btn-outline-secondary.focus,
.admin .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.bidding .btn-outline-secondary.disabled,
.bidding .btn-outline-secondary:disabled,
.admin .btn-outline-secondary.disabled,
.admin .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.bidding .btn-outline-secondary:not(:disabled):not(.disabled).active,
.bidding .btn-outline-secondary:not(:disabled):not(.disabled):active,
.bidding .show > .btn-outline-secondary.dropdown-toggle,
.admin .btn-outline-secondary:not(:disabled):not(.disabled).active,
.admin .btn-outline-secondary:not(:disabled):not(.disabled):active,
.admin .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.bidding .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.bidding .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-outline-secondary.dropdown-toggle:focus,
.admin .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.admin .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.bidding .btn-outline-success,
.admin .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.bidding .btn-outline-success:hover,
.admin .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bidding .btn-outline-success.focus,
.bidding .btn-outline-success:focus,
.admin .btn-outline-success.focus,
.admin .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.bidding .btn-outline-success.disabled,
.bidding .btn-outline-success:disabled,
.admin .btn-outline-success.disabled,
.admin .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.bidding .btn-outline-success:not(:disabled):not(.disabled).active,
.bidding .btn-outline-success:not(:disabled):not(.disabled):active,
.bidding .show > .btn-outline-success.dropdown-toggle,
.admin .btn-outline-success:not(:disabled):not(.disabled).active,
.admin .btn-outline-success:not(:disabled):not(.disabled):active,
.admin .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.bidding .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.bidding .btn-outline-success:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-outline-success.dropdown-toggle:focus,
.admin .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.admin .btn-outline-success:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.bidding .btn-outline-info,
.admin .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.bidding .btn-outline-info:hover,
.admin .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bidding .btn-outline-info.focus,
.bidding .btn-outline-info:focus,
.admin .btn-outline-info.focus,
.admin .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.bidding .btn-outline-info.disabled,
.bidding .btn-outline-info:disabled,
.admin .btn-outline-info.disabled,
.admin .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.bidding .btn-outline-info:not(:disabled):not(.disabled).active,
.bidding .btn-outline-info:not(:disabled):not(.disabled):active,
.bidding .show > .btn-outline-info.dropdown-toggle,
.admin .btn-outline-info:not(:disabled):not(.disabled).active,
.admin .btn-outline-info:not(:disabled):not(.disabled):active,
.admin .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.bidding .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.bidding .btn-outline-info:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-outline-info.dropdown-toggle:focus,
.admin .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.admin .btn-outline-info:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.bidding .btn-outline-warning,
.admin .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.bidding .btn-outline-warning:hover,
.admin .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bidding .btn-outline-warning.focus,
.bidding .btn-outline-warning:focus,
.admin .btn-outline-warning.focus,
.admin .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.bidding .btn-outline-warning.disabled,
.bidding .btn-outline-warning:disabled,
.admin .btn-outline-warning.disabled,
.admin .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.bidding .btn-outline-warning:not(:disabled):not(.disabled).active,
.bidding .btn-outline-warning:not(:disabled):not(.disabled):active,
.bidding .show > .btn-outline-warning.dropdown-toggle,
.admin .btn-outline-warning:not(:disabled):not(.disabled).active,
.admin .btn-outline-warning:not(:disabled):not(.disabled):active,
.admin .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.bidding .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.bidding .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-outline-warning.dropdown-toggle:focus,
.admin .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.admin .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.bidding .btn-outline-danger,
.admin .btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.bidding .btn-outline-danger:hover,
.admin .btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bidding .btn-outline-danger.focus,
.bidding .btn-outline-danger:focus,
.admin .btn-outline-danger.focus,
.admin .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.bidding .btn-outline-danger.disabled,
.bidding .btn-outline-danger:disabled,
.admin .btn-outline-danger.disabled,
.admin .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.bidding .btn-outline-danger:not(:disabled):not(.disabled).active,
.bidding .btn-outline-danger:not(:disabled):not(.disabled):active,
.bidding .show > .btn-outline-danger.dropdown-toggle,
.admin .btn-outline-danger:not(:disabled):not(.disabled).active,
.admin .btn-outline-danger:not(:disabled):not(.disabled):active,
.admin .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.bidding .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.bidding .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-outline-danger.dropdown-toggle:focus,
.admin .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.admin .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.bidding .btn-outline-light,
.admin .btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.bidding .btn-outline-light:hover,
.admin .btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bidding .btn-outline-light.focus,
.bidding .btn-outline-light:focus,
.admin .btn-outline-light.focus,
.admin .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.bidding .btn-outline-light.disabled,
.bidding .btn-outline-light:disabled,
.admin .btn-outline-light.disabled,
.admin .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.bidding .btn-outline-light:not(:disabled):not(.disabled).active,
.bidding .btn-outline-light:not(:disabled):not(.disabled):active,
.bidding .show > .btn-outline-light.dropdown-toggle,
.admin .btn-outline-light:not(:disabled):not(.disabled).active,
.admin .btn-outline-light:not(:disabled):not(.disabled):active,
.admin .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.bidding .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.bidding .btn-outline-light:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-outline-light.dropdown-toggle:focus,
.admin .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.admin .btn-outline-light:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.bidding .btn-outline-dark,
.admin .btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.bidding .btn-outline-dark:hover,
.admin .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bidding .btn-outline-dark.focus,
.bidding .btn-outline-dark:focus,
.admin .btn-outline-dark.focus,
.admin .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.bidding .btn-outline-dark.disabled,
.bidding .btn-outline-dark:disabled,
.admin .btn-outline-dark.disabled,
.admin .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.bidding .btn-outline-dark:not(:disabled):not(.disabled).active,
.bidding .btn-outline-dark:not(:disabled):not(.disabled):active,
.bidding .show > .btn-outline-dark.dropdown-toggle,
.admin .btn-outline-dark:not(:disabled):not(.disabled).active,
.admin .btn-outline-dark:not(:disabled):not(.disabled):active,
.admin .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.bidding .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.bidding .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.bidding .show > .btn-outline-dark.dropdown-toggle:focus,
.admin .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.admin .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.admin .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.bidding .btn-link,
.admin .btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.bidding .btn-link:hover,
.admin .btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.bidding .btn-link.focus,
.bidding .btn-link:focus,
.admin .btn-link.focus,
.admin .btn-link:focus {
  text-decoration: underline;
  box-shadow: none;
}
.bidding .btn-link.disabled,
.bidding .btn-link:disabled,
.admin .btn-link.disabled,
.admin .btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.bidding .btn-group-lg > .btn,
.bidding .btn-lg,
.admin .btn-group-lg > .btn,
.admin .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.bidding .btn-group-sm > .btn,
.bidding .btn-sm,
.admin .btn-group-sm > .btn,
.admin .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.bidding .btn-block,
.admin .btn-block {
  display: block;
  width: 100%;
}
.bidding .btn-block + .btn-block,
.admin .btn-block + .btn-block {
  margin-top: 0.5rem;
}
.bidding .fade,
.admin .fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .fade,
.admin .fade {
    transition: none;
  }
}
.bidding .fade:not(.show),
.admin .fade:not(.show) {
  opacity: 0;
}
.bidding .collapse:not(.show),
.admin .collapse:not(.show) {
  display: none;
}
.bidding .collapsing,
.admin .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .collapsing,
.admin .collapsing {
    transition: none;
  }
}
.bidding .dropdown,
.bidding .dropleft,
.bidding .dropright,
.bidding .dropup,
.admin .dropdown,
.admin .dropleft,
.admin .dropright,
.admin .dropup {
  position: relative;
}
.bidding .dropdown-toggle,
.admin .dropdown-toggle {
  white-space: nowrap;
}
.bidding .dropdown-toggle::after,
.admin .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.bidding .dropdown-toggle:empty::after,
.admin .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bidding .dropdown-menu,
.admin .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.bidding .dropdown-menu-left,
.admin .dropdown-menu-left {
  right: auto;
  left: 0;
}
.bidding .dropdown-menu-right,
.admin .dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .bidding .dropdown-menu-sm-left,
.admin .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .bidding .dropdown-menu-sm-right,
.admin .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .bidding .dropdown-menu-md-left,
.admin .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .bidding .dropdown-menu-md-right,
.admin .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .bidding .dropdown-menu-lg-left,
.admin .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .bidding .dropdown-menu-lg-right,
.admin .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .bidding .dropdown-menu-xl-left,
.admin .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .bidding .dropdown-menu-xl-right,
.admin .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.bidding .dropup .dropdown-menu,
.admin .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.bidding .dropup .dropdown-toggle::after,
.admin .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.bidding .dropup .dropdown-toggle:empty::after,
.admin .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bidding .dropright .dropdown-menu,
.admin .dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.bidding .dropright .dropdown-toggle::after,
.admin .dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.bidding .dropright .dropdown-toggle:empty::after,
.admin .dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bidding .dropright .dropdown-toggle::after,
.admin .dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.bidding .dropleft .dropdown-menu,
.admin .dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.bidding .dropleft .dropdown-toggle::after,
.admin .dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.bidding .dropleft .dropdown-toggle::after,
.admin .dropleft .dropdown-toggle::after {
  display: none;
}
.bidding .dropleft .dropdown-toggle::before,
.admin .dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.bidding .dropleft .dropdown-toggle:empty::after,
.admin .dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bidding .dropleft .dropdown-toggle::before,
.admin .dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.bidding .dropdown-menu[x-placement^=bottom],
.bidding .dropdown-menu[x-placement^=left],
.bidding .dropdown-menu[x-placement^=right],
.bidding .dropdown-menu[x-placement^=top],
.admin .dropdown-menu[x-placement^=bottom],
.admin .dropdown-menu[x-placement^=left],
.admin .dropdown-menu[x-placement^=right],
.admin .dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto;
}
.bidding .dropdown-divider,
.admin .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.bidding .dropdown-item,
.admin .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.bidding .dropdown-item:focus,
.bidding .dropdown-item:hover,
.admin .dropdown-item:focus,
.admin .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.bidding .dropdown-item.active,
.bidding .dropdown-item:active,
.admin .dropdown-item.active,
.admin .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.bidding .dropdown-item.disabled,
.bidding .dropdown-item:disabled,
.admin .dropdown-item.disabled,
.admin .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.bidding .dropdown-menu.show,
.admin .dropdown-menu.show {
  display: block;
}
.bidding .dropdown-header,
.admin .dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.bidding .dropdown-item-text,
.admin .dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.bidding .btn-group,
.bidding .btn-group-vertical,
.admin .btn-group,
.admin .btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.bidding .btn-group-vertical > .btn,
.bidding .btn-group > .btn,
.admin .btn-group-vertical > .btn,
.admin .btn-group > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.bidding .btn-group-vertical > .btn:hover,
.bidding .btn-group > .btn:hover,
.admin .btn-group-vertical > .btn:hover,
.admin .btn-group > .btn:hover {
  z-index: 1;
}
.bidding .btn-group-vertical > .btn.active,
.bidding .btn-group-vertical > .btn:active,
.bidding .btn-group-vertical > .btn:focus,
.bidding .btn-group > .btn.active,
.bidding .btn-group > .btn:active,
.bidding .btn-group > .btn:focus,
.admin .btn-group-vertical > .btn.active,
.admin .btn-group-vertical > .btn:active,
.admin .btn-group-vertical > .btn:focus,
.admin .btn-group > .btn.active,
.admin .btn-group > .btn:active,
.admin .btn-group > .btn:focus {
  z-index: 1;
}
.bidding .btn-toolbar,
.admin .btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.bidding .btn-toolbar .input-group,
.admin .btn-toolbar .input-group {
  width: auto;
}
.bidding .btn-group > .btn-group:not(:first-child),
.bidding .btn-group > .btn:not(:first-child),
.admin .btn-group > .btn-group:not(:first-child),
.admin .btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.bidding .btn-group > .btn-group:not(:last-child) > .btn,
.bidding .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.admin .btn-group > .btn-group:not(:last-child) > .btn,
.admin .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bidding .btn-group > .btn-group:not(:first-child) > .btn,
.bidding .btn-group > .btn:not(:first-child),
.admin .btn-group > .btn-group:not(:first-child) > .btn,
.admin .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bidding .dropdown-toggle-split,
.admin .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.bidding .dropdown-toggle-split::after,
.bidding .dropright .dropdown-toggle-split::after,
.bidding .dropup .dropdown-toggle-split::after,
.admin .dropdown-toggle-split::after,
.admin .dropright .dropdown-toggle-split::after,
.admin .dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.bidding .dropleft .dropdown-toggle-split::before,
.admin .dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.bidding .btn-group-sm > .btn + .dropdown-toggle-split,
.bidding .btn-sm + .dropdown-toggle-split,
.admin .btn-group-sm > .btn + .dropdown-toggle-split,
.admin .btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.bidding .btn-group-lg > .btn + .dropdown-toggle-split,
.bidding .btn-lg + .dropdown-toggle-split,
.admin .btn-group-lg > .btn + .dropdown-toggle-split,
.admin .btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.bidding .btn-group-vertical,
.admin .btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}
.bidding .btn-group-vertical > .btn,
.bidding .btn-group-vertical > .btn-group,
.admin .btn-group-vertical > .btn,
.admin .btn-group-vertical > .btn-group {
  width: 100%;
}
.bidding .btn-group-vertical > .btn-group:not(:first-child),
.bidding .btn-group-vertical > .btn:not(:first-child),
.admin .btn-group-vertical > .btn-group:not(:first-child),
.admin .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.bidding .btn-group-vertical > .btn-group:not(:last-child) > .btn,
.bidding .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.admin .btn-group-vertical > .btn-group:not(:last-child) > .btn,
.admin .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bidding .btn-group-vertical > .btn-group:not(:first-child) > .btn,
.bidding .btn-group-vertical > .btn:not(:first-child),
.admin .btn-group-vertical > .btn-group:not(:first-child) > .btn,
.admin .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bidding .btn-group-toggle > .btn,
.bidding .btn-group-toggle > .btn-group > .btn,
.admin .btn-group-toggle > .btn,
.admin .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.bidding .btn-group-toggle > .btn input[type=checkbox],
.bidding .btn-group-toggle > .btn input[type=radio],
.bidding .btn-group-toggle > .btn-group > .btn input[type=checkbox],
.bidding .btn-group-toggle > .btn-group > .btn input[type=radio],
.admin .btn-group-toggle > .btn input[type=checkbox],
.admin .btn-group-toggle > .btn input[type=radio],
.admin .btn-group-toggle > .btn-group > .btn input[type=checkbox],
.admin .btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.bidding .input-group,
.admin .input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.bidding .input-group > .custom-file,
.bidding .input-group > .custom-select,
.bidding .input-group > .form-control,
.bidding .input-group > .form-control-plaintext,
.admin .input-group > .custom-file,
.admin .input-group > .custom-select,
.admin .input-group > .form-control,
.admin .input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.bidding .input-group > .custom-file + .custom-file,
.bidding .input-group > .custom-file + .custom-select,
.bidding .input-group > .custom-file + .form-control,
.bidding .input-group > .custom-select + .custom-file,
.bidding .input-group > .custom-select + .custom-select,
.bidding .input-group > .custom-select + .form-control,
.bidding .input-group > .form-control + .custom-file,
.bidding .input-group > .form-control + .custom-select,
.bidding .input-group > .form-control + .form-control,
.bidding .input-group > .form-control-plaintext + .custom-file,
.bidding .input-group > .form-control-plaintext + .custom-select,
.bidding .input-group > .form-control-plaintext + .form-control,
.admin .input-group > .custom-file + .custom-file,
.admin .input-group > .custom-file + .custom-select,
.admin .input-group > .custom-file + .form-control,
.admin .input-group > .custom-select + .custom-file,
.admin .input-group > .custom-select + .custom-select,
.admin .input-group > .custom-select + .form-control,
.admin .input-group > .form-control + .custom-file,
.admin .input-group > .form-control + .custom-select,
.admin .input-group > .form-control + .form-control,
.admin .input-group > .form-control-plaintext + .custom-file,
.admin .input-group > .form-control-plaintext + .custom-select,
.admin .input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}
.bidding .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.bidding .input-group > .custom-select:focus,
.bidding .input-group > .form-control:focus,
.admin .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.admin .input-group > .custom-select:focus,
.admin .input-group > .form-control:focus {
  z-index: 3;
}
.bidding .input-group > .custom-file .custom-file-input:focus,
.admin .input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.bidding .input-group > .custom-select:not(:last-child),
.bidding .input-group > .form-control:not(:last-child),
.admin .input-group > .custom-select:not(:last-child),
.admin .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bidding .input-group > .custom-select:not(:first-child),
.bidding .input-group > .form-control:not(:first-child),
.admin .input-group > .custom-select:not(:first-child),
.admin .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bidding .input-group > .custom-file,
.admin .input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.bidding .input-group > .custom-file:not(:last-child) .custom-file-label,
.bidding .input-group > .custom-file:not(:last-child) .custom-file-label::after,
.admin .input-group > .custom-file:not(:last-child) .custom-file-label,
.admin .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bidding .input-group > .custom-file:not(:first-child) .custom-file-label,
.admin .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bidding .input-group-append,
.bidding .input-group-prepend,
.admin .input-group-append,
.admin .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
.bidding .input-group-append .btn,
.bidding .input-group-prepend .btn,
.admin .input-group-append .btn,
.admin .input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.bidding .input-group-append .btn:focus,
.bidding .input-group-prepend .btn:focus,
.admin .input-group-append .btn:focus,
.admin .input-group-prepend .btn:focus {
  z-index: 3;
}
.bidding .input-group-append .btn + .btn,
.bidding .input-group-append .btn + .input-group-text,
.bidding .input-group-append .input-group-text + .btn,
.bidding .input-group-append .input-group-text + .input-group-text,
.bidding .input-group-prepend .btn + .btn,
.bidding .input-group-prepend .btn + .input-group-text,
.bidding .input-group-prepend .input-group-text + .btn,
.bidding .input-group-prepend .input-group-text + .input-group-text,
.admin .input-group-append .btn + .btn,
.admin .input-group-append .btn + .input-group-text,
.admin .input-group-append .input-group-text + .btn,
.admin .input-group-append .input-group-text + .input-group-text,
.admin .input-group-prepend .btn + .btn,
.admin .input-group-prepend .btn + .input-group-text,
.admin .input-group-prepend .input-group-text + .btn,
.admin .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.bidding .input-group-prepend,
.admin .input-group-prepend {
  margin-right: -1px;
}
.bidding .input-group-append,
.admin .input-group-append {
  margin-left: -1px;
}
.bidding .input-group-text,
.admin .input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.bidding .input-group-text input[type=checkbox],
.bidding .input-group-text input[type=radio],
.admin .input-group-text input[type=checkbox],
.admin .input-group-text input[type=radio] {
  margin-top: 0;
}
.bidding .input-group-lg > .custom-select,
.bidding .input-group-lg > .form-control:not(textarea),
.admin .input-group-lg > .custom-select,
.admin .input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}
.bidding .input-group-lg > .custom-select,
.bidding .input-group-lg > .form-control,
.bidding .input-group-lg > .input-group-append > .btn,
.bidding .input-group-lg > .input-group-append > .input-group-text,
.bidding .input-group-lg > .input-group-prepend > .btn,
.bidding .input-group-lg > .input-group-prepend > .input-group-text,
.admin .input-group-lg > .custom-select,
.admin .input-group-lg > .form-control,
.admin .input-group-lg > .input-group-append > .btn,
.admin .input-group-lg > .input-group-append > .input-group-text,
.admin .input-group-lg > .input-group-prepend > .btn,
.admin .input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.bidding .input-group-sm > .custom-select,
.bidding .input-group-sm > .form-control:not(textarea),
.admin .input-group-sm > .custom-select,
.admin .input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}
.bidding .input-group-sm > .custom-select,
.bidding .input-group-sm > .form-control,
.bidding .input-group-sm > .input-group-append > .btn,
.bidding .input-group-sm > .input-group-append > .input-group-text,
.bidding .input-group-sm > .input-group-prepend > .btn,
.bidding .input-group-sm > .input-group-prepend > .input-group-text,
.admin .input-group-sm > .custom-select,
.admin .input-group-sm > .form-control,
.admin .input-group-sm > .input-group-append > .btn,
.admin .input-group-sm > .input-group-append > .input-group-text,
.admin .input-group-sm > .input-group-prepend > .btn,
.admin .input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.bidding .input-group-lg > .custom-select,
.bidding .input-group-sm > .custom-select,
.admin .input-group-lg > .custom-select,
.admin .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.bidding .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.bidding .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.bidding .input-group > .input-group-append:not(:last-child) > .btn,
.bidding .input-group > .input-group-append:not(:last-child) > .input-group-text,
.bidding .input-group > .input-group-prepend > .btn,
.bidding .input-group > .input-group-prepend > .input-group-text,
.admin .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.admin .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.admin .input-group > .input-group-append:not(:last-child) > .btn,
.admin .input-group > .input-group-append:not(:last-child) > .input-group-text,
.admin .input-group > .input-group-prepend > .btn,
.admin .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bidding .input-group > .input-group-append > .btn,
.bidding .input-group > .input-group-append > .input-group-text,
.bidding .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.bidding .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.bidding .input-group > .input-group-prepend:not(:first-child) > .btn,
.bidding .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.admin .input-group > .input-group-append > .btn,
.admin .input-group > .input-group-append > .input-group-text,
.admin .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.admin .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.admin .input-group > .input-group-prepend:not(:first-child) > .btn,
.admin .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bidding .custom-control,
.admin .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.bidding .custom-control-inline,
.admin .custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
.bidding .custom-control-input,
.admin .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.bidding .custom-control-input:checked ~ .custom-control-label::before,
.admin .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.bidding .custom-control-input:focus ~ .custom-control-label::before,
.admin .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bidding .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.admin .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.bidding .custom-control-input:not(:disabled):active ~ .custom-control-label::before,
.admin .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.bidding .custom-control-input:disabled ~ .custom-control-label,
.admin .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.bidding .custom-control-input:disabled ~ .custom-control-label::before,
.admin .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.bidding .custom-control-label,
.admin .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.bidding .custom-control-label::before,
.admin .custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.bidding .custom-control-label::after,
.admin .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.bidding .custom-checkbox .custom-control-label::before,
.admin .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.bidding .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
.admin .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.bidding .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.admin .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.bidding .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after,
.admin .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.bidding .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before,
.admin .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.bidding .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before,
.admin .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.bidding .custom-radio .custom-control-label::before,
.admin .custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.bidding .custom-radio .custom-control-input:checked ~ .custom-control-label::after,
.admin .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.bidding .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before,
.admin .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.bidding .custom-switch,
.admin .custom-switch {
  padding-left: 2.25rem;
}
.bidding .custom-switch .custom-control-label::before,
.admin .custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.bidding .custom-switch .custom-control-label::after,
.admin .custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .custom-switch .custom-control-label::after,
.admin .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.bidding .custom-switch .custom-control-input:checked ~ .custom-control-label::after,
.admin .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.bidding .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before,
.admin .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.bidding .custom-select,
.admin .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.bidding .custom-select:focus,
.admin .custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bidding .custom-select:focus::-ms-value,
.admin .custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.bidding .custom-select[multiple],
.bidding .custom-select[size]:not([size="1"]),
.admin .custom-select[multiple],
.admin .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.bidding .custom-select:disabled,
.admin .custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.bidding .custom-select::-ms-expand,
.admin .custom-select::-ms-expand {
  display: none;
}
.bidding .custom-select-sm,
.admin .custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.bidding .custom-select-lg,
.admin .custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.bidding .custom-file,
.admin .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.bidding .custom-file-input,
.admin .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.bidding .custom-file-input:focus ~ .custom-file-label,
.admin .custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bidding .custom-file-input:disabled ~ .custom-file-label,
.admin .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.bidding .custom-file-input:lang(en) ~ .custom-file-label::after,
.admin .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.bidding .custom-file-input ~ .custom-file-label[data-browse]::after,
.admin .custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.bidding .custom-file-label,
.admin .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.bidding .custom-file-label::after,
.admin .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.bidding .custom-range,
.admin .custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.bidding .custom-range:focus,
.admin .custom-range:focus {
  outline: 0;
}
.bidding .custom-range:focus::-webkit-slider-thumb,
.admin .custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bidding .custom-range:focus::-moz-range-thumb,
.admin .custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bidding .custom-range:focus::-ms-thumb,
.admin .custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bidding .custom-range::-moz-focus-outer,
.admin .custom-range::-moz-focus-outer {
  border: 0;
}
.bidding .custom-range::-webkit-slider-thumb,
.admin .custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .custom-range::-webkit-slider-thumb,
.admin .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.bidding .custom-range::-webkit-slider-thumb:active,
.admin .custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.bidding .custom-range::-webkit-slider-runnable-track,
.admin .custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.bidding .custom-range::-moz-range-thumb,
.admin .custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .custom-range::-moz-range-thumb,
.admin .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.bidding .custom-range::-moz-range-thumb:active,
.admin .custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.bidding .custom-range::-moz-range-track,
.admin .custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.bidding .custom-range::-ms-thumb,
.admin .custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .custom-range::-ms-thumb,
.admin .custom-range::-ms-thumb {
    transition: none;
  }
}
.bidding .custom-range::-ms-thumb:active,
.admin .custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.bidding .custom-range::-ms-track,
.admin .custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.bidding .custom-range::-ms-fill-lower,
.admin .custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.bidding .custom-range::-ms-fill-upper,
.admin .custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.bidding .custom-range:disabled::-webkit-slider-thumb,
.admin .custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.bidding .custom-range:disabled::-webkit-slider-runnable-track,
.admin .custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.bidding .custom-range:disabled::-moz-range-thumb,
.admin .custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.bidding .custom-range:disabled::-moz-range-track,
.admin .custom-range:disabled::-moz-range-track {
  cursor: default;
}
.bidding .custom-range:disabled::-ms-thumb,
.admin .custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.bidding .custom-control-label::before,
.bidding .custom-file-label,
.bidding .custom-select,
.admin .custom-control-label::before,
.admin .custom-file-label,
.admin .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .custom-control-label::before,
.bidding .custom-file-label,
.bidding .custom-select,
.admin .custom-control-label::before,
.admin .custom-file-label,
.admin .custom-select {
    transition: none;
  }
}
.bidding .nav,
.admin .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.bidding .nav-link,
.admin .nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.bidding .nav-link:focus,
.bidding .nav-link:hover,
.admin .nav-link:focus,
.admin .nav-link:hover {
  text-decoration: none;
}
.bidding .nav-link.disabled,
.admin .nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.bidding .nav-tabs,
.admin .nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.bidding .nav-tabs .nav-item,
.admin .nav-tabs .nav-item {
  margin-bottom: -1px;
}
.bidding .nav-tabs .nav-link,
.admin .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.bidding .nav-tabs .nav-link:focus,
.bidding .nav-tabs .nav-link:hover,
.admin .nav-tabs .nav-link:focus,
.admin .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.bidding .nav-tabs .nav-link.disabled,
.admin .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.bidding .nav-tabs .nav-item.show .nav-link,
.bidding .nav-tabs .nav-link.active,
.admin .nav-tabs .nav-item.show .nav-link,
.admin .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.bidding .nav-tabs .dropdown-menu,
.admin .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bidding .nav-pills .nav-link,
.admin .nav-pills .nav-link {
  border-radius: 0.25rem;
}
.bidding .nav-pills .nav-link.active,
.bidding .nav-pills .show > .nav-link,
.admin .nav-pills .nav-link.active,
.admin .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.bidding .nav-fill .nav-item,
.admin .nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.bidding .nav-justified .nav-item,
.admin .nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}
.bidding .tab-content > .tab-pane,
.admin .tab-content > .tab-pane {
  display: none;
}
.bidding .tab-content > .active,
.admin .tab-content > .active {
  display: block;
}
.bidding .navbar,
.admin .navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.bidding .navbar > .container,
.bidding .navbar > .container-fluid,
.admin .navbar > .container,
.admin .navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.bidding .navbar-brand,
.admin .navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.bidding .navbar-brand:focus,
.bidding .navbar-brand:hover,
.admin .navbar-brand:focus,
.admin .navbar-brand:hover {
  text-decoration: none;
}
.bidding .navbar-nav,
.admin .navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.bidding .navbar-nav .nav-link,
.admin .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.bidding .navbar-nav .dropdown-menu,
.admin .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.bidding .navbar-text,
.admin .navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.bidding .navbar-collapse,
.admin .navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}
.bidding .navbar-toggler,
.admin .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.bidding .navbar-toggler:focus,
.bidding .navbar-toggler:hover,
.admin .navbar-toggler:focus,
.admin .navbar-toggler:hover {
  text-decoration: none;
}
.bidding .navbar-toggler-icon,
.admin .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .bidding .navbar-expand-sm > .container,
.bidding .navbar-expand-sm > .container-fluid,
.admin .navbar-expand-sm > .container,
.admin .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .bidding .navbar-expand-sm,
.admin .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .bidding .navbar-expand-sm .navbar-nav,
.admin .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .bidding .navbar-expand-sm .navbar-nav .dropdown-menu,
.admin .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bidding .navbar-expand-sm .navbar-nav .nav-link,
.admin .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bidding .navbar-expand-sm > .container,
.bidding .navbar-expand-sm > .container-fluid,
.admin .navbar-expand-sm > .container,
.admin .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .bidding .navbar-expand-sm .navbar-collapse,
.admin .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .bidding .navbar-expand-sm .navbar-toggler,
.admin .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .bidding .navbar-expand-md > .container,
.bidding .navbar-expand-md > .container-fluid,
.admin .navbar-expand-md > .container,
.admin .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .bidding .navbar-expand-md,
.admin .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .bidding .navbar-expand-md .navbar-nav,
.admin .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .bidding .navbar-expand-md .navbar-nav .dropdown-menu,
.admin .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bidding .navbar-expand-md .navbar-nav .nav-link,
.admin .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bidding .navbar-expand-md > .container,
.bidding .navbar-expand-md > .container-fluid,
.admin .navbar-expand-md > .container,
.admin .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .bidding .navbar-expand-md .navbar-collapse,
.admin .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .bidding .navbar-expand-md .navbar-toggler,
.admin .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .bidding .navbar-expand-lg > .container,
.bidding .navbar-expand-lg > .container-fluid,
.admin .navbar-expand-lg > .container,
.admin .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .bidding .navbar-expand-lg,
.admin .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .bidding .navbar-expand-lg .navbar-nav,
.admin .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .bidding .navbar-expand-lg .navbar-nav .dropdown-menu,
.admin .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bidding .navbar-expand-lg .navbar-nav .nav-link,
.admin .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bidding .navbar-expand-lg > .container,
.bidding .navbar-expand-lg > .container-fluid,
.admin .navbar-expand-lg > .container,
.admin .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .bidding .navbar-expand-lg .navbar-collapse,
.admin .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .bidding .navbar-expand-lg .navbar-toggler,
.admin .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .bidding .navbar-expand-xl > .container,
.bidding .navbar-expand-xl > .container-fluid,
.admin .navbar-expand-xl > .container,
.admin .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .bidding .navbar-expand-xl,
.admin .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .bidding .navbar-expand-xl .navbar-nav,
.admin .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .bidding .navbar-expand-xl .navbar-nav .dropdown-menu,
.admin .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bidding .navbar-expand-xl .navbar-nav .nav-link,
.admin .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bidding .navbar-expand-xl > .container,
.bidding .navbar-expand-xl > .container-fluid,
.admin .navbar-expand-xl > .container,
.admin .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .bidding .navbar-expand-xl .navbar-collapse,
.admin .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .bidding .navbar-expand-xl .navbar-toggler,
.admin .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.bidding .navbar-expand,
.admin .navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.bidding .navbar-expand > .container,
.bidding .navbar-expand > .container-fluid,
.admin .navbar-expand > .container,
.admin .navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.bidding .navbar-expand .navbar-nav,
.admin .navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}
.bidding .navbar-expand .navbar-nav .dropdown-menu,
.admin .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.bidding .navbar-expand .navbar-nav .nav-link,
.admin .navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.bidding .navbar-expand > .container,
.bidding .navbar-expand > .container-fluid,
.admin .navbar-expand > .container,
.admin .navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.bidding .navbar-expand .navbar-collapse,
.admin .navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.bidding .navbar-expand .navbar-toggler,
.admin .navbar-expand .navbar-toggler {
  display: none;
}
.bidding .navbar-light .navbar-brand,
.admin .navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.bidding .navbar-light .navbar-brand:focus,
.bidding .navbar-light .navbar-brand:hover,
.admin .navbar-light .navbar-brand:focus,
.admin .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.bidding .navbar-light .navbar-nav .nav-link,
.admin .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.bidding .navbar-light .navbar-nav .nav-link:focus,
.bidding .navbar-light .navbar-nav .nav-link:hover,
.admin .navbar-light .navbar-nav .nav-link:focus,
.admin .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.bidding .navbar-light .navbar-nav .nav-link.disabled,
.admin .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.bidding .navbar-light .navbar-nav .active > .nav-link,
.bidding .navbar-light .navbar-nav .nav-link.active,
.bidding .navbar-light .navbar-nav .nav-link.show,
.bidding .navbar-light .navbar-nav .show > .nav-link,
.admin .navbar-light .navbar-nav .active > .nav-link,
.admin .navbar-light .navbar-nav .nav-link.active,
.admin .navbar-light .navbar-nav .nav-link.show,
.admin .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.bidding .navbar-light .navbar-toggler,
.admin .navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.bidding .navbar-light .navbar-toggler-icon,
.admin .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.bidding .navbar-light .navbar-text,
.admin .navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.bidding .navbar-light .navbar-text a,
.admin .navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.bidding .navbar-light .navbar-text a:focus,
.bidding .navbar-light .navbar-text a:hover,
.admin .navbar-light .navbar-text a:focus,
.admin .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.bidding .navbar-dark .navbar-brand,
.admin .navbar-dark .navbar-brand {
  color: #fff;
}
.bidding .navbar-dark .navbar-brand:focus,
.bidding .navbar-dark .navbar-brand:hover,
.admin .navbar-dark .navbar-brand:focus,
.admin .navbar-dark .navbar-brand:hover {
  color: #fff;
}
.bidding .navbar-dark .navbar-nav .nav-link,
.admin .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.bidding .navbar-dark .navbar-nav .nav-link:focus,
.bidding .navbar-dark .navbar-nav .nav-link:hover,
.admin .navbar-dark .navbar-nav .nav-link:focus,
.admin .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.bidding .navbar-dark .navbar-nav .nav-link.disabled,
.admin .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.bidding .navbar-dark .navbar-nav .active > .nav-link,
.bidding .navbar-dark .navbar-nav .nav-link.active,
.bidding .navbar-dark .navbar-nav .nav-link.show,
.bidding .navbar-dark .navbar-nav .show > .nav-link,
.admin .navbar-dark .navbar-nav .active > .nav-link,
.admin .navbar-dark .navbar-nav .nav-link.active,
.admin .navbar-dark .navbar-nav .nav-link.show,
.admin .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.bidding .navbar-dark .navbar-toggler,
.admin .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.bidding .navbar-dark .navbar-toggler-icon,
.admin .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.bidding .navbar-dark .navbar-text,
.admin .navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.bidding .navbar-dark .navbar-text a,
.admin .navbar-dark .navbar-text a {
  color: #fff;
}
.bidding .navbar-dark .navbar-text a:focus,
.bidding .navbar-dark .navbar-text a:hover,
.admin .navbar-dark .navbar-text a:focus,
.admin .navbar-dark .navbar-text a:hover {
  color: #fff;
}
.bidding .card,
.admin .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.bidding .card > hr,
.admin .card > hr {
  margin-right: 0;
  margin-left: 0;
}
.bidding .card > .list-group:first-child .list-group-item:first-child,
.admin .card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.bidding .card > .list-group:last-child .list-group-item:last-child,
.admin .card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.bidding .card-body,
.admin .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}
.bidding .card-title,
.admin .card-title {
  margin-bottom: 0.75rem;
}
.bidding .card-subtitle,
.admin .card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.bidding .card-text:last-child,
.admin .card-text:last-child {
  margin-bottom: 0;
}
.bidding .card-link:hover,
.admin .card-link:hover {
  text-decoration: none;
}
.bidding .card-link + .card-link,
.admin .card-link + .card-link {
  margin-left: 1.25rem;
}
.bidding .card-header,
.admin .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.bidding .card-header:first-child,
.admin .card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.bidding .card-header + .list-group .list-group-item:first-child,
.admin .card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.bidding .card-footer,
.admin .card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.bidding .card-footer:last-child,
.admin .card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.bidding .card-header-tabs,
.admin .card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.bidding .card-header-pills,
.admin .card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.bidding .card-img-overlay,
.admin .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.bidding .card-img,
.admin .card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.bidding .card-img-top,
.admin .card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.bidding .card-img-bottom,
.admin .card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.bidding .card-deck,
.admin .card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.bidding .card-deck .card,
.admin .card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .bidding .card-deck,
.admin .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .bidding .card-deck .card,
.admin .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.bidding .card-group,
.admin .card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.bidding .card-group > .card,
.admin .card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .bidding .card-group,
.admin .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .bidding .card-group > .card,
.admin .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .bidding .card-group > .card + .card,
.admin .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .bidding .card-group > .card:not(:last-child),
.admin .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .bidding .card-group > .card:not(:last-child) .card-header,
.bidding .card-group > .card:not(:last-child) .card-img-top,
.admin .card-group > .card:not(:last-child) .card-header,
.admin .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .bidding .card-group > .card:not(:last-child) .card-footer,
.bidding .card-group > .card:not(:last-child) .card-img-bottom,
.admin .card-group > .card:not(:last-child) .card-footer,
.admin .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .bidding .card-group > .card:not(:first-child),
.admin .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .bidding .card-group > .card:not(:first-child) .card-header,
.bidding .card-group > .card:not(:first-child) .card-img-top,
.admin .card-group > .card:not(:first-child) .card-header,
.admin .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .bidding .card-group > .card:not(:first-child) .card-footer,
.bidding .card-group > .card:not(:first-child) .card-img-bottom,
.admin .card-group > .card:not(:first-child) .card-footer,
.admin .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.bidding .card-columns .card,
.admin .card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .bidding .card-columns,
.admin .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .bidding .card-columns .card,
.admin .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.bidding .accordion > .card,
.admin .accordion > .card {
  overflow: hidden;
}
.bidding .accordion > .card:not(:first-of-type) .card-header:first-child,
.admin .accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.bidding .accordion > .card:not(:first-of-type):not(:last-of-type),
.admin .accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.bidding .accordion > .card:first-of-type,
.admin .accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bidding .accordion > .card:last-of-type,
.admin .accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bidding .accordion > .card .card-header,
.admin .accordion > .card .card-header {
  margin-bottom: -1px;
}
.bidding .breadcrumb,
.admin .breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.bidding .breadcrumb-item + .breadcrumb-item,
.admin .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.bidding .breadcrumb-item + .breadcrumb-item::before,
.admin .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.bidding .breadcrumb-item + .breadcrumb-item:hover::before,
.admin .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.bidding .breadcrumb-item + .breadcrumb-item:hover::before,
.admin .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.bidding .breadcrumb-item.active,
.admin .breadcrumb-item.active {
  color: #6c757d;
}
.bidding .pagination,
.admin .pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.bidding .page-link,
.admin .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.bidding .page-link:hover,
.admin .page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.bidding .page-link:focus,
.admin .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.bidding .page-item:first-child .page-link,
.admin .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.bidding .page-item:last-child .page-link,
.admin .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.bidding .page-item.active .page-link,
.admin .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bidding .page-item.disabled .page-link,
.admin .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.bidding .pagination-lg .page-link,
.admin .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.bidding .pagination-lg .page-item:first-child .page-link,
.admin .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.bidding .pagination-lg .page-item:last-child .page-link,
.admin .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.bidding .pagination-sm .page-link,
.admin .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.bidding .pagination-sm .page-item:first-child .page-link,
.admin .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.bidding .pagination-sm .page-item:last-child .page-link,
.admin .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.bidding .badge,
.admin .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .badge,
.admin .badge {
    transition: none;
  }
}
.bidding a.badge:focus,
.bidding a.badge:hover,
.admin a.badge:focus,
.admin a.badge:hover {
  text-decoration: none;
}
.bidding .badge:empty,
.admin .badge:empty {
  display: none;
}
.bidding .btn .badge,
.admin .btn .badge {
  position: relative;
  top: -1px;
}
.bidding .badge-pill,
.admin .badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.bidding .badge-primary,
.admin .badge-primary {
  color: #fff;
  background-color: #007bff;
}
.bidding a.badge-primary:focus,
.bidding a.badge-primary:hover,
.admin a.badge-primary:focus,
.admin a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}
.bidding a.badge-primary.focus,
.bidding a.badge-primary:focus,
.admin a.badge-primary.focus,
.admin a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.bidding .badge-secondary,
.admin .badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.bidding a.badge-secondary:focus,
.bidding a.badge-secondary:hover,
.admin a.badge-secondary:focus,
.admin a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}
.bidding a.badge-secondary.focus,
.bidding a.badge-secondary:focus,
.admin a.badge-secondary.focus,
.admin a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.bidding .badge-success,
.admin .badge-success {
  color: #fff;
  background-color: #28a745;
}
.bidding a.badge-success:focus,
.bidding a.badge-success:hover,
.admin a.badge-success:focus,
.admin a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}
.bidding a.badge-success.focus,
.bidding a.badge-success:focus,
.admin a.badge-success.focus,
.admin a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.bidding .badge-info,
.admin .badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.bidding a.badge-info:focus,
.bidding a.badge-info:hover,
.admin a.badge-info:focus,
.admin a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}
.bidding a.badge-info.focus,
.bidding a.badge-info:focus,
.admin a.badge-info.focus,
.admin a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.bidding .badge-warning,
.admin .badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.bidding a.badge-warning:focus,
.bidding a.badge-warning:hover,
.admin a.badge-warning:focus,
.admin a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}
.bidding a.badge-warning.focus,
.bidding a.badge-warning:focus,
.admin a.badge-warning.focus,
.admin a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.bidding .badge-danger,
.admin .badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.bidding a.badge-danger:focus,
.bidding a.badge-danger:hover,
.admin a.badge-danger:focus,
.admin a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}
.bidding a.badge-danger.focus,
.bidding a.badge-danger:focus,
.admin a.badge-danger.focus,
.admin a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.bidding .badge-light,
.admin .badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.bidding a.badge-light:focus,
.bidding a.badge-light:hover,
.admin a.badge-light:focus,
.admin a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}
.bidding a.badge-light.focus,
.bidding a.badge-light:focus,
.admin a.badge-light.focus,
.admin a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.bidding .badge-dark,
.admin .badge-dark {
  color: #fff;
  background-color: #343a40;
}
.bidding a.badge-dark:focus,
.bidding a.badge-dark:hover,
.admin a.badge-dark:focus,
.admin a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}
.bidding a.badge-dark.focus,
.bidding a.badge-dark:focus,
.admin a.badge-dark.focus,
.admin a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.bidding .jumbotron,
.admin .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .bidding .jumbotron,
.admin .jumbotron {
    padding: 4rem 2rem;
  }
}
.bidding .jumbotron-fluid,
.admin .jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.bidding .alert,
.admin .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.bidding .alert-heading,
.admin .alert-heading {
  color: inherit;
}
.bidding .alert-link,
.admin .alert-link {
  font-weight: 700;
}
.bidding .alert-dismissible,
.admin .alert-dismissible {
  padding-right: 4rem;
}
.bidding .alert-dismissible .close,
.admin .alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.bidding .alert-primary,
.admin .alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.bidding .alert-primary hr,
.admin .alert-primary hr {
  border-top-color: #9fcdff;
}
.bidding .alert-primary .alert-link,
.admin .alert-primary .alert-link {
  color: #002752;
}
.bidding .alert-secondary,
.admin .alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.bidding .alert-secondary hr,
.admin .alert-secondary hr {
  border-top-color: #c8cbcf;
}
.bidding .alert-secondary .alert-link,
.admin .alert-secondary .alert-link {
  color: #202326;
}
.bidding .alert-success,
.admin .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.bidding .alert-success hr,
.admin .alert-success hr {
  border-top-color: #b1dfbb;
}
.bidding .alert-success .alert-link,
.admin .alert-success .alert-link {
  color: #0b2e13;
}
.bidding .alert-info,
.admin .alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.bidding .alert-info hr,
.admin .alert-info hr {
  border-top-color: #abdde5;
}
.bidding .alert-info .alert-link,
.admin .alert-info .alert-link {
  color: #062c33;
}
.bidding .alert-warning,
.admin .alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.bidding .alert-warning hr,
.admin .alert-warning hr {
  border-top-color: #ffe8a1;
}
.bidding .alert-warning .alert-link,
.admin .alert-warning .alert-link {
  color: #533f03;
}
.bidding .alert-danger,
.admin .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.bidding .alert-danger hr,
.admin .alert-danger hr {
  border-top-color: #f1b0b7;
}
.bidding .alert-danger .alert-link,
.admin .alert-danger .alert-link {
  color: #491217;
}
.bidding .alert-light,
.admin .alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.bidding .alert-light hr,
.admin .alert-light hr {
  border-top-color: #ececf6;
}
.bidding .alert-light .alert-link,
.admin .alert-light .alert-link {
  color: #686868;
}
.bidding .alert-dark,
.admin .alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.bidding .alert-dark hr,
.admin .alert-dark hr {
  border-top-color: #b9bbbe;
}
.bidding .alert-dark .alert-link,
.admin .alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.bidding .progress,
.admin .progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.bidding .progress-bar,
.admin .progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .progress-bar,
.admin .progress-bar {
    transition: none;
  }
}
.bidding .progress-bar-striped,
.admin .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.bidding .progress-bar-animated,
.admin .progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .progress-bar-animated,
.admin .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.bidding .media,
.admin .media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.bidding .media-body,
.admin .media-body {
  -ms-flex: 1;
  flex: 1;
}
.bidding .list-group,
.admin .list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.bidding .list-group-item-action,
.admin .list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.bidding .list-group-item-action:focus,
.bidding .list-group-item-action:hover,
.admin .list-group-item-action:focus,
.admin .list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.bidding .list-group-item-action:active,
.admin .list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.bidding .list-group-item,
.admin .list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.bidding .list-group-item:first-child,
.admin .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.bidding .list-group-item:last-child,
.admin .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.bidding .list-group-item.disabled,
.bidding .list-group-item:disabled,
.admin .list-group-item.disabled,
.admin .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.bidding .list-group-item.active,
.admin .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.bidding .list-group-horizontal,
.admin .list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}
.bidding .list-group-horizontal .list-group-item,
.admin .list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.bidding .list-group-horizontal .list-group-item:first-child,
.admin .list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.bidding .list-group-horizontal .list-group-item:last-child,
.admin .list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
@media (min-width: 576px) {
  .bidding .list-group-horizontal-sm,
.admin .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .bidding .list-group-horizontal-sm .list-group-item,
.admin .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .bidding .list-group-horizontal-sm .list-group-item:first-child,
.admin .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bidding .list-group-horizontal-sm .list-group-item:last-child,
.admin .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .bidding .list-group-horizontal-md,
.admin .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .bidding .list-group-horizontal-md .list-group-item,
.admin .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .bidding .list-group-horizontal-md .list-group-item:first-child,
.admin .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bidding .list-group-horizontal-md .list-group-item:last-child,
.admin .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .bidding .list-group-horizontal-lg,
.admin .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .bidding .list-group-horizontal-lg .list-group-item,
.admin .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .bidding .list-group-horizontal-lg .list-group-item:first-child,
.admin .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bidding .list-group-horizontal-lg .list-group-item:last-child,
.admin .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .bidding .list-group-horizontal-xl,
.admin .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .bidding .list-group-horizontal-xl .list-group-item,
.admin .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .bidding .list-group-horizontal-xl .list-group-item:first-child,
.admin .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bidding .list-group-horizontal-xl .list-group-item:last-child,
.admin .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.bidding .list-group-flush .list-group-item,
.admin .list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.bidding .list-group-flush .list-group-item:last-child,
.admin .list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.bidding .list-group-flush:first-child .list-group-item:first-child,
.admin .list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.bidding .list-group-flush:last-child .list-group-item:last-child,
.admin .list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.bidding .list-group-item-primary,
.admin .list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.bidding .list-group-item-primary.list-group-item-action:focus,
.bidding .list-group-item-primary.list-group-item-action:hover,
.admin .list-group-item-primary.list-group-item-action:focus,
.admin .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}
.bidding .list-group-item-primary.list-group-item-action.active,
.admin .list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.bidding .list-group-item-secondary,
.admin .list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.bidding .list-group-item-secondary.list-group-item-action:focus,
.bidding .list-group-item-secondary.list-group-item-action:hover,
.admin .list-group-item-secondary.list-group-item-action:focus,
.admin .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.bidding .list-group-item-secondary.list-group-item-action.active,
.admin .list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.bidding .list-group-item-success,
.admin .list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.bidding .list-group-item-success.list-group-item-action:focus,
.bidding .list-group-item-success.list-group-item-action:hover,
.admin .list-group-item-success.list-group-item-action:focus,
.admin .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.bidding .list-group-item-success.list-group-item-action.active,
.admin .list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.bidding .list-group-item-info,
.admin .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.bidding .list-group-item-info.list-group-item-action:focus,
.bidding .list-group-item-info.list-group-item-action:hover,
.admin .list-group-item-info.list-group-item-action:focus,
.admin .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.bidding .list-group-item-info.list-group-item-action.active,
.admin .list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.bidding .list-group-item-warning,
.admin .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.bidding .list-group-item-warning.list-group-item-action:focus,
.bidding .list-group-item-warning.list-group-item-action:hover,
.admin .list-group-item-warning.list-group-item-action:focus,
.admin .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.bidding .list-group-item-warning.list-group-item-action.active,
.admin .list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.bidding .list-group-item-danger,
.admin .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.bidding .list-group-item-danger.list-group-item-action:focus,
.bidding .list-group-item-danger.list-group-item-action:hover,
.admin .list-group-item-danger.list-group-item-action:focus,
.admin .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.bidding .list-group-item-danger.list-group-item-action.active,
.admin .list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.bidding .list-group-item-light,
.admin .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.bidding .list-group-item-light.list-group-item-action:focus,
.bidding .list-group-item-light.list-group-item-action:hover,
.admin .list-group-item-light.list-group-item-action:focus,
.admin .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.bidding .list-group-item-light.list-group-item-action.active,
.admin .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.bidding .list-group-item-dark,
.admin .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.bidding .list-group-item-dark.list-group-item-action:focus,
.bidding .list-group-item-dark.list-group-item-action:hover,
.admin .list-group-item-dark.list-group-item-action:focus,
.admin .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.bidding .list-group-item-dark.list-group-item-action.active,
.admin .list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.bidding .close,
.admin .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.bidding .close:hover,
.admin .close:hover {
  color: #000;
  text-decoration: none;
}
.bidding .close:not(:disabled):not(.disabled):focus,
.bidding .close:not(:disabled):not(.disabled):hover,
.admin .close:not(:disabled):not(.disabled):focus,
.admin .close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
.bidding button.close,
.admin button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.bidding a.close.disabled,
.admin a.close.disabled {
  pointer-events: none;
}
.bidding .toast,
.admin .toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.bidding .toast:not(:last-child),
.admin .toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.bidding .toast.showing,
.admin .toast.showing {
  opacity: 1;
}
.bidding .toast.show,
.admin .toast.show {
  display: block;
  opacity: 1;
}
.bidding .toast.hide,
.admin .toast.hide {
  display: none;
}
.bidding .toast-header,
.admin .toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.bidding .toast-body,
.admin .toast-body {
  padding: 0.75rem;
}
.bidding .modal-open,
.admin .modal-open {
  overflow: hidden;
}
.bidding .modal-open .modal,
.admin .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.bidding .modal,
.admin .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.bidding .modal-dialog,
.admin .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.bidding .modal.fade .modal-dialog,
.admin .modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .bidding .modal.fade .modal-dialog,
.admin .modal.fade .modal-dialog {
    transition: none;
  }
}
.bidding .modal.show .modal-dialog,
.admin .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.bidding .modal-dialog-scrollable,
.admin .modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.bidding .modal-dialog-scrollable .modal-content,
.admin .modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.bidding .modal-dialog-scrollable .modal-footer,
.bidding .modal-dialog-scrollable .modal-header,
.admin .modal-dialog-scrollable .modal-footer,
.admin .modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.bidding .modal-dialog-scrollable .modal-body,
.admin .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.bidding .modal-dialog-centered,
.admin .modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.bidding .modal-dialog-centered::before,
.admin .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.bidding .modal-dialog-centered.modal-dialog-scrollable,
.admin .modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.bidding .modal-dialog-centered.modal-dialog-scrollable .modal-content,
.admin .modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.bidding .modal-dialog-centered.modal-dialog-scrollable::before,
.admin .modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.bidding .modal-content,
.admin .modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.bidding .modal-backdrop,
.admin .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.bidding .modal-backdrop.fade,
.admin .modal-backdrop.fade {
  opacity: 0;
}
.bidding .modal-backdrop.show,
.admin .modal-backdrop.show {
  opacity: 0.5;
}
.bidding .modal-header,
.admin .modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.bidding .modal-header .close,
.admin .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.bidding .modal-title,
.admin .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.bidding .modal-body,
.admin .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.bidding .modal-footer,
.admin .modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.bidding .modal-footer > :not(:first-child),
.admin .modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.bidding .modal-footer > :not(:last-child),
.admin .modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.bidding .modal-scrollbar-measure,
.admin .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .bidding .modal-dialog,
.admin .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .bidding .modal-dialog-scrollable,
.admin .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .bidding .modal-dialog-scrollable .modal-content,
.admin .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .bidding .modal-dialog-centered,
.admin .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .bidding .modal-dialog-centered::before,
.admin .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .bidding .modal-sm,
.admin .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .bidding .modal-lg,
.bidding .modal-xl,
.admin .modal-lg,
.admin .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .bidding .modal-xl,
.admin .modal-xl {
    max-width: 1140px;
  }
}
.bidding .tooltip.show,
.admin .tooltip.show {
  opacity: 0.9;
}
.bidding .tooltip .arrow,
.admin .tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.bidding .tooltip .arrow::before,
.admin .tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bidding .bs-tooltip-auto[x-placement^=top],
.bidding .bs-tooltip-top,
.admin .bs-tooltip-auto[x-placement^=top],
.admin .bs-tooltip-top {
  padding: 0.4rem 0;
}
.bidding .bs-tooltip-auto[x-placement^=top] .arrow,
.bidding .bs-tooltip-top .arrow,
.admin .bs-tooltip-auto[x-placement^=top] .arrow,
.admin .bs-tooltip-top .arrow {
  bottom: 0;
}
.bidding .bs-tooltip-auto[x-placement^=top] .arrow::before,
.bidding .bs-tooltip-top .arrow::before,
.admin .bs-tooltip-auto[x-placement^=top] .arrow::before,
.admin .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bidding .bs-tooltip-auto[x-placement^=right],
.bidding .bs-tooltip-right,
.admin .bs-tooltip-auto[x-placement^=right],
.admin .bs-tooltip-right {
  padding: 0 0.4rem;
}
.bidding .bs-tooltip-auto[x-placement^=right] .arrow,
.bidding .bs-tooltip-right .arrow,
.admin .bs-tooltip-auto[x-placement^=right] .arrow,
.admin .bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bidding .bs-tooltip-auto[x-placement^=right] .arrow::before,
.bidding .bs-tooltip-right .arrow::before,
.admin .bs-tooltip-auto[x-placement^=right] .arrow::before,
.admin .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bidding .bs-tooltip-auto[x-placement^=bottom],
.bidding .bs-tooltip-bottom,
.admin .bs-tooltip-auto[x-placement^=bottom],
.admin .bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bidding .bs-tooltip-auto[x-placement^=bottom] .arrow,
.bidding .bs-tooltip-bottom .arrow,
.admin .bs-tooltip-auto[x-placement^=bottom] .arrow,
.admin .bs-tooltip-bottom .arrow {
  top: 0;
}
.bidding .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bidding .bs-tooltip-bottom .arrow::before,
.admin .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.admin .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bidding .bs-tooltip-auto[x-placement^=left],
.bidding .bs-tooltip-left,
.admin .bs-tooltip-auto[x-placement^=left],
.admin .bs-tooltip-left {
  padding: 0 0.4rem;
}
.bidding .bs-tooltip-auto[x-placement^=left] .arrow,
.bidding .bs-tooltip-left .arrow,
.admin .bs-tooltip-auto[x-placement^=left] .arrow,
.admin .bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bidding .bs-tooltip-auto[x-placement^=left] .arrow::before,
.bidding .bs-tooltip-left .arrow::before,
.admin .bs-tooltip-auto[x-placement^=left] .arrow::before,
.admin .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.bidding .tooltip-inner,
.admin .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.bidding .popover .arrow,
.admin .popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.bidding .popover .arrow::after,
.bidding .popover .arrow::before,
.admin .popover .arrow::after,
.admin .popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bidding .bs-popover-auto[x-placement^=top],
.bidding .bs-popover-top,
.admin .bs-popover-auto[x-placement^=top],
.admin .bs-popover-top {
  margin-bottom: 0.5rem;
}
.bidding .bs-popover-auto[x-placement^=top] > .arrow,
.bidding .bs-popover-top > .arrow,
.admin .bs-popover-auto[x-placement^=top] > .arrow,
.admin .bs-popover-top > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bidding .bs-popover-auto[x-placement^=top] > .arrow::before,
.bidding .bs-popover-top > .arrow::before,
.admin .bs-popover-auto[x-placement^=top] > .arrow::before,
.admin .bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bidding .bs-popover-auto[x-placement^=top] > .arrow::after,
.bidding .bs-popover-top > .arrow::after,
.admin .bs-popover-auto[x-placement^=top] > .arrow::after,
.admin .bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bidding .bs-popover-auto[x-placement^=right],
.bidding .bs-popover-right,
.admin .bs-popover-auto[x-placement^=right],
.admin .bs-popover-right {
  margin-left: 0.5rem;
}
.bidding .bs-popover-auto[x-placement^=right] > .arrow,
.bidding .bs-popover-right > .arrow,
.admin .bs-popover-auto[x-placement^=right] > .arrow,
.admin .bs-popover-right > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bidding .bs-popover-auto[x-placement^=right] > .arrow::before,
.bidding .bs-popover-right > .arrow::before,
.admin .bs-popover-auto[x-placement^=right] > .arrow::before,
.admin .bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bidding .bs-popover-auto[x-placement^=right] > .arrow::after,
.bidding .bs-popover-right > .arrow::after,
.admin .bs-popover-auto[x-placement^=right] > .arrow::after,
.admin .bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bidding .bs-popover-auto[x-placement^=bottom],
.bidding .bs-popover-bottom,
.admin .bs-popover-auto[x-placement^=bottom],
.admin .bs-popover-bottom {
  margin-top: 0.5rem;
}
.bidding .bs-popover-auto[x-placement^=bottom] > .arrow,
.bidding .bs-popover-bottom > .arrow,
.admin .bs-popover-auto[x-placement^=bottom] > .arrow,
.admin .bs-popover-bottom > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bidding .bs-popover-auto[x-placement^=bottom] > .arrow::before,
.bidding .bs-popover-bottom > .arrow::before,
.admin .bs-popover-auto[x-placement^=bottom] > .arrow::before,
.admin .bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bidding .bs-popover-auto[x-placement^=bottom] > .arrow::after,
.bidding .bs-popover-bottom > .arrow::after,
.admin .bs-popover-auto[x-placement^=bottom] > .arrow::after,
.admin .bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bidding .bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bidding .bs-popover-bottom .popover-header::before,
.admin .bs-popover-auto[x-placement^=bottom] .popover-header::before,
.admin .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bidding .bs-popover-auto[x-placement^=left],
.bidding .bs-popover-left,
.admin .bs-popover-auto[x-placement^=left],
.admin .bs-popover-left {
  margin-right: 0.5rem;
}
.bidding .bs-popover-auto[x-placement^=left] > .arrow,
.bidding .bs-popover-left > .arrow,
.admin .bs-popover-auto[x-placement^=left] > .arrow,
.admin .bs-popover-left > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bidding .bs-popover-auto[x-placement^=left] > .arrow::before,
.bidding .bs-popover-left > .arrow::before,
.admin .bs-popover-auto[x-placement^=left] > .arrow::before,
.admin .bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bidding .bs-popover-auto[x-placement^=left] > .arrow::after,
.bidding .bs-popover-left > .arrow::after,
.admin .bs-popover-auto[x-placement^=left] > .arrow::after,
.admin .bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.bidding .popover-header,
.admin .popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.bidding .popover-header:empty,
.admin .popover-header:empty {
  display: none;
}
.bidding .popover-body,
.admin .popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.bidding .carousel,
.admin .carousel {
  position: relative;
}
.bidding .carousel.pointer-event,
.admin .carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.bidding .carousel-inner,
.admin .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.bidding .carousel-inner::after,
.admin .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.bidding .carousel-item,
.admin .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .carousel-item,
.admin .carousel-item {
    transition: none;
  }
}
.bidding .carousel-item-next,
.bidding .carousel-item-prev,
.bidding .carousel-item.active,
.admin .carousel-item-next,
.admin .carousel-item-prev,
.admin .carousel-item.active {
  display: block;
}
.bidding .active.carousel-item-right,
.bidding .carousel-item-next:not(.carousel-item-left),
.admin .active.carousel-item-right,
.admin .carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.bidding .active.carousel-item-left,
.bidding .carousel-item-prev:not(.carousel-item-right),
.admin .active.carousel-item-left,
.admin .carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.bidding .carousel-fade .carousel-item,
.admin .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.bidding .carousel-fade .carousel-item-next.carousel-item-left,
.bidding .carousel-fade .carousel-item-prev.carousel-item-right,
.bidding .carousel-fade .carousel-item.active,
.admin .carousel-fade .carousel-item-next.carousel-item-left,
.admin .carousel-fade .carousel-item-prev.carousel-item-right,
.admin .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.bidding .carousel-fade .active.carousel-item-left,
.bidding .carousel-fade .active.carousel-item-right,
.admin .carousel-fade .active.carousel-item-left,
.admin .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .carousel-fade .active.carousel-item-left,
.bidding .carousel-fade .active.carousel-item-right,
.admin .carousel-fade .active.carousel-item-left,
.admin .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.bidding .carousel-control-next,
.bidding .carousel-control-prev,
.admin .carousel-control-next,
.admin .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .carousel-control-next,
.bidding .carousel-control-prev,
.admin .carousel-control-next,
.admin .carousel-control-prev {
    transition: none;
  }
}
.bidding .carousel-control-next:focus,
.bidding .carousel-control-next:hover,
.bidding .carousel-control-prev:focus,
.bidding .carousel-control-prev:hover,
.admin .carousel-control-next:focus,
.admin .carousel-control-next:hover,
.admin .carousel-control-prev:focus,
.admin .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.bidding .carousel-control-prev,
.admin .carousel-control-prev {
  left: 0;
}
.bidding .carousel-control-next,
.admin .carousel-control-next {
  right: 0;
}
.bidding .carousel-control-next-icon,
.bidding .carousel-control-prev-icon,
.admin .carousel-control-next-icon,
.admin .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.bidding .carousel-control-prev-icon,
.admin .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}
.bidding .carousel-control-next-icon,
.admin .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}
.bidding .carousel-indicators,
.admin .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.bidding .carousel-indicators li,
.admin .carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bidding .carousel-indicators li,
.admin .carousel-indicators li {
    transition: none;
  }
}
.bidding .carousel-indicators .active,
.admin .carousel-indicators .active {
  opacity: 1;
}
.bidding .carousel-caption,
.admin .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.bidding .spinner-border,
.admin .spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.bidding .spinner-border-sm,
.admin .spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.bidding .spinner-grow,
.admin .spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}
.bidding .spinner-grow-sm,
.admin .spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.bidding .align-baseline,
.admin .align-baseline {
  vertical-align: baseline !important;
}
.bidding .align-top,
.admin .align-top {
  vertical-align: top !important;
}
.bidding .align-middle,
.admin .align-middle {
  vertical-align: middle !important;
}
.bidding .align-bottom,
.admin .align-bottom {
  vertical-align: bottom !important;
}
.bidding .align-text-bottom,
.admin .align-text-bottom {
  vertical-align: text-bottom !important;
}
.bidding .align-text-top,
.admin .align-text-top {
  vertical-align: text-top !important;
}
.bidding .bg-primary,
.admin .bg-primary {
  background-color: #007bff !important;
}
.bidding a.bg-primary:focus,
.bidding a.bg-primary:hover,
.bidding button.bg-primary:focus,
.bidding button.bg-primary:hover,
.admin a.bg-primary:focus,
.admin a.bg-primary:hover,
.admin button.bg-primary:focus,
.admin button.bg-primary:hover {
  background-color: #0062cc !important;
}
.bidding .bg-secondary,
.admin .bg-secondary {
  background-color: #6c757d !important;
}
.bidding a.bg-secondary:focus,
.bidding a.bg-secondary:hover,
.bidding button.bg-secondary:focus,
.bidding button.bg-secondary:hover,
.admin a.bg-secondary:focus,
.admin a.bg-secondary:hover,
.admin button.bg-secondary:focus,
.admin button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bidding .bg-success,
.admin .bg-success {
  background-color: #28a745 !important;
}
.bidding a.bg-success:focus,
.bidding a.bg-success:hover,
.bidding button.bg-success:focus,
.bidding button.bg-success:hover,
.admin a.bg-success:focus,
.admin a.bg-success:hover,
.admin button.bg-success:focus,
.admin button.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bidding .bg-info,
.admin .bg-info {
  background-color: #17a2b8 !important;
}
.bidding a.bg-info:focus,
.bidding a.bg-info:hover,
.bidding button.bg-info:focus,
.bidding button.bg-info:hover,
.admin a.bg-info:focus,
.admin a.bg-info:hover,
.admin button.bg-info:focus,
.admin button.bg-info:hover {
  background-color: #117a8b !important;
}
.bidding .bg-warning,
.admin .bg-warning {
  background-color: #ffc107 !important;
}
.bidding a.bg-warning:focus,
.bidding a.bg-warning:hover,
.bidding button.bg-warning:focus,
.bidding button.bg-warning:hover,
.admin a.bg-warning:focus,
.admin a.bg-warning:hover,
.admin button.bg-warning:focus,
.admin button.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bidding .bg-danger,
.admin .bg-danger {
  background-color: #dc3545 !important;
}
.bidding a.bg-danger:focus,
.bidding a.bg-danger:hover,
.bidding button.bg-danger:focus,
.bidding button.bg-danger:hover,
.admin a.bg-danger:focus,
.admin a.bg-danger:hover,
.admin button.bg-danger:focus,
.admin button.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bidding .bg-light,
.admin .bg-light {
  background-color: #f8f9fa !important;
}
.bidding a.bg-light:focus,
.bidding a.bg-light:hover,
.bidding button.bg-light:focus,
.bidding button.bg-light:hover,
.admin a.bg-light:focus,
.admin a.bg-light:hover,
.admin button.bg-light:focus,
.admin button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bidding .bg-dark,
.admin .bg-dark {
  background-color: #343a40 !important;
}
.bidding a.bg-dark:focus,
.bidding a.bg-dark:hover,
.bidding button.bg-dark:focus,
.bidding button.bg-dark:hover,
.admin a.bg-dark:focus,
.admin a.bg-dark:hover,
.admin button.bg-dark:focus,
.admin button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bidding .bg-white,
.admin .bg-white {
  background-color: #fff !important;
}
.bidding .bg-transparent,
.admin .bg-transparent {
  background-color: transparent !important;
}
.bidding .border,
.admin .border {
  border: 1px solid #dee2e6 !important;
}
.bidding .border-top,
.admin .border-top {
  border-top: 1px solid #dee2e6 !important;
}
.bidding .border-right,
.admin .border-right {
  border-right: 1px solid #dee2e6 !important;
}
.bidding .border-bottom,
.admin .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.bidding .border-left,
.admin .border-left {
  border-left: 1px solid #dee2e6 !important;
}
.bidding .border-0,
.admin .border-0 {
  border: 0 !important;
}
.bidding .border-top-0,
.admin .border-top-0 {
  border-top: 0 !important;
}
.bidding .border-right-0,
.admin .border-right-0 {
  border-right: 0 !important;
}
.bidding .border-bottom-0,
.admin .border-bottom-0 {
  border-bottom: 0 !important;
}
.bidding .border-left-0,
.admin .border-left-0 {
  border-left: 0 !important;
}
.bidding .border-primary,
.admin .border-primary {
  border-color: #007bff !important;
}
.bidding .border-secondary,
.admin .border-secondary {
  border-color: #6c757d !important;
}
.bidding .border-success,
.admin .border-success {
  border-color: #28a745 !important;
}
.bidding .border-info,
.admin .border-info {
  border-color: #17a2b8 !important;
}
.bidding .border-warning,
.admin .border-warning {
  border-color: #ffc107 !important;
}
.bidding .border-danger,
.admin .border-danger {
  border-color: #dc3545 !important;
}
.bidding .border-light,
.admin .border-light {
  border-color: #f8f9fa !important;
}
.bidding .border-dark,
.admin .border-dark {
  border-color: #343a40 !important;
}
.bidding .border-white,
.admin .border-white {
  border-color: #fff !important;
}
.bidding .rounded-sm,
.admin .rounded-sm {
  border-radius: 0.2rem !important;
}
.bidding .rounded,
.admin .rounded {
  border-radius: 0.25rem !important;
}
.bidding .rounded-top,
.admin .rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.bidding .rounded-right,
.admin .rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.bidding .rounded-bottom,
.admin .rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.bidding .rounded-left,
.admin .rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.bidding .rounded-lg,
.admin .rounded-lg {
  border-radius: 0.3rem !important;
}
.bidding .rounded-circle,
.admin .rounded-circle {
  border-radius: 50% !important;
}
.bidding .rounded-pill,
.admin .rounded-pill {
  border-radius: 50rem !important;
}
.bidding .rounded-0,
.admin .rounded-0 {
  border-radius: 0 !important;
}
.bidding .clearfix::after,
.admin .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.bidding .d-none,
.admin .d-none {
  display: none !important;
}
.bidding .d-inline,
.admin .d-inline {
  display: inline !important;
}
.bidding .d-inline-block,
.admin .d-inline-block {
  display: inline-block !important;
}
.bidding .d-block,
.admin .d-block {
  display: block !important;
}
.bidding .d-table,
.admin .d-table {
  display: table !important;
}
.bidding .d-table-row,
.admin .d-table-row {
  display: table-row !important;
}
.bidding .d-table-cell,
.admin .d-table-cell {
  display: table-cell !important;
}
.bidding .d-flex,
.admin .d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.bidding .d-inline-flex,
.admin .d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .bidding .d-sm-none,
.admin .d-sm-none {
    display: none !important;
  }
  .bidding .d-sm-inline,
.admin .d-sm-inline {
    display: inline !important;
  }
  .bidding .d-sm-inline-block,
.admin .d-sm-inline-block {
    display: inline-block !important;
  }
  .bidding .d-sm-block,
.admin .d-sm-block {
    display: block !important;
  }
  .bidding .d-sm-table,
.admin .d-sm-table {
    display: table !important;
  }
  .bidding .d-sm-table-row,
.admin .d-sm-table-row {
    display: table-row !important;
  }
  .bidding .d-sm-table-cell,
.admin .d-sm-table-cell {
    display: table-cell !important;
  }
  .bidding .d-sm-flex,
.admin .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bidding .d-sm-inline-flex,
.admin .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .bidding .d-md-none,
.admin .d-md-none {
    display: none !important;
  }
  .bidding .d-md-inline,
.admin .d-md-inline {
    display: inline !important;
  }
  .bidding .d-md-inline-block,
.admin .d-md-inline-block {
    display: inline-block !important;
  }
  .bidding .d-md-block,
.admin .d-md-block {
    display: block !important;
  }
  .bidding .d-md-table,
.admin .d-md-table {
    display: table !important;
  }
  .bidding .d-md-table-row,
.admin .d-md-table-row {
    display: table-row !important;
  }
  .bidding .d-md-table-cell,
.admin .d-md-table-cell {
    display: table-cell !important;
  }
  .bidding .d-md-flex,
.admin .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bidding .d-md-inline-flex,
.admin .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .bidding .d-lg-none,
.admin .d-lg-none {
    display: none !important;
  }
  .bidding .d-lg-inline,
.admin .d-lg-inline {
    display: inline !important;
  }
  .bidding .d-lg-inline-block,
.admin .d-lg-inline-block {
    display: inline-block !important;
  }
  .bidding .d-lg-block,
.admin .d-lg-block {
    display: block !important;
  }
  .bidding .d-lg-table,
.admin .d-lg-table {
    display: table !important;
  }
  .bidding .d-lg-table-row,
.admin .d-lg-table-row {
    display: table-row !important;
  }
  .bidding .d-lg-table-cell,
.admin .d-lg-table-cell {
    display: table-cell !important;
  }
  .bidding .d-lg-flex,
.admin .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bidding .d-lg-inline-flex,
.admin .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .bidding .d-xl-none,
.admin .d-xl-none {
    display: none !important;
  }
  .bidding .d-xl-inline,
.admin .d-xl-inline {
    display: inline !important;
  }
  .bidding .d-xl-inline-block,
.admin .d-xl-inline-block {
    display: inline-block !important;
  }
  .bidding .d-xl-block,
.admin .d-xl-block {
    display: block !important;
  }
  .bidding .d-xl-table,
.admin .d-xl-table {
    display: table !important;
  }
  .bidding .d-xl-table-row,
.admin .d-xl-table-row {
    display: table-row !important;
  }
  .bidding .d-xl-table-cell,
.admin .d-xl-table-cell {
    display: table-cell !important;
  }
  .bidding .d-xl-flex,
.admin .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bidding .d-xl-inline-flex,
.admin .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .bidding .d-print-none,
.admin .d-print-none {
    display: none !important;
  }
  .bidding .d-print-inline,
.admin .d-print-inline {
    display: inline !important;
  }
  .bidding .d-print-inline-block,
.admin .d-print-inline-block {
    display: inline-block !important;
  }
  .bidding .d-print-block,
.admin .d-print-block {
    display: block !important;
  }
  .bidding .d-print-table,
.admin .d-print-table {
    display: table !important;
  }
  .bidding .d-print-table-row,
.admin .d-print-table-row {
    display: table-row !important;
  }
  .bidding .d-print-table-cell,
.admin .d-print-table-cell {
    display: table-cell !important;
  }
  .bidding .d-print-flex,
.admin .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bidding .d-print-inline-flex,
.admin .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.bidding .embed-responsive,
.admin .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.bidding .embed-responsive::before,
.admin .embed-responsive::before {
  display: block;
  content: "";
}
.bidding .embed-responsive .embed-responsive-item,
.bidding .embed-responsive embed,
.bidding .embed-responsive iframe,
.bidding .embed-responsive object,
.bidding .embed-responsive video,
.admin .embed-responsive .embed-responsive-item,
.admin .embed-responsive embed,
.admin .embed-responsive iframe,
.admin .embed-responsive object,
.admin .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.bidding .embed-responsive-21by9::before,
.admin .embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.bidding .embed-responsive-16by9::before,
.admin .embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.bidding .embed-responsive-4by3::before,
.admin .embed-responsive-4by3::before {
  padding-top: 75%;
}
.bidding .embed-responsive-1by1::before,
.admin .embed-responsive-1by1::before {
  padding-top: 100%;
}
.bidding .flex-row,
.admin .flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.bidding .flex-column,
.admin .flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.bidding .flex-row-reverse,
.admin .flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.bidding .flex-column-reverse,
.admin .flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.bidding .flex-wrap,
.admin .flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.bidding .flex-nowrap,
.admin .flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.bidding .flex-wrap-reverse,
.admin .flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.bidding .flex-fill,
.admin .flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.bidding .flex-grow-0,
.admin .flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.bidding .flex-grow-1,
.admin .flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.bidding .flex-shrink-0,
.admin .flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.bidding .flex-shrink-1,
.admin .flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
.bidding .justify-content-start,
.admin .justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.bidding .justify-content-end,
.admin .justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.bidding .justify-content-center,
.admin .justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.bidding .justify-content-between,
.admin .justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.bidding .justify-content-around,
.admin .justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.bidding .align-items-start,
.admin .align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.bidding .align-items-end,
.admin .align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.bidding .align-items-center,
.admin .align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.bidding .align-items-baseline,
.admin .align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.bidding .align-items-stretch,
.admin .align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.bidding .align-content-start,
.admin .align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.bidding .align-content-end,
.admin .align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.bidding .align-content-center,
.admin .align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.bidding .align-content-between,
.admin .align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.bidding .align-content-around,
.admin .align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.bidding .align-content-stretch,
.admin .align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.bidding .align-self-auto,
.admin .align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.bidding .align-self-start,
.admin .align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.bidding .align-self-end,
.admin .align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.bidding .align-self-center,
.admin .align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.bidding .align-self-baseline,
.admin .align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.bidding .align-self-stretch,
.admin .align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .bidding .flex-sm-row,
.admin .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .bidding .flex-sm-column,
.admin .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .bidding .flex-sm-row-reverse,
.admin .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .bidding .flex-sm-column-reverse,
.admin .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .bidding .flex-sm-wrap,
.admin .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .bidding .flex-sm-nowrap,
.admin .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .bidding .flex-sm-wrap-reverse,
.admin .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .bidding .flex-sm-fill,
.admin .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .bidding .flex-sm-grow-0,
.admin .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .bidding .flex-sm-grow-1,
.admin .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .bidding .flex-sm-shrink-0,
.admin .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .bidding .flex-sm-shrink-1,
.admin .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .bidding .justify-content-sm-start,
.admin .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .bidding .justify-content-sm-end,
.admin .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .bidding .justify-content-sm-center,
.admin .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .bidding .justify-content-sm-between,
.admin .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .bidding .justify-content-sm-around,
.admin .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .bidding .align-items-sm-start,
.admin .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .bidding .align-items-sm-end,
.admin .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .bidding .align-items-sm-center,
.admin .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .bidding .align-items-sm-baseline,
.admin .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .bidding .align-items-sm-stretch,
.admin .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .bidding .align-content-sm-start,
.admin .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .bidding .align-content-sm-end,
.admin .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .bidding .align-content-sm-center,
.admin .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .bidding .align-content-sm-between,
.admin .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .bidding .align-content-sm-around,
.admin .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .bidding .align-content-sm-stretch,
.admin .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .bidding .align-self-sm-auto,
.admin .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .bidding .align-self-sm-start,
.admin .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .bidding .align-self-sm-end,
.admin .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .bidding .align-self-sm-center,
.admin .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .bidding .align-self-sm-baseline,
.admin .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .bidding .align-self-sm-stretch,
.admin .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .bidding .flex-md-row,
.admin .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .bidding .flex-md-column,
.admin .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .bidding .flex-md-row-reverse,
.admin .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .bidding .flex-md-column-reverse,
.admin .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .bidding .flex-md-wrap,
.admin .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .bidding .flex-md-nowrap,
.admin .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .bidding .flex-md-wrap-reverse,
.admin .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .bidding .flex-md-fill,
.admin .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .bidding .flex-md-grow-0,
.admin .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .bidding .flex-md-grow-1,
.admin .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .bidding .flex-md-shrink-0,
.admin .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .bidding .flex-md-shrink-1,
.admin .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .bidding .justify-content-md-start,
.admin .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .bidding .justify-content-md-end,
.admin .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .bidding .justify-content-md-center,
.admin .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .bidding .justify-content-md-between,
.admin .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .bidding .justify-content-md-around,
.admin .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .bidding .align-items-md-start,
.admin .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .bidding .align-items-md-end,
.admin .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .bidding .align-items-md-center,
.admin .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .bidding .align-items-md-baseline,
.admin .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .bidding .align-items-md-stretch,
.admin .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .bidding .align-content-md-start,
.admin .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .bidding .align-content-md-end,
.admin .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .bidding .align-content-md-center,
.admin .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .bidding .align-content-md-between,
.admin .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .bidding .align-content-md-around,
.admin .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .bidding .align-content-md-stretch,
.admin .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .bidding .align-self-md-auto,
.admin .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .bidding .align-self-md-start,
.admin .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .bidding .align-self-md-end,
.admin .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .bidding .align-self-md-center,
.admin .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .bidding .align-self-md-baseline,
.admin .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .bidding .align-self-md-stretch,
.admin .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .bidding .flex-lg-row,
.admin .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .bidding .flex-lg-column,
.admin .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .bidding .flex-lg-row-reverse,
.admin .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .bidding .flex-lg-column-reverse,
.admin .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .bidding .flex-lg-wrap,
.admin .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .bidding .flex-lg-nowrap,
.admin .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .bidding .flex-lg-wrap-reverse,
.admin .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .bidding .flex-lg-fill,
.admin .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .bidding .flex-lg-grow-0,
.admin .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .bidding .flex-lg-grow-1,
.admin .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .bidding .flex-lg-shrink-0,
.admin .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .bidding .flex-lg-shrink-1,
.admin .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .bidding .justify-content-lg-start,
.admin .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .bidding .justify-content-lg-end,
.admin .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .bidding .justify-content-lg-center,
.admin .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .bidding .justify-content-lg-between,
.admin .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .bidding .justify-content-lg-around,
.admin .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .bidding .align-items-lg-start,
.admin .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .bidding .align-items-lg-end,
.admin .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .bidding .align-items-lg-center,
.admin .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .bidding .align-items-lg-baseline,
.admin .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .bidding .align-items-lg-stretch,
.admin .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .bidding .align-content-lg-start,
.admin .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .bidding .align-content-lg-end,
.admin .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .bidding .align-content-lg-center,
.admin .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .bidding .align-content-lg-between,
.admin .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .bidding .align-content-lg-around,
.admin .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .bidding .align-content-lg-stretch,
.admin .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .bidding .align-self-lg-auto,
.admin .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .bidding .align-self-lg-start,
.admin .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .bidding .align-self-lg-end,
.admin .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .bidding .align-self-lg-center,
.admin .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .bidding .align-self-lg-baseline,
.admin .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .bidding .align-self-lg-stretch,
.admin .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .bidding .flex-xl-row,
.admin .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .bidding .flex-xl-column,
.admin .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .bidding .flex-xl-row-reverse,
.admin .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .bidding .flex-xl-column-reverse,
.admin .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .bidding .flex-xl-wrap,
.admin .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .bidding .flex-xl-nowrap,
.admin .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .bidding .flex-xl-wrap-reverse,
.admin .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .bidding .flex-xl-fill,
.admin .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .bidding .flex-xl-grow-0,
.admin .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .bidding .flex-xl-grow-1,
.admin .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .bidding .flex-xl-shrink-0,
.admin .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .bidding .flex-xl-shrink-1,
.admin .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .bidding .justify-content-xl-start,
.admin .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .bidding .justify-content-xl-end,
.admin .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .bidding .justify-content-xl-center,
.admin .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .bidding .justify-content-xl-between,
.admin .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .bidding .justify-content-xl-around,
.admin .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .bidding .align-items-xl-start,
.admin .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .bidding .align-items-xl-end,
.admin .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .bidding .align-items-xl-center,
.admin .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .bidding .align-items-xl-baseline,
.admin .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .bidding .align-items-xl-stretch,
.admin .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .bidding .align-content-xl-start,
.admin .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .bidding .align-content-xl-end,
.admin .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .bidding .align-content-xl-center,
.admin .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .bidding .align-content-xl-between,
.admin .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .bidding .align-content-xl-around,
.admin .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .bidding .align-content-xl-stretch,
.admin .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .bidding .align-self-xl-auto,
.admin .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .bidding .align-self-xl-start,
.admin .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .bidding .align-self-xl-end,
.admin .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .bidding .align-self-xl-center,
.admin .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .bidding .align-self-xl-baseline,
.admin .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .bidding .align-self-xl-stretch,
.admin .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.bidding .float-left,
.admin .float-left {
  float: left !important;
}
.bidding .float-right,
.admin .float-right {
  float: right !important;
}
.bidding .float-none,
.admin .float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .bidding .float-sm-left,
.admin .float-sm-left {
    float: left !important;
  }
  .bidding .float-sm-right,
.admin .float-sm-right {
    float: right !important;
  }
  .bidding .float-sm-none,
.admin .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .bidding .float-md-left,
.admin .float-md-left {
    float: left !important;
  }
  .bidding .float-md-right,
.admin .float-md-right {
    float: right !important;
  }
  .bidding .float-md-none,
.admin .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .bidding .float-lg-left,
.admin .float-lg-left {
    float: left !important;
  }
  .bidding .float-lg-right,
.admin .float-lg-right {
    float: right !important;
  }
  .bidding .float-lg-none,
.admin .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .bidding .float-xl-left,
.admin .float-xl-left {
    float: left !important;
  }
  .bidding .float-xl-right,
.admin .float-xl-right {
    float: right !important;
  }
  .bidding .float-xl-none,
.admin .float-xl-none {
    float: none !important;
  }
}
.bidding .overflow-auto,
.admin .overflow-auto {
  overflow: auto !important;
}
.bidding .overflow-hidden,
.admin .overflow-hidden {
  overflow: hidden !important;
}
.bidding .position-static,
.admin .position-static {
  position: static !important;
}
.bidding .position-relative,
.admin .position-relative {
  position: relative !important;
}
.bidding .position-absolute,
.admin .position-absolute {
  position: absolute !important;
}
.bidding .position-fixed,
.admin .position-fixed {
  position: fixed !important;
}
.bidding .position-sticky,
.admin .position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.bidding .fixed-top,
.admin .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.bidding .fixed-bottom,
.admin .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: -webkit-sticky) or (position: sticky) {
  .bidding .sticky-top,
.admin .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.bidding .sr-only,
.admin .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.bidding .sr-only-focusable:active,
.bidding .sr-only-focusable:focus,
.admin .sr-only-focusable:active,
.admin .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.bidding .shadow-sm,
.admin .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.bidding .shadow,
.admin .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.bidding .shadow-lg,
.admin .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.bidding .shadow-none,
.admin .shadow-none {
  box-shadow: none !important;
}
.bidding .w-25,
.admin .w-25 {
  width: 25% !important;
}
.bidding .w-50,
.admin .w-50 {
  width: 50% !important;
}
.bidding .w-75,
.admin .w-75 {
  width: 75% !important;
}
.bidding .w-100,
.admin .w-100 {
  width: 100% !important;
}
.bidding .w-auto,
.admin .w-auto {
  width: auto !important;
}
.bidding .h-25,
.admin .h-25 {
  height: 25% !important;
}
.bidding .h-50,
.admin .h-50 {
  height: 50% !important;
}
.bidding .h-75,
.admin .h-75 {
  height: 75% !important;
}
.bidding .h-100,
.admin .h-100 {
  height: 100% !important;
}
.bidding .h-auto,
.admin .h-auto {
  height: auto !important;
}
.bidding .mw-100,
.admin .mw-100 {
  max-width: 100% !important;
}
.bidding .mh-100,
.admin .mh-100 {
  max-height: 100% !important;
}
.bidding .min-vw-100,
.admin .min-vw-100 {
  min-width: 100vw !important;
}
.bidding .min-vh-100,
.admin .min-vh-100 {
  min-height: 100vh !important;
}
.bidding .vw-100,
.admin .vw-100 {
  width: 100vw !important;
}
.bidding .vh-100,
.admin .vh-100 {
  height: 100vh !important;
}
.bidding .stretched-link::after,
.admin .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.bidding .m-0,
.admin .m-0 {
  margin: 0 !important;
}
.bidding .mt-0,
.bidding .my-0,
.admin .mt-0,
.admin .my-0 {
  margin-top: 0 !important;
}
.bidding .mr-0,
.bidding .mx-0,
.admin .mr-0,
.admin .mx-0 {
  margin-right: 0 !important;
}
.bidding .mb-0,
.bidding .my-0,
.admin .mb-0,
.admin .my-0 {
  margin-bottom: 0 !important;
}
.bidding .ml-0,
.bidding .mx-0,
.admin .ml-0,
.admin .mx-0 {
  margin-left: 0 !important;
}
.bidding .m-1,
.admin .m-1 {
  margin: 0.25rem !important;
}
.bidding .mt-1,
.bidding .my-1,
.admin .mt-1,
.admin .my-1 {
  margin-top: 0.25rem !important;
}
.bidding .mr-1,
.bidding .mx-1,
.admin .mr-1,
.admin .mx-1 {
  margin-right: 0.25rem !important;
}
.bidding .mb-1,
.bidding .my-1,
.admin .mb-1,
.admin .my-1 {
  margin-bottom: 0.25rem !important;
}
.bidding .ml-1,
.bidding .mx-1,
.admin .ml-1,
.admin .mx-1 {
  margin-left: 0.25rem !important;
}
.bidding .m-2,
.admin .m-2 {
  margin: 0.5rem !important;
}
.bidding .mt-2,
.bidding .my-2,
.admin .mt-2,
.admin .my-2 {
  margin-top: 0.5rem !important;
}
.bidding .mr-2,
.bidding .mx-2,
.admin .mr-2,
.admin .mx-2 {
  margin-right: 0.5rem !important;
}
.bidding .mb-2,
.bidding .my-2,
.admin .mb-2,
.admin .my-2 {
  margin-bottom: 0.5rem !important;
}
.bidding .ml-2,
.bidding .mx-2,
.admin .ml-2,
.admin .mx-2 {
  margin-left: 0.5rem !important;
}
.bidding .m-3,
.admin .m-3 {
  margin: 1rem !important;
}
.bidding .mt-3,
.bidding .my-3,
.admin .mt-3,
.admin .my-3 {
  margin-top: 1rem !important;
}
.bidding .mr-3,
.bidding .mx-3,
.admin .mr-3,
.admin .mx-3 {
  margin-right: 1rem !important;
}
.bidding .mb-3,
.bidding .my-3,
.admin .mb-3,
.admin .my-3 {
  margin-bottom: 1rem !important;
}
.bidding .ml-3,
.bidding .mx-3,
.admin .ml-3,
.admin .mx-3 {
  margin-left: 1rem !important;
}
.bidding .m-4,
.admin .m-4 {
  margin: 1.5rem !important;
}
.bidding .mt-4,
.bidding .my-4,
.admin .mt-4,
.admin .my-4 {
  margin-top: 1.5rem !important;
}
.bidding .mr-4,
.bidding .mx-4,
.admin .mr-4,
.admin .mx-4 {
  margin-right: 1.5rem !important;
}
.bidding .mb-4,
.bidding .my-4,
.admin .mb-4,
.admin .my-4 {
  margin-bottom: 1.5rem !important;
}
.bidding .ml-4,
.bidding .mx-4,
.admin .ml-4,
.admin .mx-4 {
  margin-left: 1.5rem !important;
}
.bidding .m-5,
.admin .m-5 {
  margin: 3rem !important;
}
.bidding .mt-5,
.bidding .my-5,
.admin .mt-5,
.admin .my-5 {
  margin-top: 3rem !important;
}
.bidding .mr-5,
.bidding .mx-5,
.admin .mr-5,
.admin .mx-5 {
  margin-right: 3rem !important;
}
.bidding .mb-5,
.bidding .my-5,
.admin .mb-5,
.admin .my-5 {
  margin-bottom: 3rem !important;
}
.bidding .ml-5,
.bidding .mx-5,
.admin .ml-5,
.admin .mx-5 {
  margin-left: 3rem !important;
}
.bidding .p-0,
.admin .p-0 {
  padding: 0 !important;
}
.bidding .pt-0,
.bidding .py-0,
.admin .pt-0,
.admin .py-0 {
  padding-top: 0 !important;
}
.bidding .pr-0,
.bidding .px-0,
.admin .pr-0,
.admin .px-0 {
  padding-right: 0 !important;
}
.bidding .pb-0,
.bidding .py-0,
.admin .pb-0,
.admin .py-0 {
  padding-bottom: 0 !important;
}
.bidding .pl-0,
.bidding .px-0,
.admin .pl-0,
.admin .px-0 {
  padding-left: 0 !important;
}
.bidding .p-1,
.admin .p-1 {
  padding: 0.25rem !important;
}
.bidding .pt-1,
.bidding .py-1,
.admin .pt-1,
.admin .py-1 {
  padding-top: 0.25rem !important;
}
.bidding .pr-1,
.bidding .px-1,
.admin .pr-1,
.admin .px-1 {
  padding-right: 0.25rem !important;
}
.bidding .pb-1,
.bidding .py-1,
.admin .pb-1,
.admin .py-1 {
  padding-bottom: 0.25rem !important;
}
.bidding .pl-1,
.bidding .px-1,
.admin .pl-1,
.admin .px-1 {
  padding-left: 0.25rem !important;
}
.bidding .p-2,
.admin .p-2 {
  padding: 0.5rem !important;
}
.bidding .pt-2,
.bidding .py-2,
.admin .pt-2,
.admin .py-2 {
  padding-top: 0.5rem !important;
}
.bidding .pr-2,
.bidding .px-2,
.admin .pr-2,
.admin .px-2 {
  padding-right: 0.5rem !important;
}
.bidding .pb-2,
.bidding .py-2,
.admin .pb-2,
.admin .py-2 {
  padding-bottom: 0.5rem !important;
}
.bidding .pl-2,
.bidding .px-2,
.admin .pl-2,
.admin .px-2 {
  padding-left: 0.5rem !important;
}
.bidding .p-3,
.admin .p-3 {
  padding: 1rem !important;
}
.bidding .pt-3,
.bidding .py-3,
.admin .pt-3,
.admin .py-3 {
  padding-top: 1rem !important;
}
.bidding .pr-3,
.bidding .px-3,
.admin .pr-3,
.admin .px-3 {
  padding-right: 1rem !important;
}
.bidding .pb-3,
.bidding .py-3,
.admin .pb-3,
.admin .py-3 {
  padding-bottom: 1rem !important;
}
.bidding .pl-3,
.bidding .px-3,
.admin .pl-3,
.admin .px-3 {
  padding-left: 1rem !important;
}
.bidding .p-4,
.admin .p-4 {
  padding: 1.5rem !important;
}
.bidding .pt-4,
.bidding .py-4,
.admin .pt-4,
.admin .py-4 {
  padding-top: 1.5rem !important;
}
.bidding .pr-4,
.bidding .px-4,
.admin .pr-4,
.admin .px-4 {
  padding-right: 1.5rem !important;
}
.bidding .pb-4,
.bidding .py-4,
.admin .pb-4,
.admin .py-4 {
  padding-bottom: 1.5rem !important;
}
.bidding .pl-4,
.bidding .px-4,
.admin .pl-4,
.admin .px-4 {
  padding-left: 1.5rem !important;
}
.bidding .p-5,
.admin .p-5 {
  padding: 3rem !important;
}
.bidding .pt-5,
.bidding .py-5,
.admin .pt-5,
.admin .py-5 {
  padding-top: 3rem !important;
}
.bidding .pr-5,
.bidding .px-5,
.admin .pr-5,
.admin .px-5 {
  padding-right: 3rem !important;
}
.bidding .pb-5,
.bidding .py-5,
.admin .pb-5,
.admin .py-5 {
  padding-bottom: 3rem !important;
}
.bidding .pl-5,
.bidding .px-5,
.admin .pl-5,
.admin .px-5 {
  padding-left: 3rem !important;
}
.bidding .m-n1,
.admin .m-n1 {
  margin: -0.25rem !important;
}
.bidding .mt-n1,
.bidding .my-n1,
.admin .mt-n1,
.admin .my-n1 {
  margin-top: -0.25rem !important;
}
.bidding .mr-n1,
.bidding .mx-n1,
.admin .mr-n1,
.admin .mx-n1 {
  margin-right: -0.25rem !important;
}
.bidding .mb-n1,
.bidding .my-n1,
.admin .mb-n1,
.admin .my-n1 {
  margin-bottom: -0.25rem !important;
}
.bidding .ml-n1,
.bidding .mx-n1,
.admin .ml-n1,
.admin .mx-n1 {
  margin-left: -0.25rem !important;
}
.bidding .m-n2,
.admin .m-n2 {
  margin: -0.5rem !important;
}
.bidding .mt-n2,
.bidding .my-n2,
.admin .mt-n2,
.admin .my-n2 {
  margin-top: -0.5rem !important;
}
.bidding .mr-n2,
.bidding .mx-n2,
.admin .mr-n2,
.admin .mx-n2 {
  margin-right: -0.5rem !important;
}
.bidding .mb-n2,
.bidding .my-n2,
.admin .mb-n2,
.admin .my-n2 {
  margin-bottom: -0.5rem !important;
}
.bidding .ml-n2,
.bidding .mx-n2,
.admin .ml-n2,
.admin .mx-n2 {
  margin-left: -0.5rem !important;
}
.bidding .m-n3,
.admin .m-n3 {
  margin: -1rem !important;
}
.bidding .mt-n3,
.bidding .my-n3,
.admin .mt-n3,
.admin .my-n3 {
  margin-top: -1rem !important;
}
.bidding .mr-n3,
.bidding .mx-n3,
.admin .mr-n3,
.admin .mx-n3 {
  margin-right: -1rem !important;
}
.bidding .mb-n3,
.bidding .my-n3,
.admin .mb-n3,
.admin .my-n3 {
  margin-bottom: -1rem !important;
}
.bidding .ml-n3,
.bidding .mx-n3,
.admin .ml-n3,
.admin .mx-n3 {
  margin-left: -1rem !important;
}
.bidding .m-n4,
.admin .m-n4 {
  margin: -1.5rem !important;
}
.bidding .mt-n4,
.bidding .my-n4,
.admin .mt-n4,
.admin .my-n4 {
  margin-top: -1.5rem !important;
}
.bidding .mr-n4,
.bidding .mx-n4,
.admin .mr-n4,
.admin .mx-n4 {
  margin-right: -1.5rem !important;
}
.bidding .mb-n4,
.bidding .my-n4,
.admin .mb-n4,
.admin .my-n4 {
  margin-bottom: -1.5rem !important;
}
.bidding .ml-n4,
.bidding .mx-n4,
.admin .ml-n4,
.admin .mx-n4 {
  margin-left: -1.5rem !important;
}
.bidding .m-n5,
.admin .m-n5 {
  margin: -3rem !important;
}
.bidding .mt-n5,
.bidding .my-n5,
.admin .mt-n5,
.admin .my-n5 {
  margin-top: -3rem !important;
}
.bidding .mr-n5,
.bidding .mx-n5,
.admin .mr-n5,
.admin .mx-n5 {
  margin-right: -3rem !important;
}
.bidding .mb-n5,
.bidding .my-n5,
.admin .mb-n5,
.admin .my-n5 {
  margin-bottom: -3rem !important;
}
.bidding .ml-n5,
.bidding .mx-n5,
.admin .ml-n5,
.admin .mx-n5 {
  margin-left: -3rem !important;
}
.bidding .m-auto,
.admin .m-auto {
  margin: auto !important;
}
.bidding .mt-auto,
.bidding .my-auto,
.admin .mt-auto,
.admin .my-auto {
  margin-top: auto !important;
}
.bidding .mr-auto,
.bidding .mx-auto,
.admin .mr-auto,
.admin .mx-auto {
  margin-right: auto !important;
}
.bidding .mb-auto,
.bidding .my-auto,
.admin .mb-auto,
.admin .my-auto {
  margin-bottom: auto !important;
}
.bidding .ml-auto,
.bidding .mx-auto,
.admin .ml-auto,
.admin .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .bidding .m-sm-0,
.admin .m-sm-0 {
    margin: 0 !important;
  }
  .bidding .mt-sm-0,
.bidding .my-sm-0,
.admin .mt-sm-0,
.admin .my-sm-0 {
    margin-top: 0 !important;
  }
  .bidding .mr-sm-0,
.bidding .mx-sm-0,
.admin .mr-sm-0,
.admin .mx-sm-0 {
    margin-right: 0 !important;
  }
  .bidding .mb-sm-0,
.bidding .my-sm-0,
.admin .mb-sm-0,
.admin .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .bidding .ml-sm-0,
.bidding .mx-sm-0,
.admin .ml-sm-0,
.admin .mx-sm-0 {
    margin-left: 0 !important;
  }
  .bidding .m-sm-1,
.admin .m-sm-1 {
    margin: 0.25rem !important;
  }
  .bidding .mt-sm-1,
.bidding .my-sm-1,
.admin .mt-sm-1,
.admin .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .bidding .mr-sm-1,
.bidding .mx-sm-1,
.admin .mr-sm-1,
.admin .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .bidding .mb-sm-1,
.bidding .my-sm-1,
.admin .mb-sm-1,
.admin .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .bidding .ml-sm-1,
.bidding .mx-sm-1,
.admin .ml-sm-1,
.admin .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .bidding .m-sm-2,
.admin .m-sm-2 {
    margin: 0.5rem !important;
  }
  .bidding .mt-sm-2,
.bidding .my-sm-2,
.admin .mt-sm-2,
.admin .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .bidding .mr-sm-2,
.bidding .mx-sm-2,
.admin .mr-sm-2,
.admin .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .bidding .mb-sm-2,
.bidding .my-sm-2,
.admin .mb-sm-2,
.admin .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .bidding .ml-sm-2,
.bidding .mx-sm-2,
.admin .ml-sm-2,
.admin .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .bidding .m-sm-3,
.admin .m-sm-3 {
    margin: 1rem !important;
  }
  .bidding .mt-sm-3,
.bidding .my-sm-3,
.admin .mt-sm-3,
.admin .my-sm-3 {
    margin-top: 1rem !important;
  }
  .bidding .mr-sm-3,
.bidding .mx-sm-3,
.admin .mr-sm-3,
.admin .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .bidding .mb-sm-3,
.bidding .my-sm-3,
.admin .mb-sm-3,
.admin .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .bidding .ml-sm-3,
.bidding .mx-sm-3,
.admin .ml-sm-3,
.admin .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .bidding .m-sm-4,
.admin .m-sm-4 {
    margin: 1.5rem !important;
  }
  .bidding .mt-sm-4,
.bidding .my-sm-4,
.admin .mt-sm-4,
.admin .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .bidding .mr-sm-4,
.bidding .mx-sm-4,
.admin .mr-sm-4,
.admin .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .bidding .mb-sm-4,
.bidding .my-sm-4,
.admin .mb-sm-4,
.admin .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .bidding .ml-sm-4,
.bidding .mx-sm-4,
.admin .ml-sm-4,
.admin .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .bidding .m-sm-5,
.admin .m-sm-5 {
    margin: 3rem !important;
  }
  .bidding .mt-sm-5,
.bidding .my-sm-5,
.admin .mt-sm-5,
.admin .my-sm-5 {
    margin-top: 3rem !important;
  }
  .bidding .mr-sm-5,
.bidding .mx-sm-5,
.admin .mr-sm-5,
.admin .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .bidding .mb-sm-5,
.bidding .my-sm-5,
.admin .mb-sm-5,
.admin .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .bidding .ml-sm-5,
.bidding .mx-sm-5,
.admin .ml-sm-5,
.admin .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .bidding .p-sm-0,
.admin .p-sm-0 {
    padding: 0 !important;
  }
  .bidding .pt-sm-0,
.bidding .py-sm-0,
.admin .pt-sm-0,
.admin .py-sm-0 {
    padding-top: 0 !important;
  }
  .bidding .pr-sm-0,
.bidding .px-sm-0,
.admin .pr-sm-0,
.admin .px-sm-0 {
    padding-right: 0 !important;
  }
  .bidding .pb-sm-0,
.bidding .py-sm-0,
.admin .pb-sm-0,
.admin .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .bidding .pl-sm-0,
.bidding .px-sm-0,
.admin .pl-sm-0,
.admin .px-sm-0 {
    padding-left: 0 !important;
  }
  .bidding .p-sm-1,
.admin .p-sm-1 {
    padding: 0.25rem !important;
  }
  .bidding .pt-sm-1,
.bidding .py-sm-1,
.admin .pt-sm-1,
.admin .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .bidding .pr-sm-1,
.bidding .px-sm-1,
.admin .pr-sm-1,
.admin .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .bidding .pb-sm-1,
.bidding .py-sm-1,
.admin .pb-sm-1,
.admin .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .bidding .pl-sm-1,
.bidding .px-sm-1,
.admin .pl-sm-1,
.admin .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .bidding .p-sm-2,
.admin .p-sm-2 {
    padding: 0.5rem !important;
  }
  .bidding .pt-sm-2,
.bidding .py-sm-2,
.admin .pt-sm-2,
.admin .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .bidding .pr-sm-2,
.bidding .px-sm-2,
.admin .pr-sm-2,
.admin .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .bidding .pb-sm-2,
.bidding .py-sm-2,
.admin .pb-sm-2,
.admin .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .bidding .pl-sm-2,
.bidding .px-sm-2,
.admin .pl-sm-2,
.admin .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .bidding .p-sm-3,
.admin .p-sm-3 {
    padding: 1rem !important;
  }
  .bidding .pt-sm-3,
.bidding .py-sm-3,
.admin .pt-sm-3,
.admin .py-sm-3 {
    padding-top: 1rem !important;
  }
  .bidding .pr-sm-3,
.bidding .px-sm-3,
.admin .pr-sm-3,
.admin .px-sm-3 {
    padding-right: 1rem !important;
  }
  .bidding .pb-sm-3,
.bidding .py-sm-3,
.admin .pb-sm-3,
.admin .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .bidding .pl-sm-3,
.bidding .px-sm-3,
.admin .pl-sm-3,
.admin .px-sm-3 {
    padding-left: 1rem !important;
  }
  .bidding .p-sm-4,
.admin .p-sm-4 {
    padding: 1.5rem !important;
  }
  .bidding .pt-sm-4,
.bidding .py-sm-4,
.admin .pt-sm-4,
.admin .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .bidding .pr-sm-4,
.bidding .px-sm-4,
.admin .pr-sm-4,
.admin .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .bidding .pb-sm-4,
.bidding .py-sm-4,
.admin .pb-sm-4,
.admin .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .bidding .pl-sm-4,
.bidding .px-sm-4,
.admin .pl-sm-4,
.admin .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .bidding .p-sm-5,
.admin .p-sm-5 {
    padding: 3rem !important;
  }
  .bidding .pt-sm-5,
.bidding .py-sm-5,
.admin .pt-sm-5,
.admin .py-sm-5 {
    padding-top: 3rem !important;
  }
  .bidding .pr-sm-5,
.bidding .px-sm-5,
.admin .pr-sm-5,
.admin .px-sm-5 {
    padding-right: 3rem !important;
  }
  .bidding .pb-sm-5,
.bidding .py-sm-5,
.admin .pb-sm-5,
.admin .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .bidding .pl-sm-5,
.bidding .px-sm-5,
.admin .pl-sm-5,
.admin .px-sm-5 {
    padding-left: 3rem !important;
  }
  .bidding .m-sm-n1,
.admin .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .bidding .mt-sm-n1,
.bidding .my-sm-n1,
.admin .mt-sm-n1,
.admin .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .bidding .mr-sm-n1,
.bidding .mx-sm-n1,
.admin .mr-sm-n1,
.admin .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .bidding .mb-sm-n1,
.bidding .my-sm-n1,
.admin .mb-sm-n1,
.admin .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bidding .ml-sm-n1,
.bidding .mx-sm-n1,
.admin .ml-sm-n1,
.admin .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .bidding .m-sm-n2,
.admin .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .bidding .mt-sm-n2,
.bidding .my-sm-n2,
.admin .mt-sm-n2,
.admin .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .bidding .mr-sm-n2,
.bidding .mx-sm-n2,
.admin .mr-sm-n2,
.admin .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .bidding .mb-sm-n2,
.bidding .my-sm-n2,
.admin .mb-sm-n2,
.admin .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bidding .ml-sm-n2,
.bidding .mx-sm-n2,
.admin .ml-sm-n2,
.admin .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .bidding .m-sm-n3,
.admin .m-sm-n3 {
    margin: -1rem !important;
  }
  .bidding .mt-sm-n3,
.bidding .my-sm-n3,
.admin .mt-sm-n3,
.admin .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .bidding .mr-sm-n3,
.bidding .mx-sm-n3,
.admin .mr-sm-n3,
.admin .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .bidding .mb-sm-n3,
.bidding .my-sm-n3,
.admin .mb-sm-n3,
.admin .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .bidding .ml-sm-n3,
.bidding .mx-sm-n3,
.admin .ml-sm-n3,
.admin .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .bidding .m-sm-n4,
.admin .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .bidding .mt-sm-n4,
.bidding .my-sm-n4,
.admin .mt-sm-n4,
.admin .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .bidding .mr-sm-n4,
.bidding .mx-sm-n4,
.admin .mr-sm-n4,
.admin .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .bidding .mb-sm-n4,
.bidding .my-sm-n4,
.admin .mb-sm-n4,
.admin .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bidding .ml-sm-n4,
.bidding .mx-sm-n4,
.admin .ml-sm-n4,
.admin .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .bidding .m-sm-n5,
.admin .m-sm-n5 {
    margin: -3rem !important;
  }
  .bidding .mt-sm-n5,
.bidding .my-sm-n5,
.admin .mt-sm-n5,
.admin .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .bidding .mr-sm-n5,
.bidding .mx-sm-n5,
.admin .mr-sm-n5,
.admin .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .bidding .mb-sm-n5,
.bidding .my-sm-n5,
.admin .mb-sm-n5,
.admin .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .bidding .ml-sm-n5,
.bidding .mx-sm-n5,
.admin .ml-sm-n5,
.admin .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .bidding .m-sm-auto,
.admin .m-sm-auto {
    margin: auto !important;
  }
  .bidding .mt-sm-auto,
.bidding .my-sm-auto,
.admin .mt-sm-auto,
.admin .my-sm-auto {
    margin-top: auto !important;
  }
  .bidding .mr-sm-auto,
.bidding .mx-sm-auto,
.admin .mr-sm-auto,
.admin .mx-sm-auto {
    margin-right: auto !important;
  }
  .bidding .mb-sm-auto,
.bidding .my-sm-auto,
.admin .mb-sm-auto,
.admin .my-sm-auto {
    margin-bottom: auto !important;
  }
  .bidding .ml-sm-auto,
.bidding .mx-sm-auto,
.admin .ml-sm-auto,
.admin .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .bidding .m-md-0,
.admin .m-md-0 {
    margin: 0 !important;
  }
  .bidding .mt-md-0,
.bidding .my-md-0,
.admin .mt-md-0,
.admin .my-md-0 {
    margin-top: 0 !important;
  }
  .bidding .mr-md-0,
.bidding .mx-md-0,
.admin .mr-md-0,
.admin .mx-md-0 {
    margin-right: 0 !important;
  }
  .bidding .mb-md-0,
.bidding .my-md-0,
.admin .mb-md-0,
.admin .my-md-0 {
    margin-bottom: 0 !important;
  }
  .bidding .ml-md-0,
.bidding .mx-md-0,
.admin .ml-md-0,
.admin .mx-md-0 {
    margin-left: 0 !important;
  }
  .bidding .m-md-1,
.admin .m-md-1 {
    margin: 0.25rem !important;
  }
  .bidding .mt-md-1,
.bidding .my-md-1,
.admin .mt-md-1,
.admin .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .bidding .mr-md-1,
.bidding .mx-md-1,
.admin .mr-md-1,
.admin .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .bidding .mb-md-1,
.bidding .my-md-1,
.admin .mb-md-1,
.admin .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .bidding .ml-md-1,
.bidding .mx-md-1,
.admin .ml-md-1,
.admin .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .bidding .m-md-2,
.admin .m-md-2 {
    margin: 0.5rem !important;
  }
  .bidding .mt-md-2,
.bidding .my-md-2,
.admin .mt-md-2,
.admin .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .bidding .mr-md-2,
.bidding .mx-md-2,
.admin .mr-md-2,
.admin .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .bidding .mb-md-2,
.bidding .my-md-2,
.admin .mb-md-2,
.admin .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .bidding .ml-md-2,
.bidding .mx-md-2,
.admin .ml-md-2,
.admin .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .bidding .m-md-3,
.admin .m-md-3 {
    margin: 1rem !important;
  }
  .bidding .mt-md-3,
.bidding .my-md-3,
.admin .mt-md-3,
.admin .my-md-3 {
    margin-top: 1rem !important;
  }
  .bidding .mr-md-3,
.bidding .mx-md-3,
.admin .mr-md-3,
.admin .mx-md-3 {
    margin-right: 1rem !important;
  }
  .bidding .mb-md-3,
.bidding .my-md-3,
.admin .mb-md-3,
.admin .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .bidding .ml-md-3,
.bidding .mx-md-3,
.admin .ml-md-3,
.admin .mx-md-3 {
    margin-left: 1rem !important;
  }
  .bidding .m-md-4,
.admin .m-md-4 {
    margin: 1.5rem !important;
  }
  .bidding .mt-md-4,
.bidding .my-md-4,
.admin .mt-md-4,
.admin .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .bidding .mr-md-4,
.bidding .mx-md-4,
.admin .mr-md-4,
.admin .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .bidding .mb-md-4,
.bidding .my-md-4,
.admin .mb-md-4,
.admin .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .bidding .ml-md-4,
.bidding .mx-md-4,
.admin .ml-md-4,
.admin .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .bidding .m-md-5,
.admin .m-md-5 {
    margin: 3rem !important;
  }
  .bidding .mt-md-5,
.bidding .my-md-5,
.admin .mt-md-5,
.admin .my-md-5 {
    margin-top: 3rem !important;
  }
  .bidding .mr-md-5,
.bidding .mx-md-5,
.admin .mr-md-5,
.admin .mx-md-5 {
    margin-right: 3rem !important;
  }
  .bidding .mb-md-5,
.bidding .my-md-5,
.admin .mb-md-5,
.admin .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .bidding .ml-md-5,
.bidding .mx-md-5,
.admin .ml-md-5,
.admin .mx-md-5 {
    margin-left: 3rem !important;
  }
  .bidding .p-md-0,
.admin .p-md-0 {
    padding: 0 !important;
  }
  .bidding .pt-md-0,
.bidding .py-md-0,
.admin .pt-md-0,
.admin .py-md-0 {
    padding-top: 0 !important;
  }
  .bidding .pr-md-0,
.bidding .px-md-0,
.admin .pr-md-0,
.admin .px-md-0 {
    padding-right: 0 !important;
  }
  .bidding .pb-md-0,
.bidding .py-md-0,
.admin .pb-md-0,
.admin .py-md-0 {
    padding-bottom: 0 !important;
  }
  .bidding .pl-md-0,
.bidding .px-md-0,
.admin .pl-md-0,
.admin .px-md-0 {
    padding-left: 0 !important;
  }
  .bidding .p-md-1,
.admin .p-md-1 {
    padding: 0.25rem !important;
  }
  .bidding .pt-md-1,
.bidding .py-md-1,
.admin .pt-md-1,
.admin .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .bidding .pr-md-1,
.bidding .px-md-1,
.admin .pr-md-1,
.admin .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .bidding .pb-md-1,
.bidding .py-md-1,
.admin .pb-md-1,
.admin .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .bidding .pl-md-1,
.bidding .px-md-1,
.admin .pl-md-1,
.admin .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .bidding .p-md-2,
.admin .p-md-2 {
    padding: 0.5rem !important;
  }
  .bidding .pt-md-2,
.bidding .py-md-2,
.admin .pt-md-2,
.admin .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .bidding .pr-md-2,
.bidding .px-md-2,
.admin .pr-md-2,
.admin .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .bidding .pb-md-2,
.bidding .py-md-2,
.admin .pb-md-2,
.admin .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .bidding .pl-md-2,
.bidding .px-md-2,
.admin .pl-md-2,
.admin .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .bidding .p-md-3,
.admin .p-md-3 {
    padding: 1rem !important;
  }
  .bidding .pt-md-3,
.bidding .py-md-3,
.admin .pt-md-3,
.admin .py-md-3 {
    padding-top: 1rem !important;
  }
  .bidding .pr-md-3,
.bidding .px-md-3,
.admin .pr-md-3,
.admin .px-md-3 {
    padding-right: 1rem !important;
  }
  .bidding .pb-md-3,
.bidding .py-md-3,
.admin .pb-md-3,
.admin .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .bidding .pl-md-3,
.bidding .px-md-3,
.admin .pl-md-3,
.admin .px-md-3 {
    padding-left: 1rem !important;
  }
  .bidding .p-md-4,
.admin .p-md-4 {
    padding: 1.5rem !important;
  }
  .bidding .pt-md-4,
.bidding .py-md-4,
.admin .pt-md-4,
.admin .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .bidding .pr-md-4,
.bidding .px-md-4,
.admin .pr-md-4,
.admin .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .bidding .pb-md-4,
.bidding .py-md-4,
.admin .pb-md-4,
.admin .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .bidding .pl-md-4,
.bidding .px-md-4,
.admin .pl-md-4,
.admin .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .bidding .p-md-5,
.admin .p-md-5 {
    padding: 3rem !important;
  }
  .bidding .pt-md-5,
.bidding .py-md-5,
.admin .pt-md-5,
.admin .py-md-5 {
    padding-top: 3rem !important;
  }
  .bidding .pr-md-5,
.bidding .px-md-5,
.admin .pr-md-5,
.admin .px-md-5 {
    padding-right: 3rem !important;
  }
  .bidding .pb-md-5,
.bidding .py-md-5,
.admin .pb-md-5,
.admin .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .bidding .pl-md-5,
.bidding .px-md-5,
.admin .pl-md-5,
.admin .px-md-5 {
    padding-left: 3rem !important;
  }
  .bidding .m-md-n1,
.admin .m-md-n1 {
    margin: -0.25rem !important;
  }
  .bidding .mt-md-n1,
.bidding .my-md-n1,
.admin .mt-md-n1,
.admin .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .bidding .mr-md-n1,
.bidding .mx-md-n1,
.admin .mr-md-n1,
.admin .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .bidding .mb-md-n1,
.bidding .my-md-n1,
.admin .mb-md-n1,
.admin .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bidding .ml-md-n1,
.bidding .mx-md-n1,
.admin .ml-md-n1,
.admin .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .bidding .m-md-n2,
.admin .m-md-n2 {
    margin: -0.5rem !important;
  }
  .bidding .mt-md-n2,
.bidding .my-md-n2,
.admin .mt-md-n2,
.admin .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .bidding .mr-md-n2,
.bidding .mx-md-n2,
.admin .mr-md-n2,
.admin .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .bidding .mb-md-n2,
.bidding .my-md-n2,
.admin .mb-md-n2,
.admin .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bidding .ml-md-n2,
.bidding .mx-md-n2,
.admin .ml-md-n2,
.admin .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .bidding .m-md-n3,
.admin .m-md-n3 {
    margin: -1rem !important;
  }
  .bidding .mt-md-n3,
.bidding .my-md-n3,
.admin .mt-md-n3,
.admin .my-md-n3 {
    margin-top: -1rem !important;
  }
  .bidding .mr-md-n3,
.bidding .mx-md-n3,
.admin .mr-md-n3,
.admin .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .bidding .mb-md-n3,
.bidding .my-md-n3,
.admin .mb-md-n3,
.admin .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .bidding .ml-md-n3,
.bidding .mx-md-n3,
.admin .ml-md-n3,
.admin .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .bidding .m-md-n4,
.admin .m-md-n4 {
    margin: -1.5rem !important;
  }
  .bidding .mt-md-n4,
.bidding .my-md-n4,
.admin .mt-md-n4,
.admin .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .bidding .mr-md-n4,
.bidding .mx-md-n4,
.admin .mr-md-n4,
.admin .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .bidding .mb-md-n4,
.bidding .my-md-n4,
.admin .mb-md-n4,
.admin .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bidding .ml-md-n4,
.bidding .mx-md-n4,
.admin .ml-md-n4,
.admin .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .bidding .m-md-n5,
.admin .m-md-n5 {
    margin: -3rem !important;
  }
  .bidding .mt-md-n5,
.bidding .my-md-n5,
.admin .mt-md-n5,
.admin .my-md-n5 {
    margin-top: -3rem !important;
  }
  .bidding .mr-md-n5,
.bidding .mx-md-n5,
.admin .mr-md-n5,
.admin .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .bidding .mb-md-n5,
.bidding .my-md-n5,
.admin .mb-md-n5,
.admin .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .bidding .ml-md-n5,
.bidding .mx-md-n5,
.admin .ml-md-n5,
.admin .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .bidding .m-md-auto,
.admin .m-md-auto {
    margin: auto !important;
  }
  .bidding .mt-md-auto,
.bidding .my-md-auto,
.admin .mt-md-auto,
.admin .my-md-auto {
    margin-top: auto !important;
  }
  .bidding .mr-md-auto,
.bidding .mx-md-auto,
.admin .mr-md-auto,
.admin .mx-md-auto {
    margin-right: auto !important;
  }
  .bidding .mb-md-auto,
.bidding .my-md-auto,
.admin .mb-md-auto,
.admin .my-md-auto {
    margin-bottom: auto !important;
  }
  .bidding .ml-md-auto,
.bidding .mx-md-auto,
.admin .ml-md-auto,
.admin .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .bidding .m-lg-0,
.admin .m-lg-0 {
    margin: 0 !important;
  }
  .bidding .mt-lg-0,
.bidding .my-lg-0,
.admin .mt-lg-0,
.admin .my-lg-0 {
    margin-top: 0 !important;
  }
  .bidding .mr-lg-0,
.bidding .mx-lg-0,
.admin .mr-lg-0,
.admin .mx-lg-0 {
    margin-right: 0 !important;
  }
  .bidding .mb-lg-0,
.bidding .my-lg-0,
.admin .mb-lg-0,
.admin .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .bidding .ml-lg-0,
.bidding .mx-lg-0,
.admin .ml-lg-0,
.admin .mx-lg-0 {
    margin-left: 0 !important;
  }
  .bidding .m-lg-1,
.admin .m-lg-1 {
    margin: 0.25rem !important;
  }
  .bidding .mt-lg-1,
.bidding .my-lg-1,
.admin .mt-lg-1,
.admin .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .bidding .mr-lg-1,
.bidding .mx-lg-1,
.admin .mr-lg-1,
.admin .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .bidding .mb-lg-1,
.bidding .my-lg-1,
.admin .mb-lg-1,
.admin .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .bidding .ml-lg-1,
.bidding .mx-lg-1,
.admin .ml-lg-1,
.admin .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .bidding .m-lg-2,
.admin .m-lg-2 {
    margin: 0.5rem !important;
  }
  .bidding .mt-lg-2,
.bidding .my-lg-2,
.admin .mt-lg-2,
.admin .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .bidding .mr-lg-2,
.bidding .mx-lg-2,
.admin .mr-lg-2,
.admin .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .bidding .mb-lg-2,
.bidding .my-lg-2,
.admin .mb-lg-2,
.admin .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .bidding .ml-lg-2,
.bidding .mx-lg-2,
.admin .ml-lg-2,
.admin .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .bidding .m-lg-3,
.admin .m-lg-3 {
    margin: 1rem !important;
  }
  .bidding .mt-lg-3,
.bidding .my-lg-3,
.admin .mt-lg-3,
.admin .my-lg-3 {
    margin-top: 1rem !important;
  }
  .bidding .mr-lg-3,
.bidding .mx-lg-3,
.admin .mr-lg-3,
.admin .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .bidding .mb-lg-3,
.bidding .my-lg-3,
.admin .mb-lg-3,
.admin .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .bidding .ml-lg-3,
.bidding .mx-lg-3,
.admin .ml-lg-3,
.admin .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .bidding .m-lg-4,
.admin .m-lg-4 {
    margin: 1.5rem !important;
  }
  .bidding .mt-lg-4,
.bidding .my-lg-4,
.admin .mt-lg-4,
.admin .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .bidding .mr-lg-4,
.bidding .mx-lg-4,
.admin .mr-lg-4,
.admin .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .bidding .mb-lg-4,
.bidding .my-lg-4,
.admin .mb-lg-4,
.admin .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .bidding .ml-lg-4,
.bidding .mx-lg-4,
.admin .ml-lg-4,
.admin .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .bidding .m-lg-5,
.admin .m-lg-5 {
    margin: 3rem !important;
  }
  .bidding .mt-lg-5,
.bidding .my-lg-5,
.admin .mt-lg-5,
.admin .my-lg-5 {
    margin-top: 3rem !important;
  }
  .bidding .mr-lg-5,
.bidding .mx-lg-5,
.admin .mr-lg-5,
.admin .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .bidding .mb-lg-5,
.bidding .my-lg-5,
.admin .mb-lg-5,
.admin .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .bidding .ml-lg-5,
.bidding .mx-lg-5,
.admin .ml-lg-5,
.admin .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .bidding .p-lg-0,
.admin .p-lg-0 {
    padding: 0 !important;
  }
  .bidding .pt-lg-0,
.bidding .py-lg-0,
.admin .pt-lg-0,
.admin .py-lg-0 {
    padding-top: 0 !important;
  }
  .bidding .pr-lg-0,
.bidding .px-lg-0,
.admin .pr-lg-0,
.admin .px-lg-0 {
    padding-right: 0 !important;
  }
  .bidding .pb-lg-0,
.bidding .py-lg-0,
.admin .pb-lg-0,
.admin .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .bidding .pl-lg-0,
.bidding .px-lg-0,
.admin .pl-lg-0,
.admin .px-lg-0 {
    padding-left: 0 !important;
  }
  .bidding .p-lg-1,
.admin .p-lg-1 {
    padding: 0.25rem !important;
  }
  .bidding .pt-lg-1,
.bidding .py-lg-1,
.admin .pt-lg-1,
.admin .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .bidding .pr-lg-1,
.bidding .px-lg-1,
.admin .pr-lg-1,
.admin .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .bidding .pb-lg-1,
.bidding .py-lg-1,
.admin .pb-lg-1,
.admin .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .bidding .pl-lg-1,
.bidding .px-lg-1,
.admin .pl-lg-1,
.admin .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .bidding .p-lg-2,
.admin .p-lg-2 {
    padding: 0.5rem !important;
  }
  .bidding .pt-lg-2,
.bidding .py-lg-2,
.admin .pt-lg-2,
.admin .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .bidding .pr-lg-2,
.bidding .px-lg-2,
.admin .pr-lg-2,
.admin .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .bidding .pb-lg-2,
.bidding .py-lg-2,
.admin .pb-lg-2,
.admin .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .bidding .pl-lg-2,
.bidding .px-lg-2,
.admin .pl-lg-2,
.admin .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .bidding .p-lg-3,
.admin .p-lg-3 {
    padding: 1rem !important;
  }
  .bidding .pt-lg-3,
.bidding .py-lg-3,
.admin .pt-lg-3,
.admin .py-lg-3 {
    padding-top: 1rem !important;
  }
  .bidding .pr-lg-3,
.bidding .px-lg-3,
.admin .pr-lg-3,
.admin .px-lg-3 {
    padding-right: 1rem !important;
  }
  .bidding .pb-lg-3,
.bidding .py-lg-3,
.admin .pb-lg-3,
.admin .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .bidding .pl-lg-3,
.bidding .px-lg-3,
.admin .pl-lg-3,
.admin .px-lg-3 {
    padding-left: 1rem !important;
  }
  .bidding .p-lg-4,
.admin .p-lg-4 {
    padding: 1.5rem !important;
  }
  .bidding .pt-lg-4,
.bidding .py-lg-4,
.admin .pt-lg-4,
.admin .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .bidding .pr-lg-4,
.bidding .px-lg-4,
.admin .pr-lg-4,
.admin .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .bidding .pb-lg-4,
.bidding .py-lg-4,
.admin .pb-lg-4,
.admin .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .bidding .pl-lg-4,
.bidding .px-lg-4,
.admin .pl-lg-4,
.admin .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .bidding .p-lg-5,
.admin .p-lg-5 {
    padding: 3rem !important;
  }
  .bidding .pt-lg-5,
.bidding .py-lg-5,
.admin .pt-lg-5,
.admin .py-lg-5 {
    padding-top: 3rem !important;
  }
  .bidding .pr-lg-5,
.bidding .px-lg-5,
.admin .pr-lg-5,
.admin .px-lg-5 {
    padding-right: 3rem !important;
  }
  .bidding .pb-lg-5,
.bidding .py-lg-5,
.admin .pb-lg-5,
.admin .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .bidding .pl-lg-5,
.bidding .px-lg-5,
.admin .pl-lg-5,
.admin .px-lg-5 {
    padding-left: 3rem !important;
  }
  .bidding .m-lg-n1,
.admin .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .bidding .mt-lg-n1,
.bidding .my-lg-n1,
.admin .mt-lg-n1,
.admin .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .bidding .mr-lg-n1,
.bidding .mx-lg-n1,
.admin .mr-lg-n1,
.admin .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .bidding .mb-lg-n1,
.bidding .my-lg-n1,
.admin .mb-lg-n1,
.admin .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bidding .ml-lg-n1,
.bidding .mx-lg-n1,
.admin .ml-lg-n1,
.admin .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .bidding .m-lg-n2,
.admin .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .bidding .mt-lg-n2,
.bidding .my-lg-n2,
.admin .mt-lg-n2,
.admin .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .bidding .mr-lg-n2,
.bidding .mx-lg-n2,
.admin .mr-lg-n2,
.admin .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .bidding .mb-lg-n2,
.bidding .my-lg-n2,
.admin .mb-lg-n2,
.admin .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bidding .ml-lg-n2,
.bidding .mx-lg-n2,
.admin .ml-lg-n2,
.admin .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .bidding .m-lg-n3,
.admin .m-lg-n3 {
    margin: -1rem !important;
  }
  .bidding .mt-lg-n3,
.bidding .my-lg-n3,
.admin .mt-lg-n3,
.admin .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .bidding .mr-lg-n3,
.bidding .mx-lg-n3,
.admin .mr-lg-n3,
.admin .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .bidding .mb-lg-n3,
.bidding .my-lg-n3,
.admin .mb-lg-n3,
.admin .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .bidding .ml-lg-n3,
.bidding .mx-lg-n3,
.admin .ml-lg-n3,
.admin .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .bidding .m-lg-n4,
.admin .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .bidding .mt-lg-n4,
.bidding .my-lg-n4,
.admin .mt-lg-n4,
.admin .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .bidding .mr-lg-n4,
.bidding .mx-lg-n4,
.admin .mr-lg-n4,
.admin .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .bidding .mb-lg-n4,
.bidding .my-lg-n4,
.admin .mb-lg-n4,
.admin .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bidding .ml-lg-n4,
.bidding .mx-lg-n4,
.admin .ml-lg-n4,
.admin .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .bidding .m-lg-n5,
.admin .m-lg-n5 {
    margin: -3rem !important;
  }
  .bidding .mt-lg-n5,
.bidding .my-lg-n5,
.admin .mt-lg-n5,
.admin .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .bidding .mr-lg-n5,
.bidding .mx-lg-n5,
.admin .mr-lg-n5,
.admin .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .bidding .mb-lg-n5,
.bidding .my-lg-n5,
.admin .mb-lg-n5,
.admin .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .bidding .ml-lg-n5,
.bidding .mx-lg-n5,
.admin .ml-lg-n5,
.admin .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .bidding .m-lg-auto,
.admin .m-lg-auto {
    margin: auto !important;
  }
  .bidding .mt-lg-auto,
.bidding .my-lg-auto,
.admin .mt-lg-auto,
.admin .my-lg-auto {
    margin-top: auto !important;
  }
  .bidding .mr-lg-auto,
.bidding .mx-lg-auto,
.admin .mr-lg-auto,
.admin .mx-lg-auto {
    margin-right: auto !important;
  }
  .bidding .mb-lg-auto,
.bidding .my-lg-auto,
.admin .mb-lg-auto,
.admin .my-lg-auto {
    margin-bottom: auto !important;
  }
  .bidding .ml-lg-auto,
.bidding .mx-lg-auto,
.admin .ml-lg-auto,
.admin .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .bidding .m-xl-0,
.admin .m-xl-0 {
    margin: 0 !important;
  }
  .bidding .mt-xl-0,
.bidding .my-xl-0,
.admin .mt-xl-0,
.admin .my-xl-0 {
    margin-top: 0 !important;
  }
  .bidding .mr-xl-0,
.bidding .mx-xl-0,
.admin .mr-xl-0,
.admin .mx-xl-0 {
    margin-right: 0 !important;
  }
  .bidding .mb-xl-0,
.bidding .my-xl-0,
.admin .mb-xl-0,
.admin .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .bidding .ml-xl-0,
.bidding .mx-xl-0,
.admin .ml-xl-0,
.admin .mx-xl-0 {
    margin-left: 0 !important;
  }
  .bidding .m-xl-1,
.admin .m-xl-1 {
    margin: 0.25rem !important;
  }
  .bidding .mt-xl-1,
.bidding .my-xl-1,
.admin .mt-xl-1,
.admin .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .bidding .mr-xl-1,
.bidding .mx-xl-1,
.admin .mr-xl-1,
.admin .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .bidding .mb-xl-1,
.bidding .my-xl-1,
.admin .mb-xl-1,
.admin .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .bidding .ml-xl-1,
.bidding .mx-xl-1,
.admin .ml-xl-1,
.admin .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .bidding .m-xl-2,
.admin .m-xl-2 {
    margin: 0.5rem !important;
  }
  .bidding .mt-xl-2,
.bidding .my-xl-2,
.admin .mt-xl-2,
.admin .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .bidding .mr-xl-2,
.bidding .mx-xl-2,
.admin .mr-xl-2,
.admin .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .bidding .mb-xl-2,
.bidding .my-xl-2,
.admin .mb-xl-2,
.admin .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .bidding .ml-xl-2,
.bidding .mx-xl-2,
.admin .ml-xl-2,
.admin .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .bidding .m-xl-3,
.admin .m-xl-3 {
    margin: 1rem !important;
  }
  .bidding .mt-xl-3,
.bidding .my-xl-3,
.admin .mt-xl-3,
.admin .my-xl-3 {
    margin-top: 1rem !important;
  }
  .bidding .mr-xl-3,
.bidding .mx-xl-3,
.admin .mr-xl-3,
.admin .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .bidding .mb-xl-3,
.bidding .my-xl-3,
.admin .mb-xl-3,
.admin .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .bidding .ml-xl-3,
.bidding .mx-xl-3,
.admin .ml-xl-3,
.admin .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .bidding .m-xl-4,
.admin .m-xl-4 {
    margin: 1.5rem !important;
  }
  .bidding .mt-xl-4,
.bidding .my-xl-4,
.admin .mt-xl-4,
.admin .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .bidding .mr-xl-4,
.bidding .mx-xl-4,
.admin .mr-xl-4,
.admin .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .bidding .mb-xl-4,
.bidding .my-xl-4,
.admin .mb-xl-4,
.admin .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .bidding .ml-xl-4,
.bidding .mx-xl-4,
.admin .ml-xl-4,
.admin .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .bidding .m-xl-5,
.admin .m-xl-5 {
    margin: 3rem !important;
  }
  .bidding .mt-xl-5,
.bidding .my-xl-5,
.admin .mt-xl-5,
.admin .my-xl-5 {
    margin-top: 3rem !important;
  }
  .bidding .mr-xl-5,
.bidding .mx-xl-5,
.admin .mr-xl-5,
.admin .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .bidding .mb-xl-5,
.bidding .my-xl-5,
.admin .mb-xl-5,
.admin .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .bidding .ml-xl-5,
.bidding .mx-xl-5,
.admin .ml-xl-5,
.admin .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .bidding .p-xl-0,
.admin .p-xl-0 {
    padding: 0 !important;
  }
  .bidding .pt-xl-0,
.bidding .py-xl-0,
.admin .pt-xl-0,
.admin .py-xl-0 {
    padding-top: 0 !important;
  }
  .bidding .pr-xl-0,
.bidding .px-xl-0,
.admin .pr-xl-0,
.admin .px-xl-0 {
    padding-right: 0 !important;
  }
  .bidding .pb-xl-0,
.bidding .py-xl-0,
.admin .pb-xl-0,
.admin .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .bidding .pl-xl-0,
.bidding .px-xl-0,
.admin .pl-xl-0,
.admin .px-xl-0 {
    padding-left: 0 !important;
  }
  .bidding .p-xl-1,
.admin .p-xl-1 {
    padding: 0.25rem !important;
  }
  .bidding .pt-xl-1,
.bidding .py-xl-1,
.admin .pt-xl-1,
.admin .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .bidding .pr-xl-1,
.bidding .px-xl-1,
.admin .pr-xl-1,
.admin .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .bidding .pb-xl-1,
.bidding .py-xl-1,
.admin .pb-xl-1,
.admin .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .bidding .pl-xl-1,
.bidding .px-xl-1,
.admin .pl-xl-1,
.admin .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .bidding .p-xl-2,
.admin .p-xl-2 {
    padding: 0.5rem !important;
  }
  .bidding .pt-xl-2,
.bidding .py-xl-2,
.admin .pt-xl-2,
.admin .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .bidding .pr-xl-2,
.bidding .px-xl-2,
.admin .pr-xl-2,
.admin .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .bidding .pb-xl-2,
.bidding .py-xl-2,
.admin .pb-xl-2,
.admin .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .bidding .pl-xl-2,
.bidding .px-xl-2,
.admin .pl-xl-2,
.admin .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .bidding .p-xl-3,
.admin .p-xl-3 {
    padding: 1rem !important;
  }
  .bidding .pt-xl-3,
.bidding .py-xl-3,
.admin .pt-xl-3,
.admin .py-xl-3 {
    padding-top: 1rem !important;
  }
  .bidding .pr-xl-3,
.bidding .px-xl-3,
.admin .pr-xl-3,
.admin .px-xl-3 {
    padding-right: 1rem !important;
  }
  .bidding .pb-xl-3,
.bidding .py-xl-3,
.admin .pb-xl-3,
.admin .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .bidding .pl-xl-3,
.bidding .px-xl-3,
.admin .pl-xl-3,
.admin .px-xl-3 {
    padding-left: 1rem !important;
  }
  .bidding .p-xl-4,
.admin .p-xl-4 {
    padding: 1.5rem !important;
  }
  .bidding .pt-xl-4,
.bidding .py-xl-4,
.admin .pt-xl-4,
.admin .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .bidding .pr-xl-4,
.bidding .px-xl-4,
.admin .pr-xl-4,
.admin .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .bidding .pb-xl-4,
.bidding .py-xl-4,
.admin .pb-xl-4,
.admin .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .bidding .pl-xl-4,
.bidding .px-xl-4,
.admin .pl-xl-4,
.admin .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .bidding .p-xl-5,
.admin .p-xl-5 {
    padding: 3rem !important;
  }
  .bidding .pt-xl-5,
.bidding .py-xl-5,
.admin .pt-xl-5,
.admin .py-xl-5 {
    padding-top: 3rem !important;
  }
  .bidding .pr-xl-5,
.bidding .px-xl-5,
.admin .pr-xl-5,
.admin .px-xl-5 {
    padding-right: 3rem !important;
  }
  .bidding .pb-xl-5,
.bidding .py-xl-5,
.admin .pb-xl-5,
.admin .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .bidding .pl-xl-5,
.bidding .px-xl-5,
.admin .pl-xl-5,
.admin .px-xl-5 {
    padding-left: 3rem !important;
  }
  .bidding .m-xl-n1,
.admin .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .bidding .mt-xl-n1,
.bidding .my-xl-n1,
.admin .mt-xl-n1,
.admin .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .bidding .mr-xl-n1,
.bidding .mx-xl-n1,
.admin .mr-xl-n1,
.admin .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .bidding .mb-xl-n1,
.bidding .my-xl-n1,
.admin .mb-xl-n1,
.admin .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bidding .ml-xl-n1,
.bidding .mx-xl-n1,
.admin .ml-xl-n1,
.admin .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .bidding .m-xl-n2,
.admin .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .bidding .mt-xl-n2,
.bidding .my-xl-n2,
.admin .mt-xl-n2,
.admin .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .bidding .mr-xl-n2,
.bidding .mx-xl-n2,
.admin .mr-xl-n2,
.admin .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .bidding .mb-xl-n2,
.bidding .my-xl-n2,
.admin .mb-xl-n2,
.admin .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bidding .ml-xl-n2,
.bidding .mx-xl-n2,
.admin .ml-xl-n2,
.admin .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .bidding .m-xl-n3,
.admin .m-xl-n3 {
    margin: -1rem !important;
  }
  .bidding .mt-xl-n3,
.bidding .my-xl-n3,
.admin .mt-xl-n3,
.admin .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .bidding .mr-xl-n3,
.bidding .mx-xl-n3,
.admin .mr-xl-n3,
.admin .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .bidding .mb-xl-n3,
.bidding .my-xl-n3,
.admin .mb-xl-n3,
.admin .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .bidding .ml-xl-n3,
.bidding .mx-xl-n3,
.admin .ml-xl-n3,
.admin .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .bidding .m-xl-n4,
.admin .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .bidding .mt-xl-n4,
.bidding .my-xl-n4,
.admin .mt-xl-n4,
.admin .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .bidding .mr-xl-n4,
.bidding .mx-xl-n4,
.admin .mr-xl-n4,
.admin .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .bidding .mb-xl-n4,
.bidding .my-xl-n4,
.admin .mb-xl-n4,
.admin .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bidding .ml-xl-n4,
.bidding .mx-xl-n4,
.admin .ml-xl-n4,
.admin .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .bidding .m-xl-n5,
.admin .m-xl-n5 {
    margin: -3rem !important;
  }
  .bidding .mt-xl-n5,
.bidding .my-xl-n5,
.admin .mt-xl-n5,
.admin .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .bidding .mr-xl-n5,
.bidding .mx-xl-n5,
.admin .mr-xl-n5,
.admin .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .bidding .mb-xl-n5,
.bidding .my-xl-n5,
.admin .mb-xl-n5,
.admin .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .bidding .ml-xl-n5,
.bidding .mx-xl-n5,
.admin .ml-xl-n5,
.admin .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .bidding .m-xl-auto,
.admin .m-xl-auto {
    margin: auto !important;
  }
  .bidding .mt-xl-auto,
.bidding .my-xl-auto,
.admin .mt-xl-auto,
.admin .my-xl-auto {
    margin-top: auto !important;
  }
  .bidding .mr-xl-auto,
.bidding .mx-xl-auto,
.admin .mr-xl-auto,
.admin .mx-xl-auto {
    margin-right: auto !important;
  }
  .bidding .mb-xl-auto,
.bidding .my-xl-auto,
.admin .mb-xl-auto,
.admin .my-xl-auto {
    margin-bottom: auto !important;
  }
  .bidding .ml-xl-auto,
.bidding .mx-xl-auto,
.admin .ml-xl-auto,
.admin .mx-xl-auto {
    margin-left: auto !important;
  }
}
.bidding .text-monospace,
.admin .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.bidding .text-justify,
.admin .text-justify {
  text-align: justify !important;
}
.bidding .text-wrap,
.admin .text-wrap {
  white-space: normal !important;
}
.bidding .text-nowrap,
.admin .text-nowrap {
  white-space: nowrap !important;
}
.bidding .text-truncate,
.admin .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bidding .text-left,
.admin .text-left {
  text-align: left !important;
}
.bidding .text-right,
.admin .text-right {
  text-align: right !important;
}
.bidding .text-center,
.admin .text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .bidding .text-sm-left,
.admin .text-sm-left {
    text-align: left !important;
  }
  .bidding .text-sm-right,
.admin .text-sm-right {
    text-align: right !important;
  }
  .bidding .text-sm-center,
.admin .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .bidding .text-md-left,
.admin .text-md-left {
    text-align: left !important;
  }
  .bidding .text-md-right,
.admin .text-md-right {
    text-align: right !important;
  }
  .bidding .text-md-center,
.admin .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .bidding .text-lg-left,
.admin .text-lg-left {
    text-align: left !important;
  }
  .bidding .text-lg-right,
.admin .text-lg-right {
    text-align: right !important;
  }
  .bidding .text-lg-center,
.admin .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .bidding .text-xl-left,
.admin .text-xl-left {
    text-align: left !important;
  }
  .bidding .text-xl-right,
.admin .text-xl-right {
    text-align: right !important;
  }
  .bidding .text-xl-center,
.admin .text-xl-center {
    text-align: center !important;
  }
}
.bidding .font-weight-light,
.admin .font-weight-light {
  font-weight: 300 !important;
}
.bidding .font-weight-lighter,
.admin .font-weight-lighter {
  font-weight: lighter !important;
}
.bidding .font-weight-normal,
.admin .font-weight-normal {
  font-weight: 400 !important;
}
.bidding .font-weight-bold,
.admin .font-weight-bold {
  font-weight: 700 !important;
}
.bidding .font-weight-bolder,
.admin .font-weight-bolder {
  font-weight: bolder !important;
}
.bidding .font-italic,
.admin .font-italic {
  font-style: italic !important;
}
.bidding .text-white,
.admin .text-white {
  color: #fff !important;
}
.bidding .text-primary,
.admin .text-primary {
  color: #007bff !important;
}
.bidding a.text-primary:focus,
.bidding a.text-primary:hover,
.admin a.text-primary:focus,
.admin a.text-primary:hover {
  color: #0056b3 !important;
}
.bidding .text-secondary,
.admin .text-secondary {
  color: #6c757d !important;
}
.bidding a.text-secondary:focus,
.bidding a.text-secondary:hover,
.admin a.text-secondary:focus,
.admin a.text-secondary:hover {
  color: #494f54 !important;
}
.bidding .text-success,
.admin .text-success {
  color: #28a745 !important;
}
.bidding a.text-success:focus,
.bidding a.text-success:hover,
.admin a.text-success:focus,
.admin a.text-success:hover {
  color: #19692c !important;
}
.bidding .text-info,
.admin .text-info {
  color: #17a2b8 !important;
}
.bidding a.text-info:focus,
.bidding a.text-info:hover,
.admin a.text-info:focus,
.admin a.text-info:hover {
  color: #0f6674 !important;
}
.bidding .text-warning,
.admin .text-warning {
  color: #ffc107 !important;
}
.bidding a.text-warning:focus,
.bidding a.text-warning:hover,
.admin a.text-warning:focus,
.admin a.text-warning:hover {
  color: #ba8b00 !important;
}
.bidding .text-danger,
.admin .text-danger {
  color: #dc3545 !important;
}
.bidding a.text-danger:focus,
.bidding a.text-danger:hover,
.admin a.text-danger:focus,
.admin a.text-danger:hover {
  color: #a71d2a !important;
}
.bidding .text-light,
.admin .text-light {
  color: #f8f9fa !important;
}
.bidding a.text-light:focus,
.bidding a.text-light:hover,
.admin a.text-light:focus,
.admin a.text-light:hover {
  color: #cbd3da !important;
}
.bidding .text-dark,
.admin .text-dark {
  color: #343a40 !important;
}
.bidding a.text-dark:focus,
.bidding a.text-dark:hover,
.admin a.text-dark:focus,
.admin a.text-dark:hover {
  color: #121416 !important;
}
.bidding .text-body,
.admin .text-body {
  color: #212529 !important;
}
.bidding .text-muted,
.admin .text-muted {
  color: #6c757d !important;
}
.bidding .text-black-50,
.admin .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.bidding .text-white-50,
.admin .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bidding .text-hide,
.admin .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.bidding .text-decoration-none,
.admin .text-decoration-none {
  text-decoration: none !important;
}
.bidding .text-break,
.admin .text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.bidding .text-reset,
.admin .text-reset {
  color: inherit !important;
}
.bidding .visible,
.admin .visible {
  visibility: visible !important;
}
.bidding .invisible,
.admin .invisible {
  visibility: hidden !important;
}
@media print {
  .bidding *,
.bidding ::after,
.bidding ::before,
.admin *,
.admin ::after,
.admin ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  .bidding a:not(.btn),
.admin a:not(.btn) {
    text-decoration: underline;
  }
  .bidding abbr[title]::after,
.admin abbr[title]::after {
    content: " (" attr(title) ")";
  }
  .bidding pre,
.admin pre {
    white-space: pre-wrap !important;
  }
  .bidding blockquote,
.bidding pre,
.admin blockquote,
.admin pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  .bidding thead,
.admin thead {
    display: table-header-group;
  }
  .bidding img,
.bidding tr,
.admin img,
.admin tr {
    page-break-inside: avoid;
  }
  .bidding h2,
.bidding h3,
.bidding p,
.admin h2,
.admin h3,
.admin p {
    orphans: 3;
    widows: 3;
  }
  .bidding h2,
.bidding h3,
.admin h2,
.admin h3 {
    page-break-after: avoid;
  }
  @page {
    .bidding,
.admin {
      size: a3;
    }
  }
  .bidding body,
.admin body {
    min-width: 992px !important;
  }
  .bidding .container,
.admin .container {
    min-width: 992px !important;
  }
  .bidding .navbar,
.admin .navbar {
    display: none;
  }
  .bidding .badge,
.admin .badge {
    border: 1px solid #000;
  }
  .bidding .table,
.admin .table {
    border-collapse: collapse !important;
  }
  .bidding .table td,
.bidding .table th,
.admin .table td,
.admin .table th {
    background-color: #fff !important;
  }
  .bidding .table-bordered td,
.bidding .table-bordered th,
.admin .table-bordered td,
.admin .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }
  .bidding .table-dark,
.admin .table-dark {
    color: inherit;
  }
  .bidding .table-dark tbody + tbody,
.bidding .table-dark td,
.bidding .table-dark th,
.bidding .table-dark thead th,
.admin .table-dark tbody + tbody,
.admin .table-dark td,
.admin .table-dark th,
.admin .table-dark thead th {
    border-color: #dee2e6;
  }
  .bidding .table .thead-dark th,
.admin .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*# sourceMappingURL=bootstrap.min.css.map */
