$text-color: white;

header.welcome-login {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-5%, -50%);
  color: $text-color;
  text-transform: uppercase;
  font-family: Roboto;
  font-weight: 500;
  line-height: 22px;

  h1.welcome {
    margin: 0;
    font-size: 3rem;
    white-space: nowrap;
    color: $text-color;
    font-weight: 500;
    line-height: 22px;
  }
  p.module {
    margin: 0;
    text-align: right;
    font-size: 2rem;
    padding-top: 2rem;
  }
}
