#feedback-container {
  position: fixed;
  z-index: 100000;
  right: 20px;
  bottom: -350px;
  font-family: Arial, sans-serif;
  -webkit-transition: width 0.2s, bottom 0.6s;
  -moz-transition: width 0.2s, bottom 0.6s;
  -o-transition: width 0.2s, bottom 0.6s;
  transition: width 0.2s, bottom 0.6s;
  border-radius: 3px 3px 0 0;
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#feedback-container * {
  box-sizing: border-box;
}
#feedback-header {
  background-color: #333;
  color: #fff;
  opacity: 0.5;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  white-space: nowrap;
}
#feedback-header:hover {
  opacity: 1;
}
#feedback-header i {
  font-size: 16px;
  opacity: 0.3;
  margin-top: -2px;
  margin-left: 5px;
}
#feedback-header i.fa-times {
  display: none;
  float: right;
}
#feedback-body {
  height: 350px;
  background-color: #fff;
}
#feedback-body .inner {
  display: none;
  padding: 15px;
}
#feedback-body p {
  font-size: 13px;
  margin: 0 0 20px 0;
  line-height: 17px;
}
#feedback-body .input-wrap {
  margin: 0 0 13px 0;
  position: relative;
}
#feedback-body .input-wrap label {
  position: absolute;
  top: -7px;
  left: 5px;
  font-size: 11px;
  color: #666;
  background-color: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 5px;
}
#feedback-body .input-wrap.error label {
  color: red;
}
#feedback-body input,
#feedback-body textarea {
  font-size: 12px;
  padding: 7px;
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  z-index: 2;
  outline: 0;
}
#feedback-body textarea {
  height: 100px;
  resize: none;
  font-family: Arial, sans-serif;
}
#feedback-body input:focus,
#feedback-body textarea:focus {
  -webkit-box-shadow: inset 0 2px 4px -1px #ddd;
  -moz-box-shadow: inset 0 2px 4px -1px #ddd;
  box-shadow: inset 0 2px 4px -1px #ddd;
  border-color: #aaa;
}
#feedback-container.expanded {
  bottom: 0;
  width: 280px !important;
}
#feedback-container.expanded #feedback-header {
  opacity: 1;
}
#feedback-container.expanded #feedback-header:hover {
  opacity: 0.8;
}
#feedback-container.expanded #feedback-header i.fa-times {
  display: inline-block;
}
#feedback-container.expanded #feedback-header i.fa-question-circle {
  display: none;
}
#feedback-container.expanded #feedback-body .inner {
  display: block;
}
#feedback-body .input-wrap.error label {
  color: red;
}
#feedback-body .input-wrap.error input,
#feedback-body .input-wrap.error textarea {
  border-color: red;
}
#feedback-container .feedback-status {
  display: none;
  text-align: center;
  font-size: 16px;
  padding-top: 30px;
}
#feedback-container.sending #feedback-sending {
  display: block;
}
#feedback-container.sent #feedback-sent {
  display: block;
}
#feedback-container.send-error #feedback-send-error {
  display: block;
}
#feedback-container.send-error #feedback-body .inner,
#feedback-container.sending #feedback-body .inner,
#feedback-container.sent #feedback-body .inner {
  display: none;
}
