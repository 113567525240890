.main h2 {
  font-size: 26px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 16px;
  margin-top: 10px;
  font-family: Roboto, Arial;
  font-weight: 500;
  color: #464546;
  text-transform: none;
}
.main .grow-1 {
  flex-grow: 1;
}
.main .inline-box {
  display: inline-flex;
  flex-direction: row;
  flex-basis: auto;
  flex-wrap: wrap;
  align-items: center;
  width: auto;
}
.main .orange-vertical-borders {
  border-style: solid none solid none;
  border-width: 2px;
  border-color: #f28b00;
}
.main .min-width-med {
  min-width: 470px;
}
.main .right-border {
  border-right: 1px solid #edecec;
}
.main .triangle {
  /* Orange / Orange 700 Brand */
  width: 55px;
  height: 55px;
  background: #f28b00;
  transform: rotate(45deg);
  position: relative;
  right: 39px;
}
.main .triangle-container {
  overflow: hidden;
  width: 55px;
  height: 55px;
}
.main .triangle-container {
  height: 55px;
  width: 40px;
}
.main .body1 {
  /* Text / Body */
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  /* Gray / Gray 700 */
  color: #464546;
}
.main .body2 {
  /* Caps Header */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  /*font-size: 12px;*/
  line-height: 22px;
  /* or 183% */
  text-transform: uppercase;
}
.main .bold {
  font-weight: bold;
}
.main .hr {
  border: 1px solid #edecec;
}
.main .options {
  font-family: Roboto;
}

.apexcharts-menu {
  min-width: 118 !important;
}

