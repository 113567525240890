@charset "UTF-8";
@font-face {
  font-family: gotham-rounded-book;
  src: url(../fonts/gotham-rounded-book.otf);
}
@font-face {
  font-family: gotham-rounded-bold;
  src: url(../fonts/gotham-rounded-bold.otf);
}
@font-face {
  font-family: play-regular;
  src: url(../fonts/play-regular.ttf);
}
@font-face {
  font-family: play-bold;
  src: url(../fonts/play-bold.ttf);
}
.admin {
  /*I'm not proud of making a container for a container, but I needed to have 2 absolute positioned
    elements without removing the main div from the DOM.*/
  /* Styling for buttons on Application Administrators */
}
.admin * {
  box-sizing: border-box;
}
.admin html,
.admin body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
.admin body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.admin code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.admin textarea {
  height: auto;
}
.admin #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
.admin .spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.admin .spinner {
  margin: 0;
}
.admin .spinner.spinner-lg {
  font-size: 150px;
}
.admin .code-box {
  display: inline-block;
  padding: 15px;
  text-align: left;
}
.admin .input-box {
  display: inline-block;
  padding: 15px;
  text-align: left;
}
.admin .card-docs-description-url {
  display: block;
  padding: 5px 15px;
  max-width: 350px;
}
.admin .fields {
  margin-bottom: 40px;
}
.admin div.card-docs-description.field {
  display: block;
  margin-bottom: 15px;
  text-align: center;
}
.admin .field__key {
  display: block;
  margin-bottom: 5px;
}
.admin .field__value {
  display: inline-block;
  padding: 5px 15px;
  margin-bottom: 10px;
}
.admin .avatar {
  height: 100%;
}
.admin .form-control {
  max-width: 350px;
}
.admin .red-text {
  color: #db3416;
}
@keyframes fade-out-left {
  from {
    opacity: 1;
    transform: translate(0%, 0%, 0%);
  }
  to {
    opacity: 0;
    transform: translateX(-500px);
  }
}
@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translateX(500px);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
.admin .view-transition-enter.view-transition-enter-active {
  animation-name: fade-in-left;
  animation-duration: 0.4s;
}
.admin .view-transition-leave {
  opacity: 0;
}
.admin .view-transition-leave.view-transition-leave-active {
  animation-name: fade-out-left;
  animation-duration: 0.2s;
}
.admin .user-info {
  font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  flex-grow: 1;
  font-weight: 800;
  color: black;
  cursor: pointer;
  display: cblock;
  clear: both;
  float: left;
  position: relative;
  top: 43px;
  height: 120px;
  margin-right: 60px;
}
.admin .user-info-top {
  font-size: 1.3rem;
  color: white;
}
.admin .user-info-dropdown-break {
  padding: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #b4b4b4;
  height: 1px;
}
.admin .user-info-dropdown {
  transform: scaleY(0);
  transition: 0.2s;
  background: gainsboro;
  transform-origin: top;
  box-shadow: 7px 4px 15px #a0a0a0;
}
.admin .user-info:hover .user-info-dropdown {
  transform: scaleY(1);
}
.admin .user-info-dropdown-item {
  display: flex;
  justify-content: left;
  flex-basis: auto;
  flex-wrap: nowrap;
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
}
.admin .user-info-dropdown-item:hover {
  background: #e6e6e6;
}
.admin .admin-main-container {
  background: green;
}
.admin .current-view-container {
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-wrap: nowrap;
  align-items: center;
  width: 40vw;
}
.admin .header-whitespace {
  width: 30vw;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
}
.admin .header-bar {
  background-color: #1a5da6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='1' y2='0'%3E%3Cstop offset='0' stop-color='%230f1547' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%230f1547'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23473499' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23473499'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Crect fill='url(%23b)' width='1600' height='900'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  width: 100vw;
  display: flex;
  flex-flow: row;
  justify-content: center;
  flex-wrap: nowrap;
  color: white;
  box-shadow: 0px 5px 10px #b4b4b4;
}
.admin .centerline-logo {
  width: 64px;
  height: 64px;
}
.admin .centerline-branding-title {
  margin-top: 6px;
  font-size: 1rem;
  letter-spacing: 4px;
  text-align: center;
  color: white;
  line-height: 0;
  margin-top: 16px;
  font-weight: 600;
  font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
}
.admin .centerline-branding {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-basis: auto;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-left: 60px;
}
.admin .admin-header {
  font-family: play-regular, Arial, Helvetica, sans-serif;
  font-weight: 1000;
  text-align: center;
  font-size: 3rem;
  line-height: 1;
  margin: 0;
  padding: 0;
}
.admin .smaller-header {
  font-family: play-regular, Arial, Helvetica, sans-serif;
  font-weight: bolder;
  text-align: center;
  font-size: 1.8rem;
  position: relative;
  bottom: 20px;
}
.admin .inline-container,
.admin .inline-container-large {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: auto;
  align-items: center;
  max-height: 360px;
}
.admin .inline-container-large {
  max-height: 9999px;
}
.admin .home-flex-box {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.admin .home-content {
  flex: 1;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  position: fixed;
  left: 0px;
  top: 120px;
  width: 100%;
  height: calc(100vh - 120px);
  transition: 0.2s;
}
.admin .home-flex-box-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh;
  flex-basis: auto;
  align-items: center;
}
.admin .home-tile,
.admin .custom-button,
.admin .tile {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  width: 450px;
  height: 200px;
  background: white;
  margin-left: 150px;
  margin-right: 150px;
  text-align: center;
  transition: transform 0.33s;
  box-shadow: 13px 10px 25px gray;
  cursor: pointer;
  background-image: -webkit-linear-gradient(120deg, #61a6f2 50%, transparent 50%);
  background-image: linear-gradient(330deg, #61a6f2 50%, transparent 50%);
  background-size: 1300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}
.admin .flex-column {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.admin .tile {
  box-shadow: 4px 4px 10px lightgray;
  width: 500px;
  height: 175px;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: flex-start;
  background-size: 1500px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.admin .tile:hover .tile-footer {
  color: #e1e1e1;
}
.admin .tile:hover .tile-forward {
  color: #e1e1e1;
}
.admin .flex-grow {
  flex-grow: 1;
}
.admin .flex-grow-container {
  flex-grow: 100;
}
.admin .home-tile:hover,
.admin .custom-button:hover,
.admin .tile:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  color: whitesmoke;
  background-position: 100%;
}
.admin .home-tile:hover .home-tile-header,
.admin .custom-button:hover .home-tile-header,
.admin .home-tile:hover .tile-header,
.admin .custom-button:hover .tile-header,
.admin .tile:hover .home-tile-header,
.admin .tile:hover .tile-header {
  color: whitesmoke;
}
.admin .paragraph-center {
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
}
.admin .home-tile-header-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: auto;
  justify-content: flex-start;
  align-items: center;
}
.admin .home-tile-header-image {
  width: 32px;
  height: 32px;
  margin-left: 16px;
}
.admin .home-tile-break {
  height: 1px;
  background: gray;
  margin-left: 20px;
  margin-right: 20px;
}
.admin .tile-break {
  height: 1px;
  background: gray;
  margin-left: 12px;
  margin-right: 12px;
  padding: 0;
}
.admin .home-tile-header,
.admin .tile-header {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 32px;
  font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
  flex-grow: 1;
  color: black;
  font-size: 24px;
  font-weight: 1000;
  transition: 0.33s;
}
.admin .header {
  font-size: 24px;
  font-weight: 500;
  padding-top: 16px;
  padding-bottom: 16px;
}
.admin .tile-header {
  line-height: 0;
  font-size: 1.3rem;
  flex-grow: 0;
  padding-top: 8px;
  padding-bottom: 4px;
  transition: 0.33s;
}
.admin .home-tile-paragraph,
.admin .tile-paragraph {
  font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  height: auto;
  flex-grow: 0;
  text-align: center;
  justify-self: center;
  width: 100%;
  line-height: 1.5rem;
  margin-left: 32px;
  margin-right: 32px;
}
.admin .tile-paragraph {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  justify-self: flex-start;
  text-align: left;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 42%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.admin .tile-footer-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 8px;
}
.admin .tile-footer {
  font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #7a7a7a;
  height: auto;
  line-height: 1px;
  flex-grow: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  transition: color 0.33s;
}
.admin .tile-forward {
  font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
  color: #7a7a7a;
  font-size: 1.7rem;
  line-height: 0;
  transition: color 0.33s;
}
.admin .break {
  flex-basis: 100%;
  height: 0;
}
.admin .light-break {
  background-color: lightgray;
  width: 60%;
  height: 1px;
}
.admin .text-instructions {
  color: black;
  font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 1000;
  flex-grow: 1;
  max-height: 100px;
  display: flex;
  align-items: flex-end;
}
.admin .home-tile-break-margin {
  margin-top: 15px;
  margin-bottom: 15px;
}
.admin .admin-home-back {
  overflow: hidden;
  background: white;
}
.admin .sidebar-arrow {
  position: absolute;
  left: 0px;
  top: 50%;
  height: 96px;
  width: 32px;
  background-color: rgba(27, 95, 170, 0.5);
  cursor: pointer;
}
.admin .sidebar-arrow-active {
  position: absolute;
  left: 280px;
  top: 50%;
  height: 96px;
  width: 32px;
  background-color: rgba(250, 250, 250, 0.5);
  cursor: pointer;
  transition: 0.2s;
}
.admin .sidebar-active .sidebar-arrow-active {
  left: 264px;
}
.admin .sidebar-arrow-icon {
  width: 48px;
  left: -8px;
  height: 64px;
  position: absolute;
  top: 20%;
}
.admin .down-arrow-icon {
  width: 32px;
  height: 32px;
  position: relative;
  bottom: -1px;
  left: 5px;
}
.admin .user-info-username {
  position: relative;
  bottom: -1px;
}
.admin .small-user-icon {
  width: 32px;
  height: 32px;
  margin-right: 14px;
  position: relative;
  bottom: 2px;
}
.admin .current-view-icon {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  position: relative;
}
.admin .sidebar {
  width: 280px;
  left: -280px;
  top: 0px;
  height: 100%;
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-basis: auto;
  justify-content: flex-start;
  align-items: center;
  transition: left 0.22s;
  background-color: rgba(7, 7, 20, 0.75);
  color: white;
}
.admin .sidebar-active {
  left: 0px;
}
.admin .sidebar-top-margin {
  margin-bottom: 32px;
}
.admin .sidebar-item {
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: gotham-rounded-book;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  color: white;
  cursor: pointer;
}
.admin .sidebar-item-active {
  background-color: white !important;
  color: black !important;
}
.admin .sidebar-item:hover {
  background-color: lightgray;
}
.admin .sidebar-item-image-container-container {
  margin-left: 16px;
  margin-right: 24px;
  width: 32px;
  height: 32px;
}
.admin .sidebar-item-image-container {
  position: absolute;
}
.admin .sidebar-item-image {
  position: absolute;
  transform-origin: center center;
  width: 32px;
  height: 32px;
  left: 0%;
  top: 0px;
  transition: transform 0.3s;
}
.admin .sidebar-item:hover .sidebar-item-image {
  transform: scale(1.2);
}
.admin .sidebar-text {
  transform-origin: left center;
  transition: color 0.3s, transform 0.3s;
  margin-top: 0px;
  text-align: left;
}
.admin .sidebar-item:hover .sidebar-text {
  transform: scale(1.2);
  color: black;
}
.admin ​ hr {
  color: black;
  background: black;
  border-top: 1px solid gray;
  margin-top: 0px;
  margin-bottom: 0px;
}
.admin .search-adminstrator-button {
  text-align: center;
  position: relative;
  width: 306px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  margin-right: 100px;
}
.admin .adminstrator-button {
  text-align: center;
  margin-top: 5px;
  position: relative;
  width: 285px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: 100px;
}
.admin .admin-table {
  width: 1500vw;
  height: 900vh;
  overflow: 'scroll';
  padding-left: 450vw;
  padding-right: 450vw;
}
.admin .announcement-tile-styling {
  margin-top: 10vh;
}
.admin .custom-button {
  width: auto;
  height: auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 4px 2px 6px gray;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 8px;
  cursor: pointer;
  background-image: -webkit-linear-gradient(120deg, #61a6f2 50%, transparent 50%);
  background-image: linear-gradient(330deg, #61a6f2 50%, transparent 50%);
  background-size: 750px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: auto;
  justify-content: flex-start;
  align-items: center;
}
.admin .custom-button-image {
  width: 32px;
  height: 32px;
  margin-right: 11px;
}
.admin .custom-button-text {
  font-family: gotham-rounded-book, Arial, Helvetica, sans-serif;
  font-weight: bolder;
  flex-grow: 1;
  color: black;
  line-height: 0;
  padding: 0;
}
@media screen and (max-width: 1500px) {
  .admin .home-tile,
  .admin .tile,
  .admin .custom-button {
    margin-left: 80px;
    margin-right: 80px;
  }
}
.admin .not-visible {
  animation-name: fade-out;
  animation-duration: 0.2s;
  opacity: 0;
}
.admin .visible {
  animation-name: fade-in;
  animation-duration: 0.2s;
  opacity: 1;
}

