.AreaHighlight__part {
    background: rgba(9, 71, 185, 0.5);
}

.Highlight__popup {
    overflow: hidden;
}

.AreaHighlight {
    border: none;
}

.PdfHighlighter {
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    overflow: hidden;
}
