.filepond--root > .filepond--panel {
  z-index: 2;
}

.filepond--root {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1em;
  font-size: 1rem;
  line-height: normal;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 450;
  text-align: left;
  text-rendering: optimizeLegibility;
  direction: ltr;
  contain: layout style size;
}

.filepond--panel {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0;
  height: 100% !important;
  pointer-events: none;
}
.filepond--panel-root {
  border-radius: 0.5em;
  background-color: #f1f0ef;
}

.filepond--panel-bottom,
.filepond--panel-top {
  height: 0.5em;
}

.filepond--drop-label.filepond--drop-label label {
  display: block;
  margin: 0;
  padding: 0.5em;
}
.filepond--drop-label {
  z-index: 5;
}

.filepond--root :not(text) {
  font-size: inherit;
}

.filepond--drop-label {
  right: 0;
  margin: 0;
  color: #4f4f4f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.filepond--root .filepond--drop-label {
  min-height: 4.75em;
}

.filepond--drip-blob,
.filepond--drop-label {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform, opacity;
}
